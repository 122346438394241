import { Avatar, Box, Button, Divider, Grid, Table, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { MdChevronLeft } from "react-icons/md";
import { IoChevronDown } from "react-icons/io5";
import { Link, useParams } from "react-router-dom";
import img1 from '../../assets/img/1.jpg';
import { GET_SINGLE_PRODUCTS } from "../../Utils/Queries";
import Loader from "../Common/Loader";
import { useLazyQuery } from "@apollo/client";

const Overview = () => {
  const {id} = useParams();
  
    const [getproducts, {loading, data, error}] = useLazyQuery(GET_SINGLE_PRODUCTS, {variables:{
      textToSearch : id
    }});



  useEffect(()=>{
getproducts()


  }, [])
const product = data?.fetchProductsWithFilter?.items

console.log(product
)

  return (
    <>
    {
      loading  && <Loader/>
    }
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Link to="/dashboard/products">
          <Button
            sx={{ bgcolor: "#fff", color: "#666" }}
            startIcon={<MdChevronLeft />}
          >
            Product list
          </Button>
        </Link>
        {/* <Button
          endIcon={<IoChevronDown />}
          sx={{ bgcolor: "#fff", color: "#666" }}
        >
          Change Status
        </Button> */}
      </Box>

      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item lg={6}>
          <Box sx={{ p: 2, bgcolor: "#fff" }}>
            <Typography sx={{ fontWeight: 500 }}>Product Details</Typography>
            <Divider />

            <Box
            className="hide_scrollbar"
              sx={{ mt: 2, display: "flex", justifyContent: "space-between" ,overflow:'scroll'}}
            >
           {
            product && product[0]?.images?.map((url, index)  => (
                 <Avatar
                src={url}
                variant="square"
                sx={{ width: "120px", height: "120px" }}
              />
            ))
           }
           
            </Box>

            <Box sx={{ mt: 3 }}>
              <Typography sx={{ fontSize: "12px" }}>ID</Typography>
              <Typography sx={{ fontWeight: 600 }}>#{product && product[0]?._id}</Typography>
              <Box sx={{ mt: 2 }}>
                <Typography sx={{ fontSize: "12px" }}>Status</Typography>
                <Typography sx={{ fontWeight: 600 }}> {product && product[0]?.status}</Typography>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Typography sx={{ fontSize: "12px" }}>Product Title</Typography>
                <Typography sx={{ fontWeight: 600 }}>
                  {" "}
                  {product &&  product[0]?.name}
                </Typography>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Typography sx={{ fontSize: "12px" }}>Description</Typography>
                <Typography sx={{ fontWeight: 600 }}>
                  {" "}
                    {product &&  product[0]?.description}
                </Typography>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Typography sx={{ fontSize: "12px" }}>Origin</Typography>
                <Typography sx={{ fontWeight: 600 }}>  {product &&  product[0]?.country?.name}</Typography>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Typography sx={{ fontSize: "12px" }}>Price</Typography>
                <Typography sx={{ fontWeight: 600 }}>$          {product &&  product[0]?.price}</Typography>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Typography sx={{ fontSize: "12px" }}>Negotiable</Typography>
                <Typography sx={{ fontWeight: 600 }}>{product && product[0]?.isNegotiable === true  ?  "Yes" : "No"}</Typography>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Typography sx={{ fontSize: "12px" }}>Stock</Typography>
                <Typography sx={{ fontWeight: 600 }}>{product && product[0]?.stockHistory[0]?.quantity}</Typography>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Typography sx={{ fontSize: "12px" }}>Sold</Typography>
                <Typography sx={{ fontWeight: 600 }}>0</Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={6}>
          <Grid container rowSpacing={2}>
            <Grid item lg={12}>
              <Box sx={{ p: 2, bgcolor: "#fff" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography sx={{ fontWeight: 500 }}>
                    Seller Details
                  </Typography>
                  <Link to={`/dashboard/user-details/${product && product[0]?.merchant?._id}`}>
                  <Typography sx={{ fontWeight: 500, color: "#3FB763" }}>
                    View Profile
                  </Typography>
                  </Link>
                </Box>
                <Divider />
                <Box sx={{ mt: 2 }}>
                  <Typography sx={{ fontSize: "12px" }}>Full Name</Typography>
                  <Typography sx={{ fontWeight: 600 }}>{product && `${product[0]?.merchant?.firstName} ${product[0]?.merchant?.lastName}`}</Typography>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Typography sx={{ fontSize: "12px" }}>Join Date</Typography>
                  <Typography sx={{ fontWeight: 600 }}>{product && formatDate(product[0]?.merchant?.createdAt)}</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={12}>
              {/* <Box sx={{ p: 2, bgcolor: "#fff" }}>
                <Typography sx={{ fontWeight: 500 }}>Orders</Typography>

                <TableContainer sx={{ mt: 3 }}>
                  <Table>
                    <TableHead>
                      <TableRow sx={{bgcolor:'#f8f8f7'}}>
                        <TableCell>Order ID</TableCell>
                        <TableCell> Customer</TableCell>
                        <TableCell align="right"> Status</TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </TableContainer>
              </Box> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Overview;

const formatDate = (dateString) => {
  const date = new Date(dateString);

  const day = date.getUTCDate();
  const month = date.toLocaleString('en-GB', { month: 'short' });
  const year = date.getUTCFullYear();

  return `${day} ${month} ${year}`;
};
