import {
  Grid,
  Box,
  IconButton,
  ListItem,
  ListItemButton,
  Avatar,
  Button,
  Popover
} from "@mui/material";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { HiOutlineRefresh } from "react-icons/hi";
import { useMutation, useQuery } from "@apollo/client";
import { GET_DISPUTED } from "../../Utils/Queries";
import Loader from "../../Components/Common/Loader";
import emptyAnime from  '../../assets/anime/OanY0VFPFY.json'
import Lottie from 'react-lottie';
import { IoIosArrowDown } from "react-icons/io";
import { MenuItem } from "@material-ui/core";
import { BiExpand } from "react-icons/bi";
import { REFUND_BUYER, RELEASE_FUND } from "../../Utils/Mutation";
import { useSnackbarHook } from "../../hooks/useSnackbarHook";
import { Link } from "react-router-dom";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 2 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Dispute = () => {

const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { data, loading, refetch } = useQuery(GET_DISPUTED);
const {handleClick:handleAlert} = useSnackbarHook()
  
  const [outletData, setOutletData] = useState(null);
  console.log(outletData);
useEffect(()=>{
  if(data){
    setOutletData(data?.fetchDisputedOrders?.items[0])
  }
}, [data])



    const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: emptyAnime,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};


const [isLoading, setIsLoading] = useState(false)

const [refundBuyer ] = useMutation(REFUND_BUYER) 
const [releaseFund ] = useMutation(RELEASE_FUND) 

const handleRefundBuyer = async () =>{
  setIsLoading(true)
  await refundBuyer({
    variables:{
      itemId:outletData?._id
    }
  }).then((res)=>{
    setIsLoading(false)
    setAnchorEl(null)
handleAlert("Buyer Refunded", "success")
  }).catch((err)=>{
        setIsLoading(false)
    handleAlert(`${err?.message}`, "error")
  })
}
const handleReleaseFund = async () =>{
      setIsLoading(true)
  await releaseFund({
    variables:{
      itemId:outletData?._id
    }
  }).then((res)=>{
        setIsLoading(false)
    setAnchorEl(null)
handleAlert("Fund Released", "success")
  }).catch((err)=>{
        setIsLoading(false)
    handleAlert(`${err?.message}`, "error")
  })
}

  return (
    <>
    {
     (isLoading ||  loading) && <Loader/>
    }
      <Grid container spacing={2}>
        <Grid item lg={5} md={5}>
          <Box className="hide_scrollbar" sx={{ bgcolor: "#fff", height: "90vh", overflow:'scroll' }}>
            <Box align="right">
              <IconButton onClick={()=>refetch()}>
                <HiOutlineRefresh />
              </IconButton>
            </Box>

            <Box
              sx={{
                px: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label="All" {...a11yProps(0)} />
                    {/* <Tab label="Unread" {...a11yProps(1)} />
                    <Tab label="Resolved" {...a11yProps(2)} /> */}
                  </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                  {data?.fetchDisputedOrders?.items?.map((item, index) => (
                    <Box onClick={()=>setOutletData(item)}   key={index}>
                    <ListComponent data={item} selected={outletData}/>
                    </Box>
                  ))}
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  Item Two
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                  Item Three
                </CustomTabPanel>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={7} md={7}>
      
          <Box
          className="hide_scrollbar"
            sx={{
              bgcolor: "#fff",
              height: "90vh",
              p: 3,
              boxSizing: "border-box",
              overflow:'scroll'
            }}
          >
           
     {
      (!data || data?.fetchDisputedOrders?.items.length === 0 || !outletData) ? (
        <>
        <Box sx={{display:'grid', placeItems:'center',  height:'100%'}}>
<Box>

            <Lottie options={defaultOptions}  width={400}  height={200}/>
            <Typography color="primary" sx={{fontWeight:500, textAlign:'center'}}>No Dispute Record Available</Typography>
</Box>
        </Box>
        </>
      ):(
        <>
               <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontWeight: 600 }}>Dispute ID: {outletData?._id}</Typography>
              <Button sx={{ fontSize: "14px", textTransform: "initial" }} aria-describedby={id} type="button" onClick={handleClick}>
                {" "}
               <IoIosArrowDown style={{marginRight:2}} /> Change Status
              </Button>
            </Box>

            <Box
              sx={{
                mt: 3,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                Title: {outletData?.dispute?.reason}
              </Typography>
              <Typography
                sx={{ fontSize: "12px", fontWeight: 500, color: "#666" }}
              >
              {formatDateLong(parseFloat(outletData?.dispute?.createdAt))}
              </Typography>
            </Box>

            <Box sx={{ mt: 2 }}>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Box
                    sx={{
                      border: "1px solid #f7f6f6",
                      p: 2,
                      boxSizing: "border-box",
                      boxShadow:
                        "rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderBottom: "1px solid #f7f6f6",
                        pb: 2,
                      }}
                    >
                      <Typography sx={{ fontWeight: 500 }}>Buyer</Typography>
                          <Link to ={`/dashboard/user-details/${outletData?.buyer?._id}`}>
                      <Typography
                        sx={{
                          textDecoration: "underline",
                          fontWeight: 500,
                          cursor: "pointer",
                          fontSize: "13px",
                        }}
                      >
                        View Profile
                      </Typography>
                      </Link>
                    </Box>
                    <Typography sx={{ mt: 2 }}>Full Name</Typography>
                    <Typography sx={{ fontWeight: 500 }}>
                     {outletData?.buyer?.firstName}     {outletData?.buyer?.lastName}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <Box
                    sx={{
                      border: "1px solid #f7f6f6",
                      p: 2,
                      boxSizing: "border-box",
                      boxShadow:
                        "rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderBottom: "1px solid #f7f6f6",
                        pb: 2,
                      }}
                    >
                      <Typography sx={{ fontWeight: 500 }}>Seller</Typography>
                      <Link to ={`/dashboard/user-details/${outletData?.seller?._id}`}>
                      <Typography
                        sx={{
                          textDecoration: "underline",
                          fontWeight: 500,
                          cursor: "pointer",
                          fontSize: "13px",
                        }}
                      >
                        View Profile
                      </Typography>
                      </Link>
                    </Box>
                    <Typography sx={{ mt: 2 }}>Full Name</Typography>
                    <Typography sx={{ fontWeight: 500 }}>
                {outletData?.seller?.firstName}     {outletData?.seller?.lastName}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={12}>
                  <Box
                    sx={{
                      border: "1px solid #f7f6f6",
                      p: 2,
                      boxSizing: "border-box",
                      boxShadow: " rgba(149, 157, 165, 0.2) 0px 8px 24px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderBottom: "1px solid #f7f6f6",
                        pb: 2,
                      }}
                    >
                      <Typography sx={{ fontWeight: 500 }}>
                        Item Delivered
                      </Typography>
                      <Link to={`/dashboard/product-details/${outletData?.product?._id}`}>
                      <Typography
                        sx={{
                          textDecoration: "underline",
                          fontWeight: 500,
                          cursor: "pointer",
                          fontSize: "13px",
                        }}
                      >
                        Product Details
                      </Typography>
                      </Link>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 2,
                        justifyContent: "space-between",
                        mt: 2,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 2,
                        }}
                      >
                        <Avatar src={outletData?.product?.images[0]} variant="rounded" />
                        <Box>
                          <Typography sx={{fontWeight:500,  fontSize:'10px'}}>{outletData?.product?._id}</Typography>
                          <Typography sx={{fontWeight:500}}>{outletData?.product?.name}</Typography>
                          {/* <Typography>QTY: 1</Typography> */}
                        </Box>
                      </Box>

                      <Box>
                        <Typography sx={{fontWeight:500}}>${outletData?.product?.price.toLocaleString()}</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={12}>
                  <Box
                    sx={{
                      border: "1px solid #f7f6f6",
                      p: 2,
                      boxSizing: "border-box",
                      boxShadow: " rgba(149, 157, 165, 0.2) 0px 8px 24px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderBottom: "1px solid #f7f6f6",
                        pb: 2,
                      }}
                    >
                      <Typography sx={{ fontWeight: 500 }}>
               Description
                      </Typography>
               
                    </Box>
                    <Box
                      sx={{
                    
                        mt: 2,
                      }}
                    >
          <Typography> {outletData?.dispute?.description}</Typography>
<Box sx={{mt:2}}>
<Box sx={{
  background:`url('${outletData?.dispute?.files[0]}')`,
  height:'200px',
  backgroundSize:'cover',
  backgroundPosition:'center'
}}>
  <a href={outletData?.dispute?.files[0]} target="_blank">
  <Box align="right" sx={{p:1, display:'flex', alignItems:'center', columnGap:1, justifyContent:'right', bgcolor:'#ccc', backdropFilter:'blur(0.4)'}}>
    <Typography>click to  expand</Typography>

  <IconButton>

  <BiExpand style={{color:'#000'}} />
  </IconButton>
  </Box>
  </a>
  </Box>

</Box>
                    
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={12}>
                  <Box
                    sx={{
                      border: "1px solid #f7f6f6",
                      p: 2,
                      boxSizing: "border-box",
                      boxShadow: " rgba(149, 157, 165, 0.2) 0px 8px 24px",
                    }}
                  >
                    <Box
                    className="hide_scrollbar"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderBottom: "1px solid #f7f6f6",
                        pb: 2,
                        overflowX:'scroll'
                      }}
                    >
                      <Typography sx={{ fontWeight: 500 }}>
               Images
                      </Typography>
               
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 2,
                        justifyContent: "space-evenly",
                        mt: 2,
                      }}
                    >
{
  outletData?.product?.images?.map((image, index)=>(
     <Avatar src={image} variant="square" sx={{width:'140px', height:'140px', border:'1px solid #ccc'}}/>
  ))
}

                    
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
        </>
      )
     }
          </Box>
        </Grid>
      </Grid>

  <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{  p: 1, bgcolor: 'background.paper' }}>
          <MenuItem sx={{FontFamily:'outfit'}} onClick={handleRefundBuyer}>
            <Typography>Refund Buyer</Typography>
          </MenuItem>
          <MenuItem onClick={handleReleaseFund}>
          <Typography>Release Payment</Typography>
          </MenuItem>
        </Box>
      </Popover>
    </>
  );
};

export default Dispute;

const ListComponent = ({ data, selected }) => {
  const { seller, dispute, buyer } = data;

  const isSelected = selected ===  data;
  return (
    <>
      <ListItemButton selected={isSelected} sx={{ px: 1, borderBottom: 1, borderColor: "divider", marginBottom:2,...(isSelected && {
   backgroundColor:'#E9FCF9',
        borderLeft:'1px solid #3FB763'
      }) }}>
        <Box sx={{ display: "flex", width: "100%", columnGap: 2,  }}>
          <Box sx={{ width: "10%" }}>
            <Avatar sx={{ width: "30px", height: "30px" }}>
              {seller?.firstName.charAt(0)}
              {seller?.lastName.charAt(0)}
            </Avatar>
          </Box>
          <Box sx={{ width: "88%" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "13px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  width: "150px",
                }}
              >
                {buyer?.firstName} {buyer?.lastName}{" "}
              </Typography>
              <Typography sx={{ fontSize: "12px" }}>
                {" "}
                {formatToDate(parseFloat(dispute?.createdAt))}
              </Typography>
            </Box>
            <Box sx={{ height: "60px", mt: 0.5 }}>
              <Typography
                sx={{ fontSize: "12px" }}
                className="four-line-ellipsis"
              >
                {dispute?.description}
              </Typography>
            </Box>
          </Box>
        </Box>
      </ListItemButton>
    </>
  );
};

function formatToDate(timestamp) {
  const date = new Date(timestamp);
  const month = date.toLocaleString("default", { month: "short" });
  const day = date.getDate();
  return `${month} ${day}`;
}


function formatDateLong(timestamp) {
  // Create a new Date object with the provided timestamp
  const date = new Date(timestamp);

  // Define months array to convert month number to month name
  const months = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  // Get the month, day, year, hours, and minutes
  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();
  let hours = date.getHours();
  const minutes = date.getMinutes();

  // Convert hours to 12-hour format and determine AM or PM
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours %= 12;
  hours = hours || 12; // Handle midnight (0 hours)

  // Pad minutes with leading zero if less than 10
  const paddedMinutes = minutes < 10 ? '0' + minutes : minutes;

  // Construct the formatted date string
  const formattedDate = `${month} ${day}, ${year} ${hours}:${paddedMinutes} ${ampm}`;

  return formattedDate;
}
