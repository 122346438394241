import { MenuItem, TextField } from '@mui/material'
import React, {useState} from 'react'

const CustomSelect = ({variant, list, label, size, handleChangeDateRange}) => {
    const [selectedValue, setSelectedValue] = useState('');

  const handleChange = (event) => {
    handleChangeDateRange(event.target.value);
    setSelectedValue(event.target.value);
  };
  return (
    <>
      {variant !== "contained" ? (
        <>
          <TextField
            select
            fullWidth
            size={size ? size : "small"}
            label={label}
           value={selectedValue} // Set the value of TextField
      onChange={handleChange}
          >
            {list.map((option, index) => (
              <MenuItem
                sx={{ color: "#4A414F", fontWeight: 400, fontSize: "13px" }}
                key={index}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </>
      ) : (
        <>
          <TextField
            sx={{ fontSize: "12px" }}
            inputProps={{
              style: {
                fontSize: "12px",
                fontWeight: 500,
              },
            }}
            select
            fullWidth
            size="small"
            label={label}
               value={selectedValue} // Set the value of TextField
      onChange={handleChange}
          >
            {list.map((option, index) => (
              <MenuItem
                sx={{ color: "#4A414F", fontWeight: 400, fontSize: "13px" }}
                key={index}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </>
      )}
    </>
  );
}

export default CustomSelect