import React from 'react'
import { AppBar, IconButton, Box, Avatar, Typography, Popover, MenuList, MenuItem } from '@mui/material'
import { CiSearch } from "react-icons/ci";
import { IoIosNotificationsOutline } from "react-icons/io";
import { BsChevronDown } from "react-icons/bs";
import { FiSettings } from "react-icons/fi";
import { TbLogout } from "react-icons/tb";
import { Link, useNavigate } from 'react-router-dom';
import { logoutUser } from '../store/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import img1 from '../assets/img/profile.png'
import Settings from "@mui/icons-material/Settings";
import ArrowRight from "@mui/icons-material/ArrowRight";

const TopBar = () => {
   const [anchorEl, setAnchorEl] = React.useState(null);
const navigate = useNavigate()
   const handleClick = (event) => {
     setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
     setAnchorEl(null);
   };

   const open = Boolean(anchorEl);
   const id = open ? "simple-popover" : undefined;

const dispatch = useDispatch()
const user = useSelector((state)=>state.user.user)
   const handleLogout = () =>{
    dispatch(logoutUser())
navigate('/login')
   }
  return (
    <>
      <AppBar
        sx={{
          height: "6vh",
          width: {
            xl: "85%",
            lg: "80%",
            md: "80%",
            background: "#fff",
            boxShadow: "none",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "right",
            columnGap: 4,
            alignItems: "center",
            // border:'2px solid red',
            height: "100%",
            margin: "0 auto",
            width: "90%",
          }}
        >
          <Box sx={{ borderRight: "0.5px solid #ddd", pr: 3 }}>
            <IconButton>
              <CiSearch style={{ fontSize: "26px", fontWeight: "bolder" }} />
            </IconButton>

            <IconButton>
              <IoIosNotificationsOutline
                style={{ fontSize: "26px", fontWeight: "bolder" }}
              />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: 3,
              cursor: "pointer",
            }}
            onClick={handleClick}
          >
            <Avatar src={img1} />
            <Box>
              <Typography sx={{ color: "#333", fontWeight: 500 }}>
                {user?.profile?.firstName}
              </Typography>
              <Typography
                sx={{ color: "#666", fontWeight: 500, fontSize: "11px" }}
              >
                {user?.userType}
              </Typography>
            </Box>
            <IconButton>
              <BsChevronDown />
            </IconButton>
          </Box>
        </Box>
      </AppBar>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ py: 3 }}>
          <Link to="/dashboard/settings">
            <MenuItem
              onClick={handleClose}
              sx={{
                display: "flex",
                px: 3,
                alignItems: "center",
                columnGap: 3,
                py: 1,
                "& svg": {
                  color: "#000",
                  transition: "0.2s",
                  transform: "translateX(0) rotate(0)",
                },
                "&:hover, &:focus": {
                  bgcolor: "unset",
                  "& svg:first-of-type": {
                    transform: "translateX(-4px) rotate(-20deg)",
                  },
                  "& svg:last-of-type": {
                    right: 0,
                    opacity: 1,
                  },
                },
              }}
            >
        
          
                <Settings sx={{fontSize:'16px'}} />
                <ArrowRight
                  sx={{ position: "absolute", right: 2, opacity: 0, fontSize:'12px' }}
                />
     

              <Typography sx={{ fontSize: "16px", fontWeight: 400 }}>
                Settings
              </Typography>
            </MenuItem>
          </Link>
          <MenuItem
            onClick={handleLogout}
            sx={{ display: "flex", px: 3, alignItems: "center", columnGap: 3 }}
          >
            <TbLogout style={{ fontSize: "15px" }} />
            <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
              Logout
            </Typography>
          </MenuItem>
        </Box>
      </Popover>
    </>
  );
}

export default TopBar