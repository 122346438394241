import { Grid } from '@mui/material'
import React, { useEffect } from 'react'
import OverviewCard from '../../Components/App/OverviewCard'
import { IoWalletOutline } from "react-icons/io5";
import { HiOutlineUsers } from "react-icons/hi2";
import { LiaBoxSolid } from "react-icons/lia";
import { HiOutlineShoppingBag } from "react-icons/hi2";
import Analysis from '../../Components/App/Analysis';
import Top5 from '../../Components/App/Top5';
import Top10 from '../../Components/App/Top10';
import { useQuery } from '@apollo/client';
import { GET_DASHBOARD_VALUE, GET_ORDER_METRICS, GET_REVENUE_METRICS } from '../../Utils/Queries';
import Loader from '../../Components/Common/Loader';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../store/userSlice';

const Dashboard = () => {
const dispatch = useDispatch()
  const {loading, data, error} = useQuery(GET_DASHBOARD_VALUE)
  const { data:revenue_metrics} = useQuery(GET_REVENUE_METRICS)
  const { data:order_metrics} = useQuery(GET_ORDER_METRICS)

useEffect(()=>{
  if (error?.message.includes("Invalid Bearer token")) {
dispatch(logoutUser())

  }
}, [error])

const dashboardValue = data?.getAdminDashboardMetricValues;
const revenue_data = revenue_metrics?.getRevenueAnalysisByDateFilter;
const order_data = order_metrics?.getOrderAnalysisByDateFilter;


  const value = 1000000
  return (
    <>
      {loading && <Loader />}
      <Grid container spacing={3}>
        <Grid item lg={3} md={3}>
          <OverviewCard
            title="Total Users"
            icon={<HiOutlineUsers />}
            value={dashboardValue?.totalUser}
          />
        </Grid>
        <Grid item md={3}>
          <OverviewCard
            title="Total Orders"
            icon={<LiaBoxSolid />}
            value={
              dashboardValue?.totalProductOrder +
              dashboardValue?.totalServiceOrder
            }
          />
        </Grid>
        <Grid item md={3}>
          <OverviewCard
            title="Total Products"
            icon={<HiOutlineShoppingBag />}
            value={dashboardValue?.totalProduct}
          />
        </Grid>
        <Grid item md={3}>
          <OverviewCard
            title="Total Revenue"
            icon={<IoWalletOutline />}
            value={`${dashboardValue?.totalRevenue}`}
          />
        </Grid>
        <Grid item lg={6} md={12}>
          <Analysis revenue_data={revenue_data}  order_data={order_data}/>
          <Top5 />
        </Grid>
        <Grid item lg={6} md={12}>
          <Top10 />
        </Grid>
      </Grid>
    </>
  );
}

export default Dashboard