import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Grid,
  MenuItem,
  Select
} from "@mui/material";
import React, { useState } from "react";
import { GrFormNext } from "react-icons/gr";
import TransactionDetails from "./Drawer/TransactionDetails";
import EmptyData from "../Common/EmptyData";
import { LiaCopy } from "react-icons/lia";
import { MdDone } from "react-icons/md";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";

const Credit = ({ data }) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [selectedItem, setSelectedItem] = useState(null)
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

      const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePageLeft = (event, newPage) => {
    setPage(page - 1);
  };
  const handleChangePageRight = (event, newPage) => {
    setPage(page + 1);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

    const startRow = page * rowsPerPage + 1;
  const endRow = Math.min(startRow + rowsPerPage - 1, data?.length);
  const rowsText = `${startRow}-${endRow} of ${data?.length}`;
  return (
    <>
      <Box sx={{ mt: 2 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow sx={{ bgcolor: "#f8f8f7" }}>
                <TableCell sx={{ fontWeight: 600 }}>Transaction ID</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>User</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Amount</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Type</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Status</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!data || data?.length === 0 ? (
                <>
                <EmptyData/>
                </>
              ) : (
                <>
                  {data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell sx={{display:'flex', columnGap:2, alignItems:'center'}}>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: 400,
                            width: "120px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          #{item?._id}
                        </Typography>
                      <CopyButton id={item?._id}/>
                      </TableCell>
                      <TableCell
                        sx={{ fontSize: "12px" }}
                      >{`${item?.user?.firstName} - ${item?.user?.lastName}`}</TableCell>
                      <TableCell sx={{ fontSize: "12px" }}>
                        ${item?.amount.toLocaleString()}
                      </TableCell>
                      <TableCell sx={{ fontSize: "12px" }}>
                        {item?.action}
                      </TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            bgcolor: item?.status ===  "SUCCESS" ? "#ECF8F0" : "#FFE5E8",
                            color: item?.status === "SUCCESS" ? "#3FB763" : "#FF3347",
                            px: 3,
                            py: 1,
                            borderRadius: 1,
                            width: "fit-content",
                            fontSize:'12px'
                          }}
                        >
                          {
                            item?.status === "SUCCESS" ? "Success" : "Failed"
                          }
                     
                        </Box>
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={()=>{
                          handleDrawerOpen()
                        setSelectedItem(item)
                        }}>
                          <GrFormNext />
                        </IconButton>
                      </TableCell>
                     
                    </TableRow>

                  ))}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
                <Grid container sx={{ bgcolor: "#fff", mt: 3, px: 4, py: 1 }}>
        <Grid
          item
          lg={4}
          md={4}
          xs={4}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography
            sx={{
              color: "#424242",
              fontSize: "13px",
              fontFamily: "outfit",
            }}
          >
            {rowsText}
          </Typography>
        </Grid>
        <Grid item lg={8} md={8} xs={8}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: 2,
            }}
          >
            <Typography
              sx={{
                color: "#424242",
                fontSize: "13px",
                fontFamily: "outfit",
                display: { lg: "block", xs: "none" },
              }}
            >
              You are currently on Page
            </Typography>
            <Select
              size="small"
              sx={{
                fontFamily: "outfit",
                color: "#424242",
                fontSize: "13px",
              }}
              value={rowsPerPage}
              onChange={handleChangeRowsPerPage}
              InputProps={{
                style: {
                  fontFamily: "outfit",
                  fontSize: "13px",
                  // borderRadius: "8px",

                  // Replace with your desired font family
                },
              }}
            >
              <MenuItem
                sx={{ fontFamily: "outfit", color: "#424242" }}
                value={5}
              >
                5
              </MenuItem>
              <MenuItem
                sx={{ fontFamily: "outfit", color: "#424242" }}
                value={10}
              >
                10
              </MenuItem>
              <MenuItem
                sx={{ fontFamily: "outfit", color: "#424242" }}
                value={25}
              >
                25
              </MenuItem>
              <MenuItem
                sx={{ fontFamily: "outfit", color: "#424242" }}
                value={50}
              >
                50
              </MenuItem>
            </Select>
            <Box
              sx={{
                borderLeft: "1px solid #424242",
                pl: { md: 4, xs: 2 },
                ml: { md: 3, xs: 2 },
              }}
            >
              <IconButton
                disabled={startRow === 1}
                onClick={handleChangePageLeft}
                sx={{
                  width: "30px",
                  height: "30px",
                  border: "1px solid #424242",
                  "&.Mui-disabled": {
                    border: "1px solid #4242421a",
                  },
                }}
              >
                <ChevronLeftRoundedIcon />
              </IconButton>
              <IconButton
                disabled={endRow === data?.length}
                onClick={handleChangePageRight}
                sx={{
                  ml: 4,
                  width: "30px",
                  height: "30px",
                  border: "1px solid #424242",
                  "&.Mui-disabled": {
                    border: "1px solid #4242421a",
                  },
                }}
              >
                <ChevronRightRoundedIcon />
              </IconButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
      </Box>

       <TransactionDetails open={drawerOpen} onClose={handleDrawerClose}  data={selectedItem}/>
    </>
  );
};

export default Credit;


const CopyButton = ({id}) =>{

  const [isCopy, setIsCopy] = useState(false)
      const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setIsCopy(true);
        setTimeout(() => setIsCopy(false), 2000); // Reset copied state after 2 seconds
      })
      .catch((err) => console.error("Failed to copy:", err));
  };
  return (
    <>
    <IconButton onClick={()=>handleCopy(id)}>
      {
        isCopy ? (
          <MdDone  style={{color:'#3FB763'}}/>
        ):(

<LiaCopy />
        )
      }
    </IconButton>
    </>
  )
}
