import React from "react";
import { Drawer, InputLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Typography,
  Avatar,
  IconButton,
  Button,
  Radio,
} from "@mui/material";
import { IoMdClose } from "react-icons/io";
import CustomInput from "../Common/CustomInput";
import CustomSelect from "../Common/CustomSelect";

const categories = [
"Users","Orders","Products","Services","Disputes","Mamapurse",  "Team"
, "Notifications"];

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 540,
    flexShrink: 0,
    p: 4,
  },
  drawerPaper: {
    width: 540,
    p: 4,
  },
  toolbar: theme.mixins.toolbar,
}));
const CreateRole = ({ open, onClose }) => {
  const classes = useStyles();

  return (
    <Drawer
      className={classes.drawer}
      anchor="right"
      open={open}
      onClose={onClose}
      classes={{ paper: classes.drawerPaper }}
    >
      <Box sx={{ px: 4, py: 5 }} className={classes.toolbar}>
        <Box align="right">
          <IconButton onClick={onClose}>
            <IoMdClose style={{ fontSize: "20px", color: "#000" }} />
          </IconButton>
        </Box>
        <Typography
          sx={{
            fontFamily: "outfit",
            color: "#0D0D0D",
            fontSize: "14px",
            fontWeight: 600,
            // ml: 5,
            mb: 3,
          }}
        >
          Create Sub Category
        </Typography>

        <Box sx={{ mt: 6 }}>
          <InputLabel sx={{ color: "#4D4D4D", fontSize: "12px" }}>
            Select Category <span style={{ color: "#FF4C5E" }}>*</span>
          </InputLabel>
          <Box sx={{ mt: 2 }}>
         <CustomInput label="Title"/>
          </Box>

          <Box sx={{ mt: 3 }}>
<Typography>Select Features</Typography>
<Box sx={{mt:2}}>
  {
    categories?.map((item, index)=>(
      <Box sx={{display:'flex', alignItems:'center', columnGap:2}}>
        <Radio/>
        <Typography>{item}</Typography>
        </Box>
    ))
  }
</Box>
          </Box>

          <Button variant="contained" fullWidth sx={{ mt: 4 }}>
            Add Sub Category
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};
export default CreateRole;
