import React, {useEffect, useRef, useState} from 'react'
import {Box, Typography} from '@mui/material'
import addImgIcon from '../../assets/img/gallery.svg'

const ImageUploader = ({placeholder, getFile, url}) => {
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState("");
  const [selectedFileURL, setSelectedFileURL] = useState(null);
  const handleButtonClick = () => {
    fileInputRef.current.click(); // Trigger file input click
  };

    const maxFileSize = 1 * 1024 * 1024;

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
        if (file.size > maxFileSize) {
      alert("File size is too large. Please choose a smaller file.");
    } else {
      setSelectedFile(file);
          if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const dataURL = e.target.result;
        setSelectedFileURL(dataURL);
       
      };
      reader.readAsDataURL(file);
    }
    }
    
  
 


  };

  useEffect(()=>{
    getFile(selectedFile);
    url(selectedFileURL)
  }, [selectedFile, selectedFileURL])
  return (
    <>
      <Box
        sx={{
          border: "1px dashed #ccc",
          borderRadius: 2,
          padding: "44px 50px",
          boxSizing:'border-box',
          cursor:'pointer'
        }}
        onClick={handleButtonClick}
      >
          <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileSelect}
                />
        <Box
        
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            // border:'1px solid'
          }}
        >
          <Box >

          <img src={addImgIcon} width={8} />
          </Box>
          <Typography sx={{color:'#1a1a1a', fontWeight:500, mt:2, textAlign:'center'}}>{placeholder}</Typography>
          <Typography sx={{color:'#666', fontSize:'12px', fontWeight:500, mt:2}}>or drag and drop it here</Typography>
        </Box>
      </Box>

    </>
  );
}

export default ImageUploader