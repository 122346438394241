import React, {useState} from 'react';
import { IconButton } from '@mui/material';
import { LiaCopy } from "react-icons/lia";
import { MdDone } from "react-icons/md";

const CopyButton = ({id}) => {
    const [isCopy, setIsCopy] = useState(false)
      const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setIsCopy(true);
        setTimeout(() => setIsCopy(false), 2000); // Reset copied state after 2 seconds
      })
      .catch((err) => console.error("Failed to copy:", err));
  };
  return (
    <>
    <IconButton onClick={()=>handleCopy(id)}>
      {
        isCopy ? (
          <MdDone  style={{color:'#3FB763'}}/>
        ):(

<LiaCopy />
        )
      }
    </IconButton>
    </>
  )
}

export default CopyButton