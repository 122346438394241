import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#2e1833",
    },
    secondary: {
      main: "#ffcb28",
    },
  },
  typography: {
    fontFamily: "outfit",
    fontSize: "14px",
    fontWeight: 200,
    h1: {
      fontSize: "48px",
      fontWeight: 900,
    },
    h2: {
      fontSize: "36px",
      fontWeight: 800,
    },
    h3: {
      fontSize: "24px",
      fontWeight: 700,
    },
    h4: {
      fontSize: "20px",
      fontWeight: 600,
    },
    h5: {
      fontWeight: 500,
      fontSize: "16px",
    },
    body1: {
      fontWeight: 200,
      fontSize: "14px",
    },

    button: {
      textTransform: "initial",
      fontFamily: "outfit",
    },
  },
});