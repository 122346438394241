import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import React from "react";
import CopyButton from "../../Common/CopyButton";
import EmptyData from "../../Common/EmptyData";

const UserProductStore = ({ data }) => {
  return (
    <>
      {!data || data?.length === 0 ? (
        <>
        <EmptyData/>
        </>
      ) : (
        <>
          <TableContainer>
            <Table>
              <TableHead sx={{ bgcolor: "#E6E6E6" }}>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Products</TableCell>
                  <TableCell>Stock Avail.</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((item, index) => (
                  <TableRow>
                   <TableCell sx={{display:'flex', alignItems:'center', columnGap:2}}>
                        <Typography sx={{ color: "#4d4d4d", fontWeight: 500 , width:'100px',  whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis'}}>
                    {item?._id}
            
                        </Typography>
                        <CopyButton id={item?._id}/>
                 
                      </TableCell>
                    <TableCell>{item?.name}</TableCell>
                    <TableCell>{item?.isInStock ? "True" :  "False"}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
};

export default UserProductStore;
