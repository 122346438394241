import React, { useState } from "react";
import { Drawer, InputLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Typography,
  Avatar,
  IconButton,
  Button,
  TextField,
  MenuItem
} from "@mui/material";
import { IoMdClose } from "react-icons/io";
import CustomInput from "../Common/CustomInput";
import CustomSelect from "../Common/CustomSelect";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ROLES } from "../../Utils/Queries";
import { CREATE_TEAM } from "../../Utils/Mutation";
import { useSnackbarHook } from "../../hooks/useSnackbarHook";
import Loader from "../Common/Loader";

const categories = [
  {
    value: "Men",
    label: "Men",
  },
];

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 540,
    flexShrink: 0,
    p: 4,
  },
  drawerPaper: {
    width: 540,
    p: 4,
  },
  toolbar: theme.mixins.toolbar,
}));
const CreateTeam = ({ open, onClose }) => {
  const classes = useStyles();
  const {handleClick} = useSnackbarHook()

  const {data, loading} = useQuery(GET_ROLES)
  console.log(data, "=>Roles")

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [password, setPassword] = useState("")

  const [createTeam, {loading:teamLoading}] = useMutation(CREATE_TEAM)

  const handleCreateTeam = async () =>{
    await createTeam({
      variables:{
        firstName,
        lastName,
        email,
        role,
        password
      }
    }).then((res)=>{
      console.log(res)
      handleClick("User Created Successfully", "success")
      setFirstName("")
      setLastName("")
      setEmail("")
      setPassword("")
      
    }).catch((err)=>{
      console.log(err)
      handleClick(`${err.message}`, "error")
    })
  }


  return (

    <Drawer
      className={classes.drawer}
      anchor="right"
      open={open}
      onClose={onClose}
      classes={{ paper: classes.drawerPaper }}
    >
      {
        teamLoading && (<Loader/>)
      }
      <Box sx={{ px: 4, py: 5 }} className={classes.toolbar}>
        <Box align="right">
          <IconButton onClick={onClose}>
            <IoMdClose style={{ fontSize: "20px", color: "#000" }} />
          </IconButton>
        </Box>
        <Typography
          sx={{
            fontFamily: "outfit",
            color: "#0D0D0D",
            fontSize: "14px",
            fontWeight: 600,
            // ml: 5,
            mb: 3,
          }}
        >
          Add User
        </Typography>

        <Box sx={{ mt: 6 }}>
          <Typography
            sx={{ color: "#4D4D4D", fontSize: "12px", fontWeight: 400 }}
          >
            Select Role <span style={{ color: "#FF4C5E" }}>*</span>
          </Typography>
          <Box sx={{ mt: 2 }}>
            <TextField
              select
              fullWidth
              value={role}
              onChange={(e)=>setRole(e.target.value)}
              size="large"
              InputProps={{
                style: {
                  marginBottom: "20px",
                  fontSize: "13px",
                  borderRadius: "8px",
                  offset: " 1px solid #f2f2f2",
                  fontWeight: 400,
                  // Replace with your desired font family
                },
              }}
            >
              {data?.getAdminRoles?.map((option, index) => (
                <MenuItem
                  sx={{ color: "#4A414F", fontWeight: 400, fontSize: "13px" }}
                  key={index}
                  value={option._id}
                >
                  {option.title}
                </MenuItem>
              ))}
            </TextField>
          </Box>

          <Box sx={{ mt: 0 }}>
            <CustomInput label="First Name" value={firstName} onChange={(e)=>setFirstName(e.target.value)}/>
          </Box>
          <Box sx={{ mt: 0 }}>
            <CustomInput label="Last Name"  value={lastName} onChange={(e)=>setLastName(e.target.value)}/>
          </Box>
          <Box sx={{ mt: 0 }}>
            <CustomInput label="Email Address"  value={email} onChange={(e)=>setEmail(e.target.value)}/>
          </Box>
          <Box sx={{ mt: 0 }}>
            <CustomInput label="Create Password"  value={password} onChange={(e)=>setPassword(e.target.value)}/>
          </Box>

          <Button  onClick={handleCreateTeam}variant="contained" fullWidth sx={{ mt: 4 }} disabled={!firstName || !lastName || !email || !role || !password}>
            Create User Profile
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};
export default CreateTeam;
