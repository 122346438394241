import { LuLayoutDashboard } from "react-icons/lu";
import { HiOutlineUsers } from "react-icons/hi2";
import { LiaBoxSolid } from "react-icons/lia";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { VscTools } from "react-icons/vsc";
import { IoWalletOutline } from "react-icons/io5";
import { PiNotification } from "react-icons/pi";
import { TiGroupOutline } from "react-icons/ti";
import { MdOutlineHeadset } from "react-icons/md";
import { CiBoxList } from "react-icons/ci";
import { CiImageOn } from "react-icons/ci";

export const NavData = [
  {
    title: "Dashboard",
    icon: <LuLayoutDashboard />,
    link: "/",
  },
  {
    title: "Users",
    icon: <HiOutlineUsers />,
    link: "/dashboard/users",
  },
  {
    title: "Orders",
    icon: <LiaBoxSolid />,
    link: "/dashboard/orders",
  },
  {
    title: "Products",
    icon: <AiOutlineShoppingCart />,
    link: "/dashboard/products",
  },
  {
    title: "Services",
    icon: <VscTools />,
    link: "/dashboard/services",
  },
  {
    title: "Mamapurse",
    icon: <IoWalletOutline />,
    link: "/dashboard/mamapurse",
  },
  {
    title: "Categories",
    icon: <CiBoxList />,
    link: "/dashboard/categories",
  },
  {
    title: "Banners",
    icon: <CiImageOn />,
    link: "/dashboard/banner",
  },
  {
    title: "Notifications",
    icon: <PiNotification />,
    link: "/dashboard/notifications",
  },
  {
    title: "Team",
    icon: <TiGroupOutline />,
    link: "/dashboard/team",
  },
  {
    title: "Dispute",
    icon: <MdOutlineHeadset />,
    link: "/dashboard/dispute",
  },
];
