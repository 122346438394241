import { Box, Button, InputAdornment, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FaAngleDown } from "react-icons/fa6";
import { RiFileDownloadLine } from "react-icons/ri";
import jsPDF from 'jspdf';
import 'jspdf-autotable';


import CustomTabs from '../../Components/Common/CustomTabs';

import {Buyers, AllUsers, Sellers} from '../../Components/Users'
import { useQuery } from '@apollo/client';
import { GET_USER_LIST } from '../../Utils/Queries';
import Loader from '../../Components/Common/Loader';
import CustomSearch from '../../Components/Common/CustomSearch';
import CustomSelect from '../../Components/Common/CustomSelect';

const currencies = [
  {
    value: "Today",
    label: "Today",
  },
  {
    value: "Last Week",
    label: "Last Week",
  },
  {
    value: "This Month",
    label: "This Month",
  },
  {
    value: "Last Month",
    label: "Last Month",
  },
  {
    value: "Custom Range",
    label: "Custom Range",
  },
];

const Users = () => {
    const [value, setValue] = React.useState(0);
  const [searchParams, setSearchParams] = useState("");
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const {data, loading, error} = useQuery(GET_USER_LIST)

    const [searchedItems, setSearchedItems]  = useState([])

    useEffect(()=>{
      setSearchedItems(data?.listAllUsers?.items)
    }, [data])

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();

    // Filter items based on the search term
    const filteredItems = data?.listAllUsers?.items?.filter(
      (item) => item?.firstName.toLowerCase().includes(searchTerm)
    );

    setSearchedItems(filteredItems);
    setSearchParams(searchTerm);
  };


const userData = searchedItems;

const TabData = [
  {
    label: "All Users",
    component: <AllUsers data={userData} />,
  },
  {
    label: "Buyers",
    component: <Buyers data={userData} />,
  },
  {
    label: "Sellers",
    component: <Sellers data={userData} />,
  },
];

console.log(userData)

  const generatePDF = () => {
  const doc = new jsPDF();

  doc.setFont('helvetica', 'bold');
  doc.setFontSize(16);

  // Add heading
  doc.text(`Mamaket Users List - ${formatDate(new Date())}`, 10, 10);
  // Set custom font and size for the table
  doc.setFont('helvetica');
  doc.setFontSize(12);

  const tableColumn = [
    "ID", "Name", "Email", "Phone", "Location", "Profile", "Status" , "Date"
  ];
  const tableRows = [];

  searchedItems.forEach(item => {
    const itemData = [
      item._id,
    `${  item.firstName} ${  item.lastName}`,
      `${item.email}`,
      item.phone,
      item.
hasPaidMembershipFee ? 'Seller' : 'Buyer',
    `${item?.addresses[0]?.city?.name}, ${item?.addresses[0]?.city?.state?.name}, ${item?.addresses[0]?.city?.state?.country?.name}`,
      item.
activityStatus,
     formatDate(item?.createdAt)
    ];
    tableRows.push(itemData);
  });

  doc.autoTable({
    head: [tableColumn],
    body: tableRows,
    startY: 15,
    styles: {
      fontSize: 6,
      font: 'helvetica'
    },
    headStyles: {
 fillColor: [92, 48, 105]
    }
  });


  doc.save(`mamaket_ul(${new Date()}).pdf`);
};
  return (
    <>
      {loading && <Loader />}
      <Box sx={{ bgcolor: "#fff", p: 2 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "70%" }}>
            <CustomSearch placeholder={"Search by ID, product, or others..."}  value={searchParams}  onChange={handleSearch}/>
          </Box>
          <Box
            sx={{
              width: "15%",
              bgcolor: "#F8F7F7",

              // py: 1,
              fontSize: "12px",
              display: "flex",
              columnGap: 1,
              alignItems: "center",
              borderRadius: 1,
              justifyContent: "center",
              // px: 2,
            }}
          >
            {/* <CustomSelect variant="contained" list={currencies} label="Today"/> */}
     
          </Box>
          <Button
          onClick={generatePDF}
            endIcon={<RiFileDownloadLine style={{ color: "#666" }} />}
            sx={{
              color: "#666",
              bgcolor: "#F8F7F7",
              width: "10%",
              py: 1,
              fontSize: "12px",
            }}
          >
            Download
          </Button>
        </Box>

        <Box sx={{ mt: 4 }}>
          <CustomTabs
            value={value}
            handleChange={handleChange}
            data={TabData}
            variant="standard"
          />
        </Box>
      </Box>
    </>
  );
}

export default Users

const formatDate = (dateString) => {
  const date = new Date(dateString);

  const day = date.getUTCDate();
  const month = date.toLocaleString('en-GB', { month: 'short' });
  const year = date.getUTCFullYear();

  return `${day} ${month} ${year}`;
};

