import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
 IconButton,
  Select,
  MenuItem
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomSearch from "../Common/CustomSearch";
import { RiPlayListAddFill } from "react-icons/ri";
import CreateCategory from "./Drawer/CreateCategory";
import CreateSub from "./Drawer/CreateSub";
import EditCategory from "./Drawer/EditCategory";
import { useLazyQuery } from "@apollo/client";
import { GET_CATEGORIES, GET_SUB_CATEGORIES } from "../../Utils/Queries";
import Loader from "../Common/Loader";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";

const Products = () => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [categoryItem, setCategoryItem] = useState(null);
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const [drawerOpen2, setDrawerOpen2] = React.useState(false);
  const handleDrawerOpen2 = () => {
    setDrawerOpen2(true);
  };

  const handleDrawerClose2 = () => {
    setDrawerOpen2(false);
  };

  const [drawerOpen3, setDrawerOpen3] = React.useState(false);
  const handleDrawerOpen3 = (data) => {
    setDrawerOpen3(true);
    setCategoryItem(data);
  };

  const handleDrawerClose3 = () => {
    setDrawerOpen3(false);
  };

  const [data, setData] = useState(null);
    const [filteredData, setFilteredData] = useState([]);
      const [searchParams, setSearchParams] = useState("");

  const [getCategory, { loading, refetch }] = useLazyQuery(GET_CATEGORIES);

  const handleGetCategory = async () => {
    await getCategory()
      .then((res) => {
        setData(res?.data);
        console.log(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleGetCategory();
  }, []);

    useEffect(() => {
    setFilteredData(data?.getCategories);
  }, [data]);

    const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();

    // Filter items based on the search term
    const filteredItems = data?.getCategories?.filter(
      (item) => item?.name.toLowerCase().includes(searchTerm)
    );

    setFilteredData(filteredItems);
    setSearchParams(searchTerm);
  };

        const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePageLeft = (event, newPage) => {
    setPage(page - 1);
  };
  const handleChangePageRight = (event, newPage) => {
    setPage(page + 1);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

    const startRow = page * rowsPerPage + 1;
  const endRow = Math.min(startRow + rowsPerPage - 1, filteredData?.length);
  const rowsText = `${startRow}-${endRow} of ${filteredData?.length}`;

  return (
    <>
      {loading && <Loader />}
      <Box sx={{ pt: 2, width: "100%" }}>
        <Grid container spacing={2}>
          <Grid item lg={6} md={6}>
            <CustomSearch placeholder={"Search by Title"}       value={searchParams}
            onChange={handleSearch} />
          </Grid>
          <Grid item lg={3}>
            <Box>
              <Button
                onClick={handleDrawerOpen}
                variant="contained"
                fullWidth
                endIcon={<RiPlayListAddFill />}
              >
                Create Category
              </Button>
            </Box>
          </Grid>
          <Grid item lg={3}>
            <Box>
              <Button
                onClick={handleDrawerOpen2}
                variant="contained"
                fullWidth
                endIcon={<RiPlayListAddFill />}
              >
                Add Sub Category
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ mt: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                {/* <TableCell>Sub Categories</TableCell> */}
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!data?.getCategories || data?.getCategories?.length === 0 ? (
                <></>
              ) : (
                <>
                  {filteredData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((item, index) => (
                    <TableRow
                      sx={{ cursor: "pointer" }}
                      hover
                      onClick={() => {
                        handleDrawerOpen3(item);
                      }}
                    >
                      <TableCell>{item?.name}</TableCell>
                      {/* <TableCell>""</TableCell> */}
                      <TableCell>
                        {item?.isActive ? (
                          <Typography
                            sx={{ color: "#3FB763", fontWeight: 500 }}
                          >
                            Active
                          </Typography>
                        ) : (
                          <Typography sx={{ color: "#FF3347" }}>
                            Inactive
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )}
            </TableBody>
          </Table>

               <Grid container sx={{ bgcolor: "#fff", mt: 3, px: 4, py: 1 }}>
        <Grid
          item
          lg={4}
          md={4}
          xs={4}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography
            sx={{
              color: "#424242",
              fontSize: "13px",
              fontFamily: "outfit",
            }}
          >
            {rowsText}
          </Typography>
        </Grid>
        <Grid item lg={8} md={8} xs={8}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: 2,
            }}
          >
            <Typography
              sx={{
                color: "#424242",
                fontSize: "13px",
                fontFamily: "outfit",
                display: { lg: "block", xs: "none" },
              }}
            >
              You are currently on Page
            </Typography>
            <Select
              size="small"
              sx={{
                fontFamily: "outfit",
                color: "#424242",
                fontSize: "13px",
              }}
              value={rowsPerPage}
              onChange={handleChangeRowsPerPage}
              InputProps={{
                style: {
                  fontFamily: "outfit",
                  fontSize: "13px",
                  // borderRadius: "8px",

                  // Replace with your desired font family
                },
              }}
            >
              <MenuItem
                sx={{ fontFamily: "outfit", color: "#424242" }}
                value={5}
              >
                5
              </MenuItem>
              <MenuItem
                sx={{ fontFamily: "outfit", color: "#424242" }}
                value={10}
              >
                10
              </MenuItem>
              <MenuItem
                sx={{ fontFamily: "outfit", color: "#424242" }}
                value={25}
              >
                25
              </MenuItem>
              <MenuItem
                sx={{ fontFamily: "outfit", color: "#424242" }}
                value={50}
              >
                50
              </MenuItem>
            </Select>
            <Box
              sx={{
                borderLeft: "1px solid #424242",
                pl: { md: 4, xs: 2 },
                ml: { md: 3, xs: 2 },
              }}
            >
              <IconButton
                disabled={startRow === 1}
                onClick={handleChangePageLeft}
                sx={{
                  width: "30px",
                  height: "30px",
                  border: "1px solid #424242",
                  "&.Mui-disabled": {
                    border: "1px solid #4242421a",
                  },
                }}
              >
                <ChevronLeftRoundedIcon />
              </IconButton>
              <IconButton
                disabled={endRow === filteredData?.length}
                onClick={handleChangePageRight}
                sx={{
                  ml: 4,
                  width: "30px",
                  height: "30px",
                  border: "1px solid #424242",
                  "&.Mui-disabled": {
                    border: "1px solid #4242421a",
                  },
                }}
              >
                <ChevronRightRoundedIcon />
              </IconButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
        </Box>
      </Box>

      <CreateCategory
        open={drawerOpen}
        onClose={handleDrawerClose}
        action={refetch}
      />
      <CreateSub
        open={drawerOpen2}
        onClose={handleDrawerClose2}
        categories={data?.getCategories}
        action={refetch}
      />
      <EditCategory
        open={drawerOpen3}
        onClose={handleDrawerClose3}
        data={categoryItem}
        action={handleGetCategory}
      />
    </>
  );
};

export default Products;
