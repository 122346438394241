import { Skeleton, Typography } from '@mui/material';
import { Box } from '@mui/system'
import React from 'react'


const OverviewCard = ({icon, title, value}) => {
  return (
<>
<Box sx={{bgcolor:'#fff', p:2}}>
<Box sx={{display:'flex', columnGap:3, alignItems:'center'}}>
  {icon}
  <Typography variant='h5' sx={{color:'#4d4d4d', fontSize:'14px', fontWeight:600}}>{title}</Typography>
</Box>
{
  value ? (
  <Typography variant='h3' sx={{color:'#101010', mt:2 }}>{value}</Typography>
  ):(
    <Skeleton variant='text' sx={{fontSize:'2rem', width:'50px'}}/>
  )
}

</Box>
</>
  )
}

export default OverviewCard