import { Box, Button, Grid, Typography, Avatar, Popover, MenuItem } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { MdChevronLeft } from "react-icons/md";
import { IoChevronDown } from "react-icons/io5";
import { Link, useParams } from "react-router-dom";
import { RiRefundLine } from "react-icons/ri";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import img1 from '../../assets/img/1.jpg'
import { useMutation, useQuery } from '@apollo/client';
import { GET_SINGLE_PRODUCT_ORDERS } from '../../Utils/Queries';
import { BiTransfer } from "react-icons/bi";
import { REFUND_CUSTOMER_ORDER } from '../../Utils/Mutation';
import Loader from '../Common/Loader';
import { useSnackbarHook } from "../../hooks/useSnackbarHook";


const steps = [
  {
    label: "Payment received",

  },
  {
    label: "Order Processing",

  },
  {
    label: "Ready for Pickup",
    description: ``,
  },
  {
    label: "Picked Up",
    description: ``,
  },
  {
    label: "Review & Release Fund",
    description: ``,
  },
];
const OrderDetails = () => {
    const [activeStep, setActiveStep] = React.useState(0);

    const {id} = useParams()

    const {data, loading} = useQuery(GET_SINGLE_PRODUCT_ORDERS,{variables:{textToSearch:id}})

 const [buttonStatus, setButtonStatus]  = useState("Refund Order")

 const {handleClick:handleAlert} = useSnackbarHook()

    const singleOrder = data?.fetchOrdersByAdmin?.items?.filter(item => item._id === id)[0]
       console.log(singleOrder,id)


       const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const idPopover = open ? 'simple-popover' : undefined;

  console.log(singleOrder)

  useEffect(()=>{
if(singleOrder?.status  ===  "Review & Release Fund"){
  setActiveStep(4)
}else if (singleOrder?.status  ===  "Paid"){
setActiveStep(1)

}
else if (singleOrder?.status  ===  "Shipped"){
setActiveStep(2)
}
else if (singleOrder?.status  ===  "Delivered"){
setActiveStep(3)
}
else if (singleOrder?.status  ===  "Canceled"){
setActiveStep("")
}
else{
  setActiveStep(0)
}
  },[singleOrder])

  const [refundOrder ] = useMutation(REFUND_CUSTOMER_ORDER)
const [isLoading, setIsLoading] = useState(false)

  const  handleRefundOrder = async () =>{
    setIsLoading(true)
    await refundOrder({variables:{
      itemId:id
    }}).then((res)=>{
          setIsLoading(false)
      console.log(res)
    }).catch((err)=>{
          setIsLoading(false)
      console.log(err)
          handleAlert(`${err?.message}`, "error")
    })
  }
  return (
    <>
    {
      isLoading && <Loader/>
    }
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Link to="/dashboard/orders">
          <Button
            sx={{ bgcolor: "#fff", color: "#666" }}
            startIcon={<MdChevronLeft />}
          >
            Order Details
          </Button>
        </Link>
        {/* <Box>
        {
          buttonStatus === "Refund Order"  ? (
              <Button
              onClick={handleRefundOrder}
            sx={{ bgcolor: "#fff", color: "#666" }}
            startIcon={<RiRefundLine />}
          >
            Refund Order
          </Button>
          ):(
              <Button
            sx={{ bgcolor: "#fff", color: "#666" }}
            startIcon={<BiTransfer />}
          >
            Release Funds
          </Button>
          )
        }
          <Button
          aria-describedby={idPopover} variant="contained" onClick={handleClick}
            sx={{ bgcolor: "#fff", color: "#666", ml: 3 }}
            startIcon={<IoChevronDown />}
          >
            Change Status
          </Button>
        </Box> */}
      </Box>

      <Grid container sx={{ mt: 2 }} spacing={3}>
        <Grid item lg={8}>
          <Box sx={{ bgcolor: "#fff", p: 2 }}>
            <Typography sx={{ color: "#4d4d4d", fontWeight: 500, fontSize:'12px' }}>
              ID: {singleOrder?._id}
            </Typography>
            <Box
              sx={{
                mt: 3,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", columnGap: 3 }}>
                <Avatar
                  src={singleOrder?.product?.images[0]}
                  variant="square"
                  sx={{ width: "88px", height: "88px" }}
                />
                <Box>
                  <Typography sx={{ fontWeight: 500, fontSize: "16px" }}>
                  {singleOrder?.product?.name}
                  </Typography>
                  <Box sx={{ display: "flex", columnGap: 5, mt: 5 }}>
                    <Typography sx={{ fontWeight: 400, fontSize: "14px" }}>
                      ${singleOrder?.pricePerUnit?.toLocaleString()}
                    </Typography>
                    <Typography sx={{ fontWeight: 400, fontSize: "14px" }}>
                      QTY: {singleOrder?.quantity}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box
                  sx={{ px: 3, bgcolor: "#f2f2f2", borderRadius: 10, py: 0.5, fontSize:'12px' ,      ...(singleOrder?.status === "Canceled") &&{
                            color:'#FF3347',
                         
                          }}}
                >
                  {singleOrder?.status}
                </Box>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "14px",
                    mt: 5,
                    textAlign: "right",
                  }}
                >
                 ${singleOrder?.amountPaid?.toLocaleString()}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ bgcolor: "#fff", p: 2, mt: 1 }}>
            <Typography sx={{ fontWeight: 500 }}>Order Details</Typography>

            <Box sx={{ mt: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography>Buyer:</Typography>
                <Typography
                  sx={{
                    color: "#101010",
                    textDecoration: "underline",
                    fontWeight: 500,
                  }}
                >
         {`${singleOrder?.buyer?.firstName} ${singleOrder?.buyer?.lastName}`}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mt: 1,
                }}
              >
                <Typography>Date:</Typography>
                <Typography sx={{ color: "#101010", fontWeight: 500 }}>
                 {formatDate(singleOrder?.createdAt)}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mt: 1,
                }}
              >
                <Typography>Time:</Typography>
                <Typography sx={{ color: "#101010", fontWeight: 500 }}>
              {formatTime(singleOrder?.createdAt)}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ bgcolor: "#fff", p: 2, mt: 1 }}>
            <Typography sx={{ fontWeight: 500 }}>Payment</Typography>

            <Box sx={{ mt: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography>Amount:</Typography>
                <Typography
                  sx={{
                    color: "#101010",

                    fontWeight: 500,
                  }}
                >
                     ${singleOrder?.amountPaid?.toLocaleString()}
                </Typography>
              </Box>
              {/* <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mt: 1,
                }}
              >
                <Typography>Payment Method:</Typography>
                <Typography sx={{ color: "#101010", fontWeight: 500 }}>
                  Mamapurse
                </Typography>
              </Box> */}
            </Box>
          </Box>
          <Box sx={{ bgcolor: "#fff", p: 2, mt: 1 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontWeight: 500 }}>Seller Details</Typography>
              <Link to={`/dashboard/user-details/${singleOrder?.seller?._id}`}>
              <Button sx={{ fontWeight: 500, color: "#3FB763" }}>
               View Profile
              </Button>
              </Link>
            </Box>

            <Box sx={{ mt: 2 }}>
              <Box sx={{}}>
                <Typography>Full Name</Typography>
                <Typography
                  sx={{
                    color: "#101010",

                    fontWeight: 500,
                  }}
                >
                 {`${singleOrder?.seller?.firstName} ${singleOrder?.seller?.lastName}`}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={4}>
          <Box sx={{ p: 2, bgcolor: "#FFF" }}>
            <Typography>Status</Typography>
            <Box>
              <Stepper  activeStep={activeStep} orientation="vertical">
                {steps.map((step, index) => (
                  <Step key={step.label} sx={{ fontFamily: "outfit" }}>
                    <StepLabel

                    // StepIconProps={{
                    // }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "outfit",
                          color: "#333",
                          fontSize: "14px",
                          fontWeight: 500,
                          ...(singleOrder?.status === "Canceled") &&{
                            color:'#FF3347',
                            opacity:0.3
                          }
                        }}
                      >
                        {" "}
                        {step.label}
                      </Typography>
                    </StepLabel>
                    <StepContent>
                      <Typography
                        sx={{
                          fontFamily: "outfit",
                          color: "#666",
                          fontSize: "12px",
                        }}
                      >
                        {step.description}
                      </Typography>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Box>
        </Grid>
      </Grid>

        <Popover
        id={idPopover}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
      <Box sx={{p:1}}>
        <MenuItem onClick={()=>{
          setAnchorEl(null)
          setButtonStatus("Release Funds")
        }}>Release Funds</MenuItem>
        <MenuItem onClick={()=>{
          setAnchorEl(null)
          setButtonStatus("Refund Order")
        }}>Refund Order</MenuItem>
      </Box>
      </Popover>
    </>
  );
}

export default OrderDetails


function formatDate(dateString) {
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  return new Date(dateString).toLocaleDateString('en-GB', options);

  
}

function formatTime(isoString) {
  const date = new Date(isoString);
  const formattedTime = date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true // to display AM/PM
  });
  return formattedTime;
}


