import React from 'react'
import { InputLabel, TextField } from '@mui/material';
const CustomInput = ({label, name, value, onChange, placeholder , size, rows, multiline}) => {
  return (
    <>
      <InputLabel
        sx={{
          color: "#2B2B2B",
          fontSize: "13px",
          fontWeight: 600,
          // fontFamily: "Gordita",
        }}
      >
      {label}
      </InputLabel>
      <TextField
      multiline={multiline}
      rows={rows}
      type=''
        // onKeyPress={handleKeyPress}
        placeholder={placeholder}
        size={size ? size : "large"}
        fullWidth
        value={value}
        onChange={onChange}
        InputProps={{
          style: {
            //   fontFamily: "Gordita",
            marginTop: "10px",
            marginBottom: "20px",
            fontSize: "13px",
            borderRadius: "8px",
            offset: " 1px solid #f2f2f2",
            // Replace with your desired font family
          },
        }}
      />
    </>
  );
}

export default CustomInput