import { Box, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import React from 'react'
import logo from '../assets/img/logo/slogo.svg'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { NavData } from '../assets/img/logo/Data/NavData'

const SideNav = () => {
    const router = useLocation();
    const navigate = useNavigate();
  return (
    <>
      <Box sx={{ bgcolor: "#fff", height: "100vh" }}>
        <Box sx={{ pt: 4, px: 8 }}>
          <Link to="/">
            <Box sx={{display:'flex',alignItems:'center',  columnGap:2}}>

            <img src={logo} width={20} />
            <Box>
              <Typography sx={{fontWeight:900, fontSize:'16px'}}>Ma</Typography>
              <Typography sx={{fontSize:'12px', fontWeight:500}}>Mamaket  Workplace</Typography>
            </Box>
            </Box>
          </Link>
        </Box>
        <Box sx={{ mt: 10, mx: 3 }}>
          <List>
            {NavData.map((item, index) => (
              <Link to={item.link} key={index}>
                <ListItemButton
                  selected={router.pathname === item.link}
                  sx={{
                    color: "#1F1023",
                    " 	&.Mui-selected ": {
                      color: "#3E2046",
                      bgcolor: "#F5EEF7",
                      borderRadius: 1,
                    },
                  }}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText>
                    <Typography sx={{ fontWeight: 400, fontFamily: "outfit" }}>
                      {item.title}
                    </Typography>
                  </ListItemText>
                </ListItemButton>
              </Link>
            ))}
          </List>
        </Box>
      </Box>
    </>
  );
}

export default SideNav