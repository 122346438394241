import {
  Box,
  TableCell,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
    Grid,
  Select,
  IconButton,
  MenuItem,
  Typography
} from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";

const AllProducts = ({ data }) => {
  const navigate = useNavigate();

  const handleClick = (id) => {
    navigate(`/dashboard/product-details/${id}`);
  };


    const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePageLeft = (event, newPage) => {
    setPage(page - 1);
  };
  const handleChangePageRight = (event, newPage) => {
    setPage(page + 1);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const startRow = page * rowsPerPage + 1;
  const endRow = Math.min(startRow + rowsPerPage - 1, data?.length);
  const rowsText = `${startRow}-${endRow} of ${data?.length}`;
  return (
    <>
      <Box sx={{ mt: 2 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow sx={{ bgcolor: "#f8f8f7" }}>
                <TableCell sx={{ fontWeight: 600 }}>Product ID</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Title</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Category</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Origin</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Price($)</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!data || data.length === 0 ? (
                <></>
              ) : (
                <>
                  {data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((item, index) => (
                    <TableRow hover onClick={() => handleClick(item?._id)}>
                      <TableCell>{item?._id}</TableCell>
                      <TableCell>{item?.name}</TableCell>
                      <TableCell>{item?.categories[0]?.name}</TableCell>
                      <TableCell>{item?.country?.name}</TableCell>
                      <TableCell>{item?.price.toLocaleString()}</TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            bgcolor: item?.status === "APPROVED" ? "#ECF8F0" : "#FFE5E8",
                            color: item?.status === "APPROVED" ? "#3FB763" : "#FF3347",
                            px: 1,
                            py: 0.5,
                            borderRadius: 1,
                            fontWeight: 500,
                            textAlign: "center",
                          }}
                        >
                          {item?.status === "APPROVED" ? "Active" : "Pending"}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>

                  <Grid container sx={{ bgcolor: "#fff", mt: 3, px: 4, py: 1 }}>
        <Grid
          item
          lg={4}
          md={4}
          xs={4}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography
            sx={{
              color: "#424242",
              fontSize: "13px",
              fontFamily: "outfit",
            }}
          >
            {rowsText}
          </Typography>
        </Grid>
        <Grid item lg={8} md={8} xs={8}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: 2,
            }}
          >
            <Typography
              sx={{
                color: "#424242",
                fontSize: "13px",
                fontFamily: "outfit",
                display: { lg: "block", xs: "none" },
              }}
            >
              You are currently on Page
            </Typography>
            <Select
              size="small"
              sx={{
                fontFamily: "outfit",
                color: "#424242",
                fontSize: "13px",
              }}
              value={rowsPerPage}
              onChange={handleChangeRowsPerPage}
              InputProps={{
                style: {
                  fontFamily: "outfit",
                  fontSize: "13px",
                  // borderRadius: "8px",

                  // Replace with your desired font family
                },
              }}
            >
              <MenuItem
                sx={{ fontFamily: "outfit", color: "#424242" }}
                value={5}
              >
                5
              </MenuItem>
              <MenuItem
                sx={{ fontFamily: "outfit", color: "#424242" }}
                value={10}
              >
                10
              </MenuItem>
              <MenuItem
                sx={{ fontFamily: "outfit", color: "#424242" }}
                value={25}
              >
                25
              </MenuItem>
              <MenuItem
                sx={{ fontFamily: "outfit", color: "#424242" }}
                value={50}
              >
                50
              </MenuItem>
            </Select>
            <Box
              sx={{
                borderLeft: "1px solid #424242",
                pl: { md: 4, xs: 2 },
                ml: { md: 3, xs: 2 },
              }}
            >
              <IconButton
                disabled={startRow === 1}
                onClick={handleChangePageLeft}
                sx={{
                  width: "30px",
                  height: "30px",
                  border: "1px solid #424242",
                  "&.Mui-disabled": {
                    border: "1px solid #4242421a",
                  },
                }}
              >
                <ChevronLeftRoundedIcon />
              </IconButton>
              <IconButton
                disabled={endRow === data?.length}
                onClick={handleChangePageRight}
                sx={{
                  ml: 4,
                  width: "30px",
                  height: "30px",
                  border: "1px solid #424242",
                  "&.Mui-disabled": {
                    border: "1px solid #4242421a",
                  },
                }}
              >
                <ChevronRightRoundedIcon />
              </IconButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
      </Box>
    </>
  );
};

export default AllProducts;
