import { useRoutes } from "react-router-dom";
import { Dashboard, DashboardLayout, Dispute, Mamapurse, Notifications, Orders, Products, Services, Team, Users , Banner, Categories} from "../Pages/Dashboard";
import { Login } from "../Pages/Auth";
import UserDetails from "../Components/Users/UserDetails";
import OrderDetails from "../Components/Orders/OrderDetails";
import { Overview } from "../Components/Products.js";
import ServiceOverview from "../Components/Services/ServiceOverview.js"
import ProtectedRoutes from "../Utils/ProtectedRoutes.js";
import Settings from "../Pages/Dashboard/Settings.js";
import ServiceDetails from "../Components/Orders/ServiceDetails.jsx";



export function Routes  () {
let element = useRoutes([
  {
    path: "/login",
    element: <Login />,
  },
  {
    element: (
      <ProtectedRoutes>
        {" "}
        <DashboardLayout />{" "}
      </ProtectedRoutes>
    ),
    children: [
      {
        path: "/",
        element: <Dashboard />,
      },
      {
        path: "/dashboard/users",
        element: <Users />,
      },
      {
        path: "/dashboard/orders",
        element: <Orders />,
      },
      {
        path: "/dashboard/products",
        element: <Products />,
      },
      {
        path: "/dashboard/services",
        element: <Services />,
      },
      {
        path: "/dashboard/mamapurse",
        element: <Mamapurse />,
      },
      {
        path: "/dashboard/categories",
        element: <Categories />,
      },
      {
        path: "/dashboard/banner",
        element: <Banner />,
      },
      {
        path: "/dashboard/notifications",
        element: <Notifications />,
      },
      {
        path: "/dashboard/team",
        element: <Team />,
      },
      {
        path: "/dashboard/dispute",
        element: <Dispute />,
      },
      {
        path: "/dashboard/user-details/:id",
        element: <UserDetails />,
      },
      {
        path: "/dashboard/order-details/:id",
        element: <OrderDetails />,
      },
      {
        path: "/dashboard/order-service-details/:id",
        element: <ServiceDetails />,
      },
      {
        path: "/dashboard/product-details/:id",
        element: <Overview />,
      },
      {
        path: "/dashboard/service-details/:id",
        element: <ServiceOverview />,
      },
      {
        path: "/dashboard/settings",
        element: <Settings />,
      },
    ],
  },
]);
return element
}