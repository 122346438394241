import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { RiFullscreenExitFill } from "react-icons/ri";
import { Link } from "react-router-dom";

const Sellers = ({ data }) => {
  const filteredData = data.filter((item) => item?.hasPaidMembershipFee);
  return (
    <>
      <Box sx={{ mt: 2 }}>
        <TableContainer>
          <Table>
            <TableHead sx={{ bgcolor: "#f8f8f7" }}>
              <TableRow>
                <TableCell sx={{ color: "#101010", fontWeight: 500 }}>
                  User ID{" "}
                </TableCell>
                <TableCell sx={{ color: "#101010", fontWeight: 500 }}>
                  Name{" "}
                </TableCell>
                <TableCell sx={{ color: "#101010", fontWeight: 500 }}>
                  Email Address
                </TableCell>
                <TableCell sx={{ color: "#101010", fontWeight: 500 }}>
                  Location
                </TableCell>
                <TableCell sx={{ color: "#101010", fontWeight: 500 }}>
                  Profile
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "#101010", fontWeight: 500 }}
                >
                  Status
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "#101010", fontWeight: 500 }}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                filteredData?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item._id}</TableCell>
                    <TableCell>{`${item?.firstName} ${item?.lastName} `}</TableCell>
                    <TableCell>{item.email}</TableCell>
                    <TableCell>{item.location}</TableCell>
                    <TableCell>
                      {item?.hasPaidMembershipFee ? "Seller" : "Buyer"}
                    </TableCell>
                    <TableCell align="right">
                      {item.activityStatus ? (
                        <Box
                          sx={{
                            bgcolor: "#ECF8F0",
                            px: 3,
                            py: 1,
                            borderRadius: 1,
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#3FB763",
                              textAlign: "center",
                              fontSize: "10px",
                            }}
                          >
                            Active
                          </Typography>
                        </Box>
                      ) : (
                        <Box></Box>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <Link to={`/dashboard/user-details/${item?._id}`}>
                        <IconButton>
                          <RiFullscreenExitFill style={{ fontSize: "12px" }} />
                        </IconButton>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default Sellers;
