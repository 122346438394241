import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, {useEffect} from "react";
import { MdChevronLeft } from "react-icons/md";
import { IoChevronDown } from "react-icons/io5";
import { Link, useParams } from "react-router-dom";
import img1 from "../../assets/img/1.jpg";
import { GET_SINGLE_SERVICES } from "../../Utils/Queries";
import { useLazyQuery } from "@apollo/client";

const ServiceOverview = () => {
  const {id} = useParams();
      const [getServices, {loading, data, error}] = useLazyQuery(GET_SINGLE_SERVICES, {variables:{
      textToSearch : id
    }});

      useEffect(()=>{
getServices()
  }, [])

  const service = data?.fetchAllServicesWithFilter?.items

console.log(service)
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Link to="/dashboard/services">
          <Button
            sx={{ bgcolor: "#fff", color: "#666" }}
            startIcon={<MdChevronLeft />}
          >
      Service list
          </Button>
        </Link>
        {/* <Button
          endIcon={<IoChevronDown />}
          sx={{ bgcolor: "#fff", color: "#666" }}
        >
          Change Status
        </Button> */}
      </Box>

      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item lg={6}>
          <Box sx={{ p: 2, bgcolor: "#fff" }}>
            <Typography sx={{ fontWeight: 500 }}>Service Details</Typography>
            <Divider />

            <Box
            className= "hide_scrollbar"
              sx={{ mt: 2, display: "flex", justifyContent: "space-between", overflow:'scroll' }}
            >
             {
              service && service[0]?.images?.map((url, index)=>(
                 <Avatar
                src={url}
                variant="square"
                sx={{ width: "120px", height: "120px" }}
              />
              ))
             }
         
            </Box>

            <Box sx={{ mt: 3 }}>
              <Typography sx={{ fontSize: "12px" }}>ID</Typography>
              <Typography sx={{ fontWeight: 600 }}>{id}</Typography>
              <Box sx={{ mt: 2 }}>
                <Typography sx={{ fontSize: "12px" }}>Status</Typography>
                <Typography sx={{ fontWeight: 600 }}> {service && service[0]?.status}</Typography>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Typography sx={{ fontSize: "12px" }}>Product Title</Typography>
                <Typography sx={{ fontWeight: 600 }}>
                  {" "}
              {service && service[0]?.title}
                </Typography>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Typography sx={{ fontSize: "12px" }}>Description</Typography>
                <Typography sx={{ fontWeight: 600 }}>
                  {" "}
                 {service && service[0]?.description}
                </Typography>
              </Box>
              <Box sx={{ mt: 2}}>
                <Typography sx={{ fontSize: "12px" }}>Origin</Typography>
                <Typography sx={{ fontWeight: 600 }}> {service &&  service[0]?.country?.name}</Typography>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Typography sx={{ fontSize: "12px" }}>Price</Typography>
                <Typography sx={{ fontWeight: 600 }}>$  {service &&  service[0]?.price}</Typography>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Typography sx={{ fontSize: "12px" }}>Negotiable</Typography>
                <Typography sx={{ fontWeight: 600 }}>{service && service?.isNegotiable ? "Yes" : "No"}</Typography>
              </Box>
             
             
            </Box>
          </Box>
        </Grid>
        <Grid item lg={6}>
          <Grid container rowSpacing={2}>
            <Grid item lg={12}>
              <Box sx={{ p: 2, bgcolor: "#fff" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography sx={{ fontWeight: 500 }}>
                    Seller Details
                  </Typography>
                           <Link to={`/dashboard/user-details/${service && service[0]?.user?._id}`}>
                  <Typography sx={{ fontWeight: 500, color: "#3FB763" }}>
                    View Profile
                  </Typography>
                  </Link>
                </Box>
                <Divider />
                <Box sx={{ mt: 2 }}>
                  <Typography sx={{ fontSize: "12px" }}>Full Name</Typography>
                  <Typography sx={{ fontWeight: 600 }}>{service && `${service[0]?.user?.firstName} ${service[0]?.user?.lastName}`}</Typography>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Typography sx={{ fontSize: "12px" }}>Join Date</Typography>
                  <Typography sx={{ fontWeight: 600 }}>{service && formatDate(service[0]?.user?.createdAt)}</Typography>
                </Box>
              </Box>
            </Grid>
            {/* <Grid item lg={12}>
              <Box sx={{ p: 2, bgcolor: "#fff" }}>
                <Typography sx={{ fontWeight: 500 }}>Orders</Typography>

                <TableContainer sx={{ mt: 3 }}>
                  <Table>
                    <TableHead>
                      <TableRow sx={{ bgcolor: "#f8f8f7" }}>
                        <TableCell>Order ID</TableCell>
                        <TableCell> Customer</TableCell>
                        <TableCell align="right"> Status</TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </TableContainer>
              </Box>
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ServiceOverview;

const formatDate = (dateString) => {
  const date = new Date(dateString);

  const day = date.getUTCDate();
  const month = date.toLocaleString('en-GB', { month: 'short' });
  const year = date.getUTCFullYear();

  return `${day} ${month} ${year}`;
};

