import {
  Avatar,
  Box,
  Button,
  Grid,
  Skeleton,
  Modal,
  Typography,
  Popper,
  MenuItem,
} from "@mui/material";
import React, { useState } from "react";
import { MdChevronLeft } from "react-icons/md";
import { IoChevronDown } from "react-icons/io5";
import { Link, useParams } from "react-router-dom";
import CustomTabs from "../Common/CustomTabs";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_SINGLE_USER,
  GET_USER_MAMAPURSE,
  GET_USER_PRODUCTS,
  GET_USER_PRODUCT_ORDER,
  GET_USER_SERVICE,
  GET_USER_SERVICE_ORDER,
  GET_USER_STORE,
} from "../../Utils/Queries";
import img1 from "../../assets/img/profile.png";
import { format } from "date-fns";
import UserProductOrder from "./Orders/UserProductOrder";
import UserServiceOrder from "./Orders/UserServiceOrder";
import UserProductStore from "./Store/UserProductStore";
import UserServiceStore from "./Store/UserServiceStore";
import Loader from "../Common/Loader";
import deleteImg from "../../assets/icon/delete.svg";
import { DEACTIVATE_USER } from "../../Utils/Mutation";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",

  boxShadow: 24,
  p: 4,
};

const UserDetails = () => {
  const { id } = useParams();
  const [value, setValue] = React.useState(0);

  const [isLoading, setisLoading] = useState(false)

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [value2, setValue2] = React.useState(0);

  const handleChange2 = (event, newValue) => {
    setValue2(newValue);
  };

  const { loading, data } = useQuery(GET_SINGLE_USER, {
    variables: { _id: id },
  });
  const { data: mamapurse } = useQuery(GET_USER_MAMAPURSE, {
    variables: { userId: id },
  });
  const { data: storeBalance } = useQuery(GET_USER_STORE, {
    variables: { userId: id },
  });
  const { data: productOrder } = useQuery(GET_USER_PRODUCT_ORDER, {
    variables: { userId: id },
  });
  const { data: serviceOrder } = useQuery(GET_USER_SERVICE_ORDER, {
    variables: { userId: id },
  });
  const { data: userProduct } = useQuery(GET_USER_PRODUCTS, {
    variables: { userId: id },
  });
  const { data: userService } = useQuery(GET_USER_SERVICE, {
    variables: { userId: id },
  });

  console.log(data);

  const mamapurse_details =
    mamapurse?.getUserMamapurseBalanceInformationByUserId;

  const store_balance = storeBalance?.getUserStoreBalanceInformationByUserId;

  // console.log(data?.listAllUsers?.items[0], "Single");

  const userData = data?.listAllUsers?.items[0];

  const TabData = [
    {
      label: "Products",
      component: (
        <UserProductStore data={userProduct?.fetchProductsByUserId?.items} />
      ),
    },
    {
      label: "Services",
      component: (
        <UserServiceStore
          data={userService?.fetchUserServicesByUserId?.items}
        />
      ),
    },
  ];

  const TabData2 = [
    {
      label: "Products",
      component: (
        <UserProductOrder
          data={productOrder?.fetchProductOrdersByUserId?.items}
        />
      ),
    },
    {
      label: "Services",
      component: (
        <UserServiceOrder
          data={serviceOrder?.fetchServiceOrdersByUserid?.items}
        />
      ),
    },
  ];

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const idPopper = open ? "simple-popper" : undefined;

  const [deactivateUser ] = useMutation(DEACTIVATE_USER)

  const handleDeactivateUser = async () =>{
    setisLoading(true)
    await deactivateUser({
      variables:{
        userId:id
      }
    }).then((res)=>{
      console.log(res)
      setisLoading(false)
      setOpenModal(false)
      setAnchorEl(null)
    })
  }
  return (
    <>
      {(isLoading || loading) && <Loader />}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Link to="/dashboard/users">
          <Button
            sx={{ bgcolor: "#fff", color: "#666" }}
            startIcon={<MdChevronLeft />}
          >
            User List
          </Button>
        </Link>
        <Button
          aria-describedby={idPopper}
          onClick={handleClick}
          endIcon={<IoChevronDown />}
          sx={{ bgcolor: "#fff", color: "#666" }}
        >
          Change Status
        </Button>
      </Box>

      <Box sx={{ mt: 3 }}>
        <Grid container spacing={3}>
          <Grid item lg={12} sx={{opacity:userData?.activityStatus === "BLOCKED" ? 0.4 : 1}}>
            <Box sx={{ bgcolor: "#fff", p: 2 }}>
              <Grid container>
                <Grid item lg={5}>
                  <Box
                    sx={{ display: "flex", alignItems: "center", columnGap: 5 }}
                  >
                    {userData ? (
                      <Avatar
                        src={userData?.imageUrl ? userData?.imageUrl : img1}
                        sx={{ width: "100px", height: "100px" }}
                      />
                    ) : (
                      <Skeleton
                        variant="circular"
                        sx={{ width: "100px", height: "100px" }}
                      />
                    )}

                    <Box>
                      <Box>
                        <Typography sx={{ color: "#666", fontSize: "12px" }}>
                          Full Name
                        </Typography>
                        {userData?.firstName ? (
                          <Typography
                            sx={{
                              color: "#333",
                              fontSize: "12px",
                              fontWeight: 500,
                            }}
                          >
                            {`${userData?.firstName} ${userData?.lastName}`}
                          </Typography>
                        ) : (
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        )}
                      </Box>
                      <Box sx={{ mt: 3 }}>
                        <Typography sx={{ color: "#666", fontSize: "12px" }}>
                          Join Date
                        </Typography>
                        {userData ? (
                          <Typography
                            sx={{
                              color: "#333",
                              fontSize: "12px",
                              fontWeight: 500,
                            }}
                          >
                            {format(
                              new Date(userData?.createdAt),
                              "dd MMM yyyy"
                            )}
                          </Typography>
                        ) : (
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item lg={3}>
                  <Box>
                    <Typography sx={{ color: "#666", fontSize: "12px" }}>
                      Email Address
                    </Typography>
                    {userData ? (
                      <Typography
                        sx={{
                          color: "#333",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        {`${userData?.email}`}
                      </Typography>
                    ) : (
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                    )}
                  </Box>
                  <Box sx={{ mt: 3 }}>
                    <Typography sx={{ color: "#666", fontSize: "12px" }}>
                      Phone Number
                    </Typography>
                    {userData ? (
                      <Typography
                        sx={{
                          color: "#333",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        {`${userData?.phone}`}
                      </Typography>
                    ) : (
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                    )}
                  </Box>
                </Grid>
                <Grid item lg={2}>
                  <Box>
                    <Typography sx={{ color: "#666", fontSize: "12px" }}>
                      State
                    </Typography>
                    <Typography
                      sx={{
                        color: "#333",
                        fontSize: "12px",
                        fontWeight: 500,
                      }}
                    >
                      {`${userData?.addresses[0]?.city?.name || "-"}, ${
                        userData?.addresses[0]?.city?.state?.country?.name ||
                        "-"
                      }`}
                    </Typography>
                  </Box>
                  <Box sx={{ mt: 3 }}>
                    <Typography sx={{ color: "#666", fontSize: "12px" }}>
                      Store fees
                    </Typography>
                    <Typography
                      sx={{
                        color: "#333",
                        fontSize: "12px",
                        fontWeight: 500,
                      }}
                    >
                   {
                    userData?.
hasPaidMembershipFee
 ? "Paid" : "Unpaid"
                   }
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={2}>
                  <Box>
                    <Typography sx={{ color: "#666", fontSize: "12px" }}>
                      Status
                    </Typography>
                    <Typography
                      sx={{
                        color: userData?.activityStatus === "BLOCKED" ? "#FF3347" : "#349852",
                        fontSize: "12px",
                        fontWeight: 500,
                      }}
                    >
                 {userData?.
activityStatus}
                    </Typography>
                  </Box>
                  <Box sx={{ mt: 3 }}>
                    <Typography sx={{ color: "#666", fontSize: "12px" }}>
                      Verification Status
                    </Typography>
                    <Typography
                      sx={{
                        color: "#333",
                        fontSize: "12px",
                        fontWeight: 500,
                      }}
                    >
                     {userData?.profileStatus}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item lg={6} sx={{opacity:userData?.activityStatus === "BLOCKED" ? 0.4 : 1}}>
            <Box sx={{ bgcolor: "#fff", p: 2 }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box>
                  <Typography sx={{ fontWeight: 500 }}>
                    Mamapurse Balance
                  </Typography>
                  <Typography variant="h2">
                    ${mamapurse_details?.balance?.toLocaleString()}
                  </Typography>
                </Box>
                {/* <Button sx={{ color: "#4d4d4d" }}>View Transaction</Button> */}
              </Box>
              <Box sx={{ mt: 4, display: "flex" }}>
                <Typography sx={{ fontSize: "12px", fontWeight: 400 }}>
                  Added: ${mamapurse_details?.totalCredited?.toLocaleString()}
           
                </Typography>
                <Typography sx={{ ml: 12, fontSize: "12px", fontWeight: 400 }}>
                  Spent:$ {mamapurse_details?.totalDebited?.toLocaleString()}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={6} sx={{opacity:userData?.activityStatus === "BLOCKED" ? 0.4 : 1}}>
            <Box sx={{ bgcolor: "#fff", p: 2 }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box>
                  <Typography sx={{ fontWeight: 500 }}>
                    Store Balance
                  </Typography>
                  <Typography variant="h2">
                    ${store_balance?.balance?.toLocaleString()}
                  </Typography>
                </Box>
                {/* <Button  sx={{ color: "#4d4d4d" }}>View Transaction</Button> */}
              </Box>
              <Box sx={{ mt: 4, display: "flex" }}>
                <Typography sx={{ fontSize: "12px", fontWeight: 400 }}>
                  Earned:${store_balance?.totalEarned?.toLocaleString()}
                </Typography>
                <Typography sx={{ ml: 12, fontSize: "12px", fontWeight: 400 }}>
                  Spent:${store_balance?.totalWithdrawn?.toLocaleString()}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={6}>
            <Box sx={{ bgcolor: "#fff", p: 2 }}>
              <Typography sx={{ fontWeight: 500 }}>Orders</Typography>
              <Box sx={{ bgcolor: "#fff", p: 2 }}>
                <CustomTabs
                  value={value2}
                  handleChange={handleChange2}
                  data={TabData2}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item lg={6}>
            <Box sx={{ bgcolor: "#fff", p: 2 }}>
              <CustomTabs
                value={value}
                handleChange={handleChange}
                data={TabData}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Popper id={id} open={open} anchorEl={anchorEl}>
        <Box sx={{ border: 1, p: 1, bgcolor: "background.paper" }}>
      
              <MenuItem onClick={handleDeactivateUser}>Activiate</MenuItem>
    
  
             
          <MenuItem onClick={handleOpen} sx={{color:'#FF3347'}}>Deactivate</MenuItem>
    
        </Box>
      </Popper>

      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", columnGap: 3, alignItems: "center" }}>
            <Box>
              <img src={deleteImg} width={50} />
            </Box>
            <Box>
              <Typography
                sx={{ fontSize: "16px", fontWeight: 500, color: "#333" }}
              >
                Are you sure you want to deactivate this user?
              </Typography>
              <Typography sx={{ fontSize: "12px", fontWeight: 400 }}>
                This user will be deleted immediately, you can’t undo this
                action.
              </Typography>
              <Box sx={{ mt: 3 }}>
                  <Button variant="outlined"   onClick={handleClose} color="error" sx={{ mr: 4, px: 4 }} >
             Cancel
                </Button>
                <Button
                onClick={handleDeactivateUser}
                  variant="contained"
                  color="error"
                  sx={{ bgcolor: "#FF3347", px: 4 }}
                
                >
               Deactivate
                </Button>
              
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default UserDetails;
