import React, { useState } from "react";
import { Drawer } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Typography,
  Avatar,
  IconButton,
  Button,
} from "@mui/material";
import { IoMdClose } from "react-icons/io";
import CustomInput from "../../Common/CustomInput";
import ImageUploader from "../../Common/ImageUploader";
import { useMutation } from "@apollo/client";
import { CREATE_BANNER } from "../../../Utils/Mutation";
import Loader from '../../Common/Loader'
import { useSnackbarHook } from "../../../hooks/useSnackbarHook";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 540,
    flexShrink: 0,
    p: 4,
  },
  drawerPaper: {
    width: 540,
    p: 4,
  },
  toolbar: theme.mixins.toolbar,
}));




const MiddleBanner = ({ open, onClose, getAllBanner }) => {
  const classes = useStyles();
  const {handleClick} = useSnackbarHook()


  const [files, setFiles] = useState("")
  const [fileUrl, setFileUrl] = useState("")

  const handleGetFile = (file) =>{
    setFiles(file)
    console.log(files)
  
  }

  const handleGetUrl = (url) =>{
    setFileUrl(url)
    console.log(fileUrl)
  }


  const [link, setLink]= useState("")


  const [createBanner, {loading}] = useMutation(CREATE_BANNER);
const handleCreateBanner = async () => {
    const type = "WEB_BOTTOM"; // Get the type
    const route = link; // Get the route
    const description = ""; // Get the description

  
    const formData = new FormData();
    formData.append('image', files); // Append the image file with the field name 'image'

    try {
        const { data } = await createBanner({
            variables: {
                image: files,
                type,
                route,
                description
            }
        });
        // console.log('Banner created:', data);
        getAllBanner();
        onClose();
           handleClick("Banner added successfully", "success");

    } catch (error) {
        console.error('Error creating banner:', error);
          handleClick(`${error.message}`, "error")
    }
};


    
  return (
    <Drawer
      className={classes.drawer}
      anchor="right"
      open={open}
      onClose={onClose}
      classes={{ paper: classes.drawerPaper }}
    >
 {
  loading && <Loader/>
 }
      <Box sx={{ px: 4, py: 5 }} className={classes.toolbar}>
        <Box align="right">
          <IconButton onClick={onClose}>
            <IoMdClose style={{ fontSize: "20px", color: "#000" }} />
          </IconButton>
        </Box>
        <Typography
          sx={{
            fontFamily: "outfit",
            color: "#0D0D0D",
            fontSize: "14px",
            fontWeight: 600,
            // ml: 5,
            mb: 3,
          }}
        >
          Add Middle Banner
        </Typography>

        <Box sx={{ mt: 6 }}>
          <Box sx={{mb:2}}>
<ImageUploader placeholder="JPG, PNG or GIF, file size no more than 10MB 
 Image size of 1440 X 240" getFile={handleGetFile} url={handleGetUrl}/>
            {
  files && (
    <Avatar src={fileUrl} sx={{borderRadius:1, width:'100%', height:'200px',my:2}}/>
  )
}
          </Box>
          <CustomInput label="Url Link" value={link} onChange={(e)=>setLink(e.target.value)} />

          <Button onClick={handleCreateBanner} disabled={!files || !link} variant="contained" fullWidth sx={{ mt: 4 }}>
            Upload Banner
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};
export default MiddleBanner;
