import React from 'react';
import { TableContainer, Table, TableHead,   TableRow, TableCell,  Typography, TableBody } from '@mui/material';
import EmptyData from '../../Common/EmptyData';
import CopyButton from '../../Common/CopyButton';

const UserServiceOrder = ({data}) => {
  return (
<>
        {
          (!data || data?.length === 0  )  ? (
            <>
<EmptyData/>
            </>
          ):(
          
            <>
                 <TableContainer sx={{ mt: 3 }}>
                <Table>
                  <TableHead>
                    <TableRow sx={{ bgcolor: "#f8f8f7" }}>
                      <TableCell>Order  ID</TableCell>
                      <TableCell>Product</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                 {
                  data?.map((item, index)=>(
                       <TableRow key={index}>
                    <TableCell sx={{display:'flex', alignItems:'center', columnGap:2}}>
                        <Typography sx={{ color: "#4d4d4d", fontWeight: 500 , width:'100px',  whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis'}}>
                    {item?._id}
                           <Typography
                          sx={{ color: "#4d4d4d", fontSize: "12px", mt: 1 , fontWeight:400}}
                        >
                     ${item?.amountPaid.toLocaleString()}
                        </Typography>
                        </Typography>
                        <CopyButton id={item?._id}/>
                 
                      </TableCell>
                      <TableCell>  {item?.service?.name}</TableCell>
                      <TableCell>{item?.status}</TableCell>
                    </TableRow>
                  ))
                 }
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )
        }
</>
  )
}

export default UserServiceOrder