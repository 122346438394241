import {
  Box,
  Button,
  Chip,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React,{useState, useEffect} from 'react'
import CustomSearch from "../../Components/Common/CustomSearch";
import { useLazyQuery } from "@apollo/client";
import { RiPlayListAddFill } from "react-icons/ri";
import { GET_TEAMS } from "../../Utils/Queries";
import Loader from "../../Components/Common/Loader";
import DateFormatter from "../../Components/Common/DateFormetter";
import CreateTeam from "../../Components/Team/CreateTeam";
import CreateRole from "../../Components/Team/CreateRoles";
import EditTeam from "../../Components/Team/EditTeam";

const Team = () => {
  const [data, setData] = useState(null);
         const [drawerOpen, setDrawerOpen] = React.useState(false);
         const [categoryItem, setCategoryItem] = useState(null);
         const handleDrawerOpen = () => {
           setDrawerOpen(true);
         };

         const handleDrawerClose = () => {
           setDrawerOpen(false);
         };

         const [drawerOpen2, setDrawerOpen2] = React.useState(false);
         const handleDrawerOpen2 = () => {
           setDrawerOpen2(true);
         };

         const handleDrawerClose2 = () => {
           setDrawerOpen2(false);
         };

         const [drawerOpen3, setDrawerOpen3] = React.useState(false);
         const handleDrawerOpen3 = (data) => {
           setDrawerOpen3(true);
           setCategoryItem(data);
         };

         const handleDrawerClose3 = () => {
           setDrawerOpen3(false);
         };

                const [getTeam, { loading }] = useLazyQuery(GET_TEAMS);

                       const handleGetTeam = async () => {
                         await getTeam()
                           .then((res) => {
                             setData(res?.data);
                             console.log(res?.data);
                           })
                           .catch((err) => {
                             console.log(err);
                           });
                       };

                       useEffect(() => {
                         handleGetTeam();
                       }, []);

                       const [selectedTeam, setSelectedTeam] = useState(null)
  return (
    <>
      {loading && <Loader />}
      <Box sx={{ bgcolor: "#fff", p: 2 }}>
        <Box sx={{ pt: 2, width: "100%" }}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6}>
              <CustomSearch placeholder={"Search by Title"} />
            </Grid>
            <Grid item lg={3}>
              <Box>
                <Button
                  onClick={handleDrawerOpen}
                  variant="contained"
                  fullWidth
                  endIcon={<RiPlayListAddFill />}
                >
                  Add User
                </Button>
              </Box>
            </Grid>
            <Grid item lg={3}>
              <Box>
                <Button
                  onClick={handleDrawerOpen2}
                  variant="contained"
                  fullWidth
                  endIcon={<RiPlayListAddFill />}
                >
                  Create Roles
                </Button>
              </Box>
            </Grid>
          </Grid>

          <Box sx={{ mt: 3 }}>
            <Table>
              <TableHead sx={{ bgcolor: "#F7F6F6" }}>
                <TableRow sx={{ fontSize: "12px" }}>
                  <TableCell>User Name</TableCell>
                  <TableCell>Email Address</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Date Joined</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!data?.fetchTeamsWithFilter?.items ||
                data?.fetchTeamsWithFilter?.items?.length === 0 ? (
                  <></>
                ) : (
                  <>
                    {data?.fetchTeamsWithFilter?.items.map((item, index) => (
                      <TableRow
                      key={index}
                        sx={{ cursor: "pointer", fontSize: "12px" }}
                        hover
                        onClick={() => {
                          handleDrawerOpen3(item);
                          setSelectedTeam(item)
                        }}
                      >
                        <TableCell>{item?.firstName}</TableCell>
                        <TableCell>{item?.email}</TableCell>
                        <TableCell>{item?.role?.title}</TableCell>
                        <TableCell>
                          <DateFormatter dateString={`${item?.createdAt}`} />
                        </TableCell>
                        <TableCell>
                          <Typography
                            sx={{
                              bgcolor: " #ECF8F0",
                              px: 1.5,
                              textAlign: "center",
                              fontSize: "12px",
                              fontWeight: 400,
                              py: 1,
                              color: "#3FB763",
                              borderRadius: 2,
                              textTransform: "capitalize",
                            }}
                          >
                            {item?.activityStatus}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                )}
              </TableBody>
            </Table>
          </Box>
        </Box>
      </Box>

      <CreateTeam onClose={handleDrawerClose} open={drawerOpen} />
      {/* <CreateRole onClose={handleDrawerClose2} open={drawerOpen2} /> */}
      <EditTeam
        onClose={handleDrawerClose3}
        open={drawerOpen3}
        data={selectedTeam}
      />
    </>
  );
}

export default Team