import { gql } from "@apollo/client";


export const LOGIN_MUTATION = gql`
  mutation LoginUser($email: String!, $password: String!) {
    loginAdmin(
      loginPayload: { email: $email, password: $password }
    ) {
      accessToken
      tokenExpiresIn
      userType
      profile {
        _id
        uuid
        firstName
        lastName
        email
        phone

        activityStatus
        createdAt
        updatedAt
        country {
          name
          alias
        }
      }
    }
  }
`;

export const CREATE_PRODUCT_CATEGORIES = gql`
  mutation createCategory($name:String!) {
    createCategoryWithoutImage(
      payload: { name: $name, isActive: true, isForService: false }
    ) {
      _id
    }
  }
`;

export const DELETE_CATEGORIES = gql`
  mutation deleteCategory($categoryId: String!) {
    deleteCategory(payload: { categoryId: $categoryId }) {
      message
    }
  }
`;

export const EDIT_PROFILE = gql`
mutation updateAdminProfile($firstName:String!, $lastName:String! ) {
    updateAdminProfile(payload: {
        firstName: $firstName,
        lastName: $lastName,
     
    }) {
        _id
        uuid
        firstName
        lastName
        email
        phone
        activityStatus
        createdAt
        updatedAt
        country {
            name
            alias
        }
    }
}
`;

export const CREATE_TEAM = gql`
  mutation createAdminProfile($firstName:String!, $lastName:String!, $email:String!, $role:String!, $password:String!) {
    createAdminProfile(
      payload: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        role: $role
        password: $password
      }
    ) {
      _id
      firstName
      lastName
      email
      role {
        title
      }
    }
  }
`;

export const CREATE_BANNER = gql`
  mutation createBanner($image: Upload!, $type:String!, $route:String!, $description:String!) {
    createBanner(image: $image, payload: {
      type:$type,route:$route, description:$description
    }) {
      _id
      type
      imageUrl
      description
      createdAt
      updatedAt
    }
  }
`;

export const DEACTIVATE_USER = gql`
mutation deactivate($userId:String!) {
    blockOrUnblockUser(payload: { userId: $userId }) {
        _id
        activityStatus
    }
}
`


export const DELETE_BANNER = gql `
mutation delete($bannerId:String!) {
    deleteBanner(payload: { bannerId: $bannerId}) {
        message
    }
}
`

export const CREATE_NOTIFICATION = gql`
mutation create($accountType:String! , $title:String!, $content: String!) {
    createNotificationByAdmin(payload: {
        accountType: $accountType,
        title: $title,
        content: $content
    }) {
        _id
        accountType
        title
        content
        createdAt
        updatedAt
    }
}
`

export const EDIT_SUB_CATEGORY = gql`
mutation editSub($subCategoryId:String!, $name:String!) {
    editSubCategory(payload: {
        subCategoryId: $subCategoryId,
        name: $name,
        # category: "",
        # description: "",
        # isActive: false
    }) {
        _id
        name
    }
}
`

export const DELETE_SUB_CATEGORY  = gql`
mutation deleteSub($subCategoryId:String!){
    deleteSubCategory(payload: { subCategoryId: $subCategoryId}) {
        message
    }
}
`

export const CREATE_SUB_CATEGORY = gql`
mutation createSub($name:String!,  $category:String!, ) {
    createSubCategory(payload: { 
        name: $name, 
        category: $category, 
        description: "", 
        isActive: true 
    }) {
        _id
        name
    }
}
`

export const REFUND_BUYER = gql`
mutation refund($itemId : String!) {
    refundToCustomer(payload: { itemId:$itemId }) {
        message
    }
}
`

export const RELEASE_FUND =gql`
mutation release($itemId : String!) {
    handleAdminReleaseFundsProcess(payload: { itemId: $itemId }) {
        message
    }
}
`

export const  REFUND_CUSTOMER_ORDER = gql`
mutation refund($itemId:String!){
    refundToCustomer(payload: { itemId: $itemId }) {
        message
    }
}
`



