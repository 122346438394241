import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Typography,
  Avatar,
  IconButton,
  Button,
  ListItemButton,
  Skeleton,
  Drawer,
} from "@mui/material";
import { IoMdClose } from "react-icons/io";
import CustomInput from "../../Common/CustomInput";
import { RiEditBoxLine } from "react-icons/ri";
import { RiCloseFill } from "react-icons/ri";
import { useMutation, useLazyQuery, useQuery } from "@apollo/client";
import {
  DELETE_CATEGORIES,
  DELETE_SUB_CATEGORY,
  EDIT_SUB_CATEGORY,
} from "../../../Utils/Mutation";
import { useSnackbarHook } from "../../../hooks/useSnackbarHook";
import deleteIcon from "../../../assets/img/delete.svg";
import Modal from "@mui/material/Modal";
import Loader from "../../Common/Loader";
import { GET_SUB_CATEGORIES } from "../../../Utils/Queries";
import { CloseOutlined } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 540,
    flexShrink: 0,
    p: 4,
  },
  drawerPaper: {
    width: 540,
    p: 4,
  },
  toolbar: theme.mixins.toolbar,
}));
const EditCategory = ({ open, onClose, data, action }) => {
  const [editSub, setEditSub] = useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpen = (item) => {
    setOpenModal(true);
    setEditSub(item);
  };
  const handleClose = () => setOpenModal(false);
  const [openModal2, setOpenModal2] = React.useState(false);
  const handleOpen2 = () => setOpenModal2(true);
  const handleClose2 = () => setOpenModal2(false);
  const [openModal3, setOpenModal3] = React.useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const handleOpen3 = (item) => {
    setOpenModal3(true);
    setEditSub(item);
  };
  const handleClose3 = () => setOpenModal3(false);

  const { handleClick } = useSnackbarHook();

  const [categoryName, setCategoryName] = useState("");
  const classes = useStyles();
  const [deleteCategory, { loading }] = useMutation(DELETE_CATEGORIES);
  const [deleteSubCategory] = useMutation(DELETE_SUB_CATEGORY);

  const handleDeleteCategories = async () => {
            setIsLoading(true)
    handleClose();
    await deleteCategory({
      variables: {
        categoryId: data._id,
      },
    })
      .then((res) => {
             setIsLoading(false)
        onClose();
        action();
        handleClick("Category Deleted Successfully", "success");
      })
      .catch((err) => {
                setIsLoading(false)
        console.log(err);
        handleClick(`${err.message}`, "error");
        onClose();
      });
  };

  const [getSubCategory, { loading: sub_loading, refetch }] = useLazyQuery(
    GET_SUB_CATEGORIES
  );

  const [subList, setSubList] = useState(null);
  const handleGetSubCategory = async (id) => {
    await getSubCategory({
      variables: {
        category: id,
      },
    })
      .then((res) => {
        console.log(res);
        setSubList(res?.data?.getSubCategories);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [subName, setSubName] = useState("");

  useEffect(() => {
    setCategoryName(data?.name);
    handleGetSubCategory(data?._id);
  }, [data]);

  useEffect(() => {
    if(editSub){

      setSubName(editSub?.name);
    }
  }, [editSub]);

  const [editSubCategory] = useMutation(EDIT_SUB_CATEGORY);

  const handleEditSubCategory = async (id) => {
    setIsLoading(true);
    await editSubCategory({
      variables: {
        subCategoryId: editSub?._id,
        name: subName,
      },
    })
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        handleGetSubCategory(data._id);
        handleClose3();
        handleClick(" Sub Category Edited Successfully", "success");
      })
      .catch((err) => {
        setIsLoading(false);
        handleClick(`${err.message}`, "error");
      });
  };

  const handleDeleteSubCategories = async () => {
    setIsLoading(true)
    await deleteSubCategory({
      variables: {
        subCategoryId: editSub?._id,
      },
    })
      .then((res) => {
        setIsLoading(false)
        handleClose();
        handleGetSubCategory(data._id);
        handleClick("Sub Category Deleted Successfully", "success");
      })
      .catch((err) => {
                setIsLoading(false)
        handleClick(`${err.message}`, "error");
      });
  };
  return (
    <>
      {(isLoading || loading) && <Loader />}
      <Drawer
        className={classes.drawer}
        anchor="right"
        open={open}
        onClose={onClose}
        classes={{ paper: classes.drawerPaper }}
      >
        <Box sx={{ px: 4, py: 5 }} className={classes.toolbar}>
          <Box align="right">
            <IconButton onClick={onClose}>
              <IoMdClose style={{ fontSize: "20px", color: "#000" }} />
            </IconButton>
          </Box>
          <Typography
            sx={{
              fontFamily: "outfit",
              color: "#0D0D0D",
              fontSize: "14px",
              fontWeight: 600,
              // ml: 5,
              mb: 3,
            }}
          >
            Category
          </Typography>

          <Box sx={{ mt: 6 }}>
            <CustomInput
              label="Title *"
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
            />
            <Box
              sx={{
                mt: 3,
                bgcolor: "#F7F6F6",
                p: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography>Sub Categories</Typography>
              <Button sx={{ color: "#0AB110" }}>Add Sub Category</Button>
            </Box>

            <Box sx={{ mt: 2 }}>
              {!subList || subList.length === 0 ? (
                <>
                  <ListItemButton>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Skeleton
                        variant="text"
                        width="70%"
                        sx={{ borderRadius: 0 }}
                      />
                      <Box>
                        <IconButton disabled>
                          <RiEditBoxLine />
                        </IconButton>
                        <IconButton disabled>
                          <RiCloseFill style={{ color: "#FF4C5E" }} />
                        </IconButton>
                      </Box>
                    </Box>
                  </ListItemButton>
                  <ListItemButton>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Skeleton
                        variant="text"
                        width="70%"
                        sx={{ borderRadius: 0 }}
                      />
                      <Box>
                        <IconButton disabled>
                          <RiEditBoxLine />
                        </IconButton>
                        <IconButton disabled>
                          <RiCloseFill style={{ color: "#FF4C5E" }} />
                        </IconButton>
                      </Box>
                    </Box>
                  </ListItemButton>
                  <ListItemButton>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Skeleton
                        variant="text"
                        width="70%"
                        sx={{ borderRadius: 0 }}
                      />
                      <Box>
                        <IconButton disabled>
                          <RiEditBoxLine />
                        </IconButton>
                        <IconButton disabled>
                          <RiCloseFill style={{ color: "#FF4C5E" }} />
                        </IconButton>
                      </Box>
                    </Box>
                  </ListItemButton>
                </>
              ) : (
                <>
                  {subList.map((item, index) => (
                    <ListItemButton key={index}>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#4D4D4D",
                            fontSize: "12px",
                            fontWeight: 500,
                          }}
                        >
                          {item?.name}
                        </Typography>
                        <Box>
                          <IconButton onClick={() => handleOpen3(item)}>
                            <RiEditBoxLine />
                          </IconButton>
                          <IconButton onClick={() => handleOpen(item)}>
                            <RiCloseFill style={{ color: "#FF4C5E" }} />
                          </IconButton>
                        </Box>
                      </Box>
                    </ListItemButton>
                  ))}
                </>
              )}
            </Box>
            <Button variant="contained" fullWidth sx={{ mt: 4 }}>
              Save Changes
            </Button>
            <Button
              onClick={handleOpen2}
              color="error"
              variant="contained"
              fullWidth
              sx={{ mt: 4 }}
            >
              Delete-{" "}
              <span style={{ fontWeight: 700 }}> {"  " + data?.name}</span>
            </Button>
          </Box>
        </Box>

        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box sx={{ display: "flex", columnGap: 3, alignItems: "center" }}>
              <img src={deleteIcon} width={200} />
              <Box>
                <Typography sx={{ fontWeight: 600 }}>
                  Are you sure you want to delete this sub category?
                </Typography>
                <Typography sx={{ fontSize: "12px", mt: 1 }}>
                  This sub category will be deleted immediately, you can’t undo
                  this action.
                </Typography>
                <Box
                  sx={{
                    mt: 3,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={handleDeleteSubCategories}
                  >
                    Delete
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>

        <Modal
          open={openModal2}
          onClose={handleClose2}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box sx={{ display: "flex", columnGap: 3, alignItems: "center" }}>
              <img src={deleteIcon} width={200} />
              <Box>
                <Typography sx={{ fontWeight: 600 }}>
                  Are you sure you want to delete this category?
                </Typography>
                <Typography sx={{ fontSize: "12px", mt: 1 }}>
                  This category will be deleted immediately, you can’t undo this
                  action.
                </Typography>
                <Box
                  sx={{
                    mt: 3,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleClose2}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={handleDeleteCategories}
                  >
                    Delete
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={openModal3}
          onClose={handleClose3}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: "16px", fontWeight: 700 }}>
                Edit
              </Typography>
              <IconButton onClick={handleClose3}>
                <CloseOutlined sx={{ fontSize: "20px" }} />
              </IconButton>
            </Box>

            <Box sx={{ mt: 3 }}>
              <Typography sx={{ fontSize: "13px", fontWeight: 400 }}>
                Sub Category Title
              </Typography>
              <CustomInput
                value={subName}
                onChange={(e) => setSubName(e.target.value)}
              />
              <Box sx={{ mt: 2 }}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={handleEditSubCategory}
                  sx={{ borderRadius: "10px" }}
                >
                  Save Changes
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      </Drawer>
    </>
  );
};
export default EditCategory;
