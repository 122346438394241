import React from "react";
import { Drawer } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography, Avatar, IconButton } from "@mui/material";
import { IoMdClose } from "react-icons/io";



const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 540,
    flexShrink: 0,
    p: 4,
  },
  drawerPaper: {
    width: 540,
    p: 4,
  },
  toolbar: theme.mixins.toolbar,
}));
const TransactionDetails = ({ open, onClose, data }) => {
  const classes = useStyles();



  return (
    <Drawer
      className={classes.drawer}
      anchor="right"
      open={open}
      onClose={onClose}
      classes={{ paper: classes.drawerPaper }}
    >
      <Box sx={{ px: 4, py:5 }} className={classes.toolbar}>
        <Box align="right">
          <IconButton onClick={onClose}>
            <IoMdClose style={{ fontSize: "20px", color: "#000" }} />
          </IconButton>
        </Box>
        <Typography
          sx={{
            fontFamily: "outfit",
            color: "#0D0D0D",
            fontSize: "14px",
            fontWeight: 600,
            ml: 5,
            mb: 3,
          }}
        >
          Transaction Details
        </Typography>

        <Box sx={{mt:5}}>
          <Box sx={{display:'flex', justifyContent:'space-between',  alignItems:'center'}}>
            <Typography sx={{fontWeight:500}}>Status</Typography>
            <Typography sx={{fontWeight:500,  color:data?.status  === "SUCCESS" ? "#3FB763" : "#FF3347", textTransform:'capitalize'}}>{data?.status === "SUCCESS" ? "Success" : "Failed"}</Typography>
          </Box>
          <Box sx={{display:'flex', justifyContent:'space-between',  alignItems:'center', mt:4}}>
            <Typography sx={{fontWeight:500}}>Amount</Typography>
            <Typography sx={{fontWeight:500,  textTransform:'capitalize'}}>${data?.amount.toLocaleString()}</Typography>
          </Box>
          <Box sx={{display:'flex', justifyContent:'space-between',  alignItems:'center', mt:4}}>
            <Typography sx={{fontWeight:500}}>Transaction ID</Typography>
            <Typography sx={{fontWeight:500,  textTransform:'capitalize'}}>{data?._id}</Typography>
          </Box>
          <Box sx={{display:'flex', justifyContent:'space-between',  alignItems:'center', mt:4}}>
            <Typography sx={{fontWeight:500}}>Reference ID</Typography>
            <Typography sx={{fontWeight:500,  textTransform:'capitalize'}}>{data?.reference}</Typography>
          </Box>
          <Box sx={{display:'flex', justifyContent:'space-between',  alignItems:'center', mt:4}}>
            <Typography sx={{fontWeight:500}}> Payment Type</Typography>
            <Typography sx={{fontWeight:500,  textTransform:'capitalize'}}>{data?.type}</Typography>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};
export default TransactionDetails;
