import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Typography,
  Avatar,
  IconButton,
  Button,
  Drawer, InputLabel, 
  TextField,
  MenuItem
} from "@mui/material";
import { IoMdClose } from "react-icons/io";
import CustomInput from "../../Common/CustomInput";
import CustomSelect from "../../Common/CustomSelect";
import { useMutation, useQuery } from "@apollo/client";
import { GET_SUB_CATEGORIES } from "../../../Utils/Queries";
import { CREATE_SUB_CATEGORY } from "../../../Utils/Mutation";
import { useSnackbarHook } from "../../../hooks/useSnackbarHook";
import Loader from "../../Common/Loader";

const categories = [
  {
    value:'Men',
    label:"Men"
  }
 ]




const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 540,
    flexShrink: 0,
    p: 4,
  },
  drawerPaper: {
    width: 540,
    p: 4,
  },
  toolbar: theme.mixins.toolbar,
}));
const CreateSub = ({ open, onClose, categories, action }) => {

  const {handleClick} = useSnackbarHook()
  const classes = useStyles();
  const [rotationAngle, setRotationAngle] = React.useState(0);
  const handleRotateClick = () => {
    // Rotate the image by 45 degrees each time the button is clicked
    setRotationAngle((prevAngle) => prevAngle + 90);
  };



const [createSubCategory, {loading}] = useMutation(CREATE_SUB_CATEGORY)

  const [category, setCategory] = useState("")
  const [name, setName] = useState("")

  const handleCreateCategory = async () => {

    await createSubCategory({
      variables: {
        name,
        category: category,
      },
    })
      .then((res) => {
            
        onClose();
        action();
        handleClick("Sub Category Created Successfully", "success");
      })
      .catch((err) => {
              
        console.log(err);
        handleClick(`${err.message}`, "error");
        onClose();
      });
  };
  return (
    <Drawer
      className={classes.drawer}
      anchor="right"
      open={open}
      onClose={onClose}
      classes={{ paper: classes.drawerPaper }}
    >

      {
        loading && (<Loader/>)
      }
      <Box sx={{ px: 4, py: 5 }} className={classes.toolbar}>
        <Box align="right">
          <IconButton onClick={onClose}>
            <IoMdClose style={{ fontSize: "20px", color: "#000" }} />
          </IconButton>
        </Box>
        <Typography
          sx={{
            fontFamily: "outfit",
            color: "#0D0D0D",
            fontSize: "14px",
            fontWeight: 600,
            // ml: 5,
            mb: 3,
          }}
        >
          Create Sub Category
        </Typography>

        <Box sx={{ mt: 6 }}>
          <InputLabel sx={{ color: "#4D4D4D", fontSize: "12px" }}>
            Select Category <span style={{ color: "#FF4C5E" }}>*</span>
          </InputLabel>
          <Box sx={{ mt: 2 }}>
            <TextField
            value={category}
            onChange={(e)=>setCategory(e.target.value)}
                 sx={{ fontSize: "12px" }}
            inputProps={{
              style: {
                fontSize: "12px",
                fontWeight: 500,
              },
            }}
            select
            fullWidth
            size="small"
            label={"Select Category"}
          >
            {categories?.map((option, index) => (
              <MenuItem
                sx={{ color: "#4A414F", fontWeight: 400, fontSize: "13px" }}
                key={index}
                value={option._id}
              >
                {option.name}
              </MenuItem>
            ))}
          </TextField>
          </Box>

          <Box sx={{ mt: 3 }}>
            <CustomInput label="Enter Sub Category Title" value={name} onChange={(e)=>setName(e.target.value)} />
          </Box>

          <Button variant="contained" fullWidth sx={{ mt: 4 }} disabled={!category || !name} onClick={handleCreateCategory}>
            Add Sub Category
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};
export default CreateSub;
