import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FaAngleDown } from "react-icons/fa6";
import { RiFileDownloadLine } from "react-icons/ri";
import { FiSearch } from "react-icons/fi";
import CustomTabs from "../../Components/Common/CustomTabs";
import Loader from '../../Components/Common/Loader.js'
import { AllProducts, Pending, Active, Deactivated } from "../../Components/Products.js";
import { useLazyQuery } from "@apollo/client";
import { GET_PRODUCTS, GET_PRODUCTS_BY_DATERANGE } from "../../Utils/Queries.js";
import CustomSelect from "../../Components/Common/CustomSelect.js";
import jsPDF from 'jspdf';
import 'jspdf-autotable';



const days = [
  {
    value: "1",
    label: "Today",
  },
  {
    value: "7",
    label: "Last 7 days",
  },
  {
    value: "14",
    label: "Last 14 days",
  },
  {
    value: "30",
    label: 'Last 30 days',
  },
  {
    value: "90",
    label: "Last 90 days",
  },
  {
    value: 0,
    label: "All",
  },
];


const Products = () => {
  const [value, setValue] = React.useState(0);

  const [dateRange, setDateRange] = useState(0);



    const [searchParams, setSearchParams] = useState("");

    const [searchedItems, setSearchedItems]  = useState([])

    const [getproducts, {loading, data, error, refetch}] = useLazyQuery(dateRange === 0 ?  GET_PRODUCTS : GET_PRODUCTS_BY_DATERANGE,{variables:{dateRangeKey:dateRange}});


    


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const handleGetAllProduct =  async () =>


  useEffect(()=>{

refetch()


  }, [dateRange])


    useEffect(()=>{
      setSearchedItems(data?.fetchProductsWithFilter?.items)
    }, [data])

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();

    // Filter items based on the search term
    const filteredItems = data?.fetchProductsWithFilter?.items?.filter(
      (item) => item?.name.toLowerCase().includes(searchTerm) || item?._id.toLowerCase().includes(searchTerm)
    );

    setSearchedItems(filteredItems);
    setSearchParams(searchTerm);
  };

const pendingData = searchedItems?.filter((item) => item?.status === "PENDING")
const deactivatedData = searchedItems?.filter((item) => item?.status === "DEACTIVATED")

console.log(data)
  const TabData = [
    {
      label: "All Products",
      component: <AllProducts data={searchedItems} />,
    },
    {
      label: "Pending",
      component: <Pending data={pendingData}  />,
    },
    {
      label: "Active",
      component: <Active />,
    },
    {
      label: "Deactivated",
      component: <Deactivated data={deactivatedData} />,
    },
  ];

  const handleChangeDateRange = (value) =>{
    setDateRange(value)
  } 

  const generatePDF = () => {
  const doc = new jsPDF();

  doc.setFont('helvetica', 'bold');
  doc.setFontSize(16);

  // Add heading
  doc.text(`Mamaket Product List - ${formatDate(new Date())}`, 10, 10);
  // Set custom font and size for the table
  doc.setFont('helvetica');
  doc.setFontSize(12);

  const tableColumn = [
    "Reference", "Name", "Price", "Description", "In Stock", "Rating", "Status" , "Date"
  ];
  const tableRows = [];

  searchedItems.forEach(item => {
    const itemData = [
      item.reference,
      item.name,
      `${item.currency.symbol}${item.price}`,
      item.description,
      item.isInStock ? 'Yes' : 'No',
      item.rating,
      item.status,
     formatDate(item?.createdAt)
    ];
    tableRows.push(itemData);
  });

  doc.autoTable({
    head: [tableColumn],
    body: tableRows,
    startY: 15,
    styles: {
      fontSize: 8,
      font: 'helvetica'
    },
    headStyles: {
 fillColor: [92, 48, 105]
    }
  });


  doc.save(`mamaket_pl(${new Date()}).pdf`);
};

  return (
    <>
    {
      loading  && (<Loader/>)
    }
      <Box sx={{ bgcolor: "#fff", p: 2 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <TextField
           value={searchParams}  onChange={handleSearch}
            placeholder="Search by ID, product, or others..."
            sx={{ width: "70%" }}
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FiSearch style={{ fontSize: "18px" }} />
                </InputAdornment>
              ),
              style: {
                background: " #F8F7F7",

                fontSize: "13px",
                borderRadius: "5px",
                border: 0,
                offset: " 0px solid #f2f2f2",
                // Replace with your desired font family
              },
            }}
          />
          <Box sx={{width:'13%', bgcolor: "#F8F7F7", }}>

<CustomSelect label={"Date Range"}  list={days} handleChangeDateRange={handleChangeDateRange}/>
          </Box>
          <Button
          onClick={generatePDF}
            endIcon={<RiFileDownloadLine style={{ color: "#666" }} />}
            sx={{ color: "#666", bgcolor: "#F8F7F7", width: "10%", py: 1, fontSize:'12px' }}
          >
            Download
          </Button>
        </Box>

        <Box sx={{ mt: 4 }}>
          <CustomTabs
            value={value}
            handleChange={handleChange}
            data={TabData}
            variant="standard"
          />
        </Box>
      </Box>
    </>
  );
};

export default Products;


const formatDate = (dateString) => {
  const date = new Date(dateString);

  const day = date.getUTCDate();
  const month = date.toLocaleString('en-GB', { month: 'short' });
  const year = date.getUTCFullYear();

  return `${day} ${month} ${year}`;
};