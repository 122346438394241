import { Box, Typography } from '@mui/material'
import React from 'react'
import emptyIcon from '../../assets/icon/empty.svg'

const EmptyData = ({label, height}) => {
  return (
<>
<Box sx={{height:height, width:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center',}}>
<Box sx={{pt:4}}><img src={emptyIcon} width={100}/></Box>
<Typography sx={{fontWeight:400, mt:2}}>{label}</Typography>
</Box>
</>
  )
}

export default EmptyData