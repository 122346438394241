import { Box } from "@mui/material";
import React from "react";
import TopBar from "./TopBar";
import { Outlet } from "react-router-dom";
import SideNav from "./SideNav";

const DashboardLayout = () => {
  return (
    <>
      <Box className="hide_scrollbar" sx={{ display: "flex", height:'100vh' , overflow:'scroll' }}>
        <Box
          sx={{
            width: { lg: "20%", xl: "15%", md: "20%" },
       
          }}
        >
          <SideNav />
        </Box>
        <Box sx={{ width: { lg: "80%", xl: "85%", md: "80%" } }}>
          <Box sx={{ height: "4vh" }}>
            <TopBar />
          </Box>
          <Box className="hide_scrollbar" sx={{ p: 4, pt:7, height:'90vh', overflowY:'scroll' }}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DashboardLayout;
