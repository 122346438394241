import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const favouriteSlice = createSlice({
  name: "favourite",
  initialState,
  reducers: {
    addFav(state, action) {
      const productToAdd = action.payload;
      //   const product = product.quantity
      const existingProduct = state.find(
        (item) => item._id === productToAdd._id
      );

      if (existingProduct) {
        // If the product already exists in the cart, increase its quantity
        existingProduct.quantity += 1;
      } else {
        // If the product is not in the cart, add it with quantity 1
        state.push({ ...productToAdd, quantity: 1 });
      }
    },
    removeFav(state, action) {
      return state.filter((item) => item._id !== action.payload);
    },
  },
});

export const { addFav, removeFav } = favouriteSlice.actions;
export default favouriteSlice.reducer;
