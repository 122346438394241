import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import CustomTabs from '../../Components/Common/CustomTabs';
import Website from '../../Components/Banner/Website';
import Mobile from '../../Components/Banner/Mobile';
import { useLazyQuery } from '@apollo/client';
import { ALL_BANNER } from '../../Utils/Queries';
import Loader from '../../Components/Common/Loader';




const Banner = () => {
      const [value, setValue] = React.useState(0);

      const [getAllBanner, {data, loading, refetch} ] = useLazyQuery(ALL_BANNER)

      useEffect(()=>{
getAllBanner()
      },[])





      const handleChange = (event, newValue) => {
        setValue(newValue);
      };

//       console.log(data?.
// fetchBanners
// )
const isMobile = data?.fetchBanners?.filter (item =>  item?.type === "MOBILE")
const isWeb = data?.fetchBanners?.filter (item =>  item?.type === "WEB_TOP")
const isWebBottom = data?.fetchBanners?.filter (item =>  item?.type === "WEB_BOTTOM")


      const TabData = [
  {
    label: "Website",
    component: <Website data={isWeb} data2={isWebBottom}   getAllBanner={refetch}/>,
  },
  {
    label: "Mobile",
    component: <Mobile data={isMobile} getAllBanner={refetch}/>,
  },
];
  return (
    <>
    {
      loading && <Loader/>
    }
      <Box sx={{ p: 2, bgcolor: "#fff" }}>
        <CustomTabs
          variant="standard"
          value={value}
          handleChange={handleChange}
          data={TabData}
        />
      </Box>
    </>
  );
}

export default Banner