import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Select, Grid, MenuItem } from '@mui/material'
import React from 'react'
import { RiFullscreenExitFill } from "react-icons/ri";
import { Link } from 'react-router-dom';
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";



const AllUsers = ({data}) => {
    const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePageLeft = (event, newPage) => {
    setPage(page - 1);
  };
  const handleChangePageRight = (event, newPage) => {
    setPage(page + 1);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const startRow = page * rowsPerPage + 1;
  const endRow = Math.min(startRow + rowsPerPage - 1, data?.length);
  const rowsText = `${startRow}-${endRow} of ${data?.length}`;
  return (
    <>
      <Box sx={{ mt: 2 }}>
        <TableContainer>
          <Table>
            <TableHead sx={{ bgcolor: "#f8f8f7" }}>
              <TableRow>
                <TableCell sx={{ color: "#101010", fontWeight: 500 }}>
                  User ID{" "}
                </TableCell>
                <TableCell sx={{ color: "#101010", fontWeight: 500 }}>
                  Name{" "}
                </TableCell>
                <TableCell sx={{ color: "#101010", fontWeight: 500 }}>
                  Email Address
                </TableCell>
                <TableCell sx={{ color: "#101010", fontWeight: 500 }}>
                  Location
                </TableCell>
                <TableCell sx={{ color: "#101010", fontWeight: 500 }}>
                  Profile
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "#101010", fontWeight: 500 }}
                >
                  Status
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "#101010", fontWeight: 500 }}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{fontSize:'12px'}}>{item._id}</TableCell>
                    <TableCell sx={{fontSize:'12px'}}>{`${item?.firstName} ${item?.lastName} `}</TableCell>
                    <TableCell sx={{fontSize:'12px'}}>{item.email}</TableCell>
                    <TableCell sx={{fontSize:'12px'}}>{`${item?.addresses[0]?.city?.state?.country?.name || "--"}`}</TableCell>
                    <TableCell  sx={{fontSize:'12px'}}>
                      {item?.hasPaidMembershipFee ? "Seller" : "Buyer"}
                    </TableCell>
                    <TableCell align="right">
                      {item.activityStatus ===  "ACTIVE" ? (
                          <Box
                          sx={{
                            bgcolor: "#ECF8F0",
                            px: 3,
                            py: 1,
                            borderRadius: 1,
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#3FB763",
                              textAlign: "center",
                              fontSize: "10px",
                              fontWeight:500
                            }}
                          >
                    {item?.activityStatus}
                          </Typography>
                        </Box>
                      ) : (
                    
                            <Box
                          sx={{
                            bgcolor: "#FFE5E8",
                            px: 3,
                            py: 1,
                            borderRadius: 1,
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#FF3347",
                              textAlign: "center",
                              fontSize: "10px",
                              fontWeight:500,

                            }}
                          >
                    {item?.activityStatus}
                          </Typography>
                        </Box>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <Link to={`/dashboard/user-details/${item?._id}`}>
                        <IconButton>
                          <RiFullscreenExitFill style={{ fontSize: "12px" }} />
                        </IconButton>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
           <Grid container sx={{ bgcolor: "#fff", mt: 3, px: 4, py: 1 }}>
        <Grid
          item
          lg={4}
          md={4}
          xs={4}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography
            sx={{
              color: "#424242",
              fontSize: "13px",
              fontFamily: "outfit",
            }}
          >
            {rowsText}
          </Typography>
        </Grid>
        <Grid item lg={8} md={8} xs={8}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: 2,
            }}
          >
            <Typography
              sx={{
                color: "#424242",
                fontSize: "13px",
                fontFamily: "outfit",
                display: { lg: "block", xs: "none" },
              }}
            >
              You are currently on Page
            </Typography>
            <Select
              size="small"
              sx={{
                fontFamily: "outfit",
                color: "#424242",
                fontSize: "13px",
              }}
              value={rowsPerPage}
              onChange={handleChangeRowsPerPage}
              InputProps={{
                style: {
                  fontFamily: "outfit",
                  fontSize: "13px",
                  // borderRadius: "8px",

                  // Replace with your desired font family
                },
              }}
            >
              <MenuItem
                sx={{ fontFamily: "outfit", color: "#424242" }}
                value={5}
              >
                5
              </MenuItem>
              <MenuItem
                sx={{ fontFamily: "outfit", color: "#424242" }}
                value={10}
              >
                10
              </MenuItem>
              <MenuItem
                sx={{ fontFamily: "outfit", color: "#424242" }}
                value={25}
              >
                25
              </MenuItem>
              <MenuItem
                sx={{ fontFamily: "outfit", color: "#424242" }}
                value={50}
              >
                50
              </MenuItem>
            </Select>
            <Box
              sx={{
                borderLeft: "1px solid #424242",
                pl: { md: 4, xs: 2 },
                ml: { md: 3, xs: 2 },
              }}
            >
              <IconButton
                disabled={startRow === 1}
                onClick={handleChangePageLeft}
                sx={{
                  width: "30px",
                  height: "30px",
                  border: "1px solid #424242",
                  "&.Mui-disabled": {
                    border: "1px solid #4242421a",
                  },
                }}
              >
                <ChevronLeftRoundedIcon />
              </IconButton>
              <IconButton
                disabled={endRow === data?.length}
                onClick={handleChangePageRight}
                sx={{
                  ml: 4,
                  width: "30px",
                  height: "30px",
                  border: "1px solid #424242",
                  "&.Mui-disabled": {
                    border: "1px solid #4242421a",
                  },
                }}
              >
                <ChevronRightRoundedIcon />
              </IconButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
      </Box>
    </>
  );
}

export default AllUsers