import { Box, Table, Typography } from '@mui/material'
import React from 'react'
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CustomTabs from '../Common/CustomTabs';
import { StyledTableCell, StyledTableRow } from '../Common/StyledCell';
import EmptyData from '../Common/EmptyData';

const Revenue = () => {
  return (
    <>
      <Table>
        <StyledTableRow>
          <StyledTableCell>New York</StyledTableCell>
          <StyledTableCell align="right">$2,500</StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell>New York</StyledTableCell>
          <StyledTableCell align="right">$2,500</StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell>New York</StyledTableCell>
          <StyledTableCell align="right">$2,500</StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell>New York</StyledTableCell>
          <StyledTableCell align="right">$2,500</StyledTableCell>
        </StyledTableRow>
      </Table>
    </>
  );
};
const Users = () => {
  return (
    <>
      <Table>
        <StyledTableRow>
          <StyledTableCell>New York</StyledTableCell>
          <StyledTableCell align="right">$2,500</StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell>New York</StyledTableCell>
          <StyledTableCell align="right">$2,500</StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell>New York</StyledTableCell>
          <StyledTableCell align="right">$2,500</StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell>New York</StyledTableCell>
          <StyledTableCell align="right">$2,500</StyledTableCell>
        </StyledTableRow>
      </Table>
    </>
  );
};
const TabData = [
  {
    label: "Revenue",
    component: <Revenue />,
  },
  {
    label: "Users",
    component:<Users/>
  },
];
const Top5 = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Box sx={{ bgcolor: "#fff", mt: 3, p: 2 }}>
        <Typography variant='
        h5' sx={{ color: "#101010", fontWeight:700, fontSize:'14px'  }}>
          Top 5 State per
        </Typography>
        <Box sx={{mt:4}}>
          {/* <CustomTabs value={value} handleChange={handleChange} data={TabData}/> */}
          <EmptyData label="No Data available currently" height="400px"/>
        </Box>
      </Box>
    </>
  );
}

export default Top5

