import React from "react";
import { Drawer, InputLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Typography,
  Avatar,
  IconButton,
  Button,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { IoMdClose } from "react-icons/io";
import CustomInput from "../Common/CustomInput";
import CustomSelect from "../Common/CustomSelect";
import { format, parseISO } from 'date-fns';

const formatDate = (dateString) => {
  const date = parseISO(dateString);
  return format(date, 'dd-MM-yyyy hh:mma');
};


const categories = [
  {
    value: "Men",
    label: "Men",
  },
];

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 540,
    flexShrink: 0,
    p: 4,
  },
  drawerPaper: {
    width: 540,
    p: 4,
  },
  toolbar: theme.mixins.toolbar,
}));
const EditTeam = ({ open, onClose, data,  }) => {
  const classes = useStyles();
console.log(data)
  return (
    <Drawer
      className={classes.drawer}
      anchor="right"
      open={open}
      onClose={onClose}
      classes={{ paper: classes.drawerPaper }}
    >
      <Box sx={{ px: 4, py: 5 }} className={classes.toolbar}>
        <Box align="right">
          <IconButton onClick={onClose}>
            <IoMdClose style={{ fontSize: "20px", color: "#000" }} />
          </IconButton>
        </Box>
        <Typography
          sx={{
            fontFamily: "outfit",
            color: "#0D0D0D",
            fontSize: "14px",
            fontWeight: 600,
            // ml: 5,
            mb: 3,
          }}
        >
          User Details
        </Typography>

        <Box sx={{ mt: 6 }}>
          <List sx={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                columnGap: 2,
                borderBottom: "0.5px solid #d7d7d7",
                py: 2,
              }}
            >
              <Typography sx={{ fontWeight: 500 }}>Status</Typography>
              <Typography  sx={{ fontWeight: 500, color:data?.activityStatus === "ACTIVE" && "#3FB763" }}>
               {data?.activityStatus}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                columnGap: 2,
                borderBottom: "0.5px solid #d7d7d7",
                py: 2,
                mt: 1,
              }}
            >
              <Typography sx={{ fontWeight: 500 }}>First Name</Typography>
              <Typography color={"success"} sx={{ fontWeight: 500 }}>
             {data?.firstName}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                columnGap: 2,
                borderBottom: "0.5px solid #d7d7d7",
                py: 2,
                mt: 1,
              }}
            >
              <Typography sx={{ fontWeight: 500 }}>Last Name</Typography>
              <Typography color={"success"} sx={{ fontWeight: 500 }}>
              {data?.lastName}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                columnGap: 2,
                borderBottom: "0.5px solid #d7d7d7",
                py: 2,
                mt: 1,
              }}
            >
              <Typography sx={{ fontWeight: 500 }}>Email Address</Typography>
              <Typography color={"success"} sx={{ fontWeight: 500 }}>
           {data?.email}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                columnGap: 2,
                borderBottom: "0.5px solid #d7d7d7",
                py: 2,
                mt: 1,
              }}
            >
              <Typography sx={{ fontWeight: 500 }}>Role</Typography>
              <Typography color={"success"} sx={{ fontWeight: 500 }}>
            {data?.role?.title}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                columnGap: 2,
                borderBottom: "0.5px solid #d7d7d7",
                py: 2,
                mt: 1,
              }}
            >
              <Typography sx={{ fontWeight: 500 }}>Last seen</Typography>
              <Typography color={"success"} sx={{ fontWeight: 500 }}>
                {data && formatDate(data?.updatedAt)}
              </Typography>
            </Box>
          </List>

          <Box sx={{mt:5}}>
            <Button variant="contained" fullWidth sx={{py:2, borderRadius:'50px'}}>Update Team</Button>
            <Button fullWidth color="error" sx={{mt:3}}> Deactivate User</Button>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};
export default EditTeam;
