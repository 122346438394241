import { Box, Typography } from '@mui/material'
import React from 'react'
import CustomTabs from '../Common/CustomTabs';
import Product from './Top10/Product';
import Categories from './Top10/Categories';
import Sellers from './Top10/Sellers';
import Origin from './Top10/Origin';
import { useQuery } from '@apollo/client';
import { GET_TOP_10, GET_TOP_CATEGORIES } from '../../Utils/Queries';


const Top10 = () => {

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const  {data,  loading, error} = useQuery(GET_TOP_10);
    const  {data:categories} = useQuery(GET_TOP_CATEGORIES);
console.log(categories)

    const productData = data?.getTopSalesProductsAndTotalNumberSold
    const TabData = [
  {
    label: "Product",
    component: <Product data={productData} />,
  },
  {
    label: "Categories",
    component: <Categories data={categories?.getTopSellingProductCategories}/>,
  },
  // {
  //   label: "Sellers",
  //   component: <Sellers />,
  // },
  // {
  //   label: "Origin",
  //   component: <Origin />,
  // },
];
  return (
    <>
      <Box sx={{ p: 2, bgcolor: "#fff", pt:3 , height:'96%'}}>
        <Typography variant='h5' sx={{ color: "#101010", fontSize:'14px', fontWeight:700 }}>
          Top 10 List Per
        </Typography>
        <Box sx={{mt:4}}>
          <CustomTabs  value={value} handleChange={handleChange} data={TabData}/>
        </Box>
      </Box>
    </>
  );
}

export default Top10