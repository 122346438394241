import React from 'react'
import { TextField , InputAdornment} from '@mui/material';
import { FiSearch } from "react-icons/fi";

const CustomSearch = ({value, onChange, placeholder, icon}) => {
  return (
    <>
      <TextField
        placeholder={placeholder}
fullWidth
value={value}
onChange={onChange}
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FiSearch style={{ fontSize: "18px" }} />
            </InputAdornment>
          ),
          style: {
            background: " #F8F7F7",

            fontSize: "13px",
            borderRadius: "5px",
            border: 0,
            offset: " 0px solid #f2f2f2",
            // Replace with your desired font family
          },
        }}
      />
    </>
  );
}

export default CustomSearch