import { Box, Button, Checkbox, Typography, TextField, InputAdornment, IconButton, LinearProgress } from "@mui/material";
import React, {useState} from "react";
import CustomInput from "../../Components/Common/CustomInput";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/img/logo/slogo.svg";
import { useDispatch, useSelector } from "react-redux";
import { LOGIN_MUTATION } from "../../Utils/Mutation";
import { useMutation } from "@apollo/client";
import { setUser } from "../../store/userSlice";
import { PiEyeClosedBold } from "react-icons/pi";
import { GiAngryEyes } from "react-icons/gi";

const Login = () => {
  const navigate  = useNavigate()
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = React.useState(false);
      const [loginMutation, { loading, error }] = useMutation(LOGIN_MUTATION);

      const handleLogin = async () =>{
        console.log(email, password)
        try{
    const { data } = await loginMutation({
      variables: { email, password },
    });
      dispatch(setUser(data?.loginAdmin));
      localStorage.setItem("isAuthenticated", "true");
      localStorage.setItem("mamaketUserToken", data?.loginAdmin?.accessToken);
         console.log(data);
         navigate('/')
        }catch (error){
          console.log(error)
        }
      }
  return (
    <>
      <Box sx={{ height: "100vh", display: "grid", placeItems: "center" }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ mb: 3 }}>
           <Box sx={{display:'flex',alignItems:'center',  columnGap:2}}>

            <img src={logo} width={20} />
            <Box>
              <Typography sx={{fontWeight:900, fontSize:'26px'}}>Ma</Typography>
        
            </Box>
            </Box>
            <Typography sx={{ textAlign: "center", fontWeight: 900, mt: 1 }}>
        Mamaket  Workplace
            </Typography>
          </Box>
          <Box
            sx={{
              width: "40%",
              border: "1px solid var(--black-100, #E6E6E6)",
              p: 4,
              boxSizing: "border-box",
              bgcolor: "#fff",
              borderRadius: 0.5,
            }}
          >
            {
              loading && <LinearProgress sx={{mb:2}}/>
            }
            <Typography variant="h3">Welcome </Typography>
            <Typography variant="body1">Please enter your detail</Typography>
            <Box sx={{ mt: 5 }}>
              <CustomInput
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                label="Email"
                placeholder="Email Address"
              />
              <TextField
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter Password"
                type={showPassword ? "text" : "password"}
                size="large"
                fullWidth
                InputProps={{
                  style: {
                    //   fontFamily: "Gordita",
                    marginTop: "10px",
                    marginBottom: "20px",
                    fontSize: "13px",
                    borderRadius: "8px",
                    offset: " 1px solid #f2f2f2",
                    // Replace with your desired font family
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? (
                          <GiAngryEyes style={{ fontSize: "26px", color:'#333' }} />
                        ) : (
                          <PiEyeClosedBold style={{ fontSize: "20px", color:'#333' }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: 2,
                  }}
                >
                  <Checkbox /> <Typography>Remember me</Typography>
                </Box>
                <Link to="">
                  <Typography variant="h5">Forget Password?</Typography>
                </Link>
              </Box>

              <Button
              disabled={loading || !email || !password}
                onClick={handleLogin}
                variant="contained"
                fullWidth
                sx={{ mt: 4, borderRadius: "100px" }}
                size="large"
              >
                Sign in
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Login;
