import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  isAuthenticated: false,
  uuid: null,
  email: null,
  userID: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.isAuthenticated = !!action.payload;
    },
    setUserId: (state, action) => {
      state.uuid = action.payload;
      //   state.isAuthenticated = !!action.payload;
    },
    setUserEmail: (state, action) => {
      state.email = action.payload;
      //   state.isAuthenticated = !!action.payload;
    },
    setUserID: (state, action) => {
      state.userID = action.payload;
      //   state.isAuthenticated = !!action.payload;
    },
    logoutUser: (state) => {
      state.user = null;
      state.uuid = null;
      state.isAuthenticated = false;
      localStorage.setItem("isAuthenticated", "false");
    },
  },
});

export const { setUser, logoutUser, setUserId, setUserEmail, setUserID } =
  userSlice.actions;
export default userSlice.reducer;
