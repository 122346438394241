import { gql } from "@apollo/client";

export const GET_DASHBOARD_VALUE = gql`
  query {
    getAdminDashboardMetricValues {
      totalUser
      totalProduct
      totalProductOrder
      totalServiceOrder
      totalRevenue
    }
  }
`;

export const GET_REVENUE_METRICS = gql`
query {
    getRevenueAnalysisByDateFilter(dateFilter: "MTD") {
        currentValue
        previousValue
        percentage
        currencySymbol
    }
}
`
export const GET_ORDER_METRICS = gql`
query {
    getOrderAnalysisByDateFilter(dateFilter: "7") {
        currentValue
        previousValue
        percentage
        currencySymbol
    }
}
`

export const GET_USER_METRICS = gql `

query {
    getUserAnalysisByDateFilter(dateFilter: "YTD") {
        currentValue
        previousValue
        percentage
        currencySymbol
    }
}`

export const GET_TOP_10 = gql`
query {
  getTopSalesProductsAndTotalNumberSold {
    product {
      name
      images
      categories {
        name
      }
      currency {
        symbol
        shortCode
        alias
      }
    }
    totalSold
  }
}
`

export const GET_TOP_CATEGORIES = gql `
query {
    getTopSellingProductCategories {
        category
        totalSold
    }
}
`

export const GET_USER_LIST = gql`
  query {
    listAllUsers(query: {}, pageOptions: { page: 1, limit: 25 }) {
      items {
        _id
        imageUrl
        uuid
        firstName
        lastName
        email
        phone
        profileStatus
        activityStatus
        hasCompletedSellerVerification
        hasPaidMembershipFee # this is used ti determine if a User is also a Buyer or not
        coordinates
        addresses {
          city {
            name
            state {
              name
              country {
                name
              }
            }
          }
        }
        createdAt
      }
      pagination
    }
  }
`;

export const GET_USER_MAMAPURSE = gql`
query user($userId:String!){
    getUserMamapurseBalanceInformationByUserId(
        payload: { userId: $userId}) 
    {
        totalCredited
        totalDebited
        balance
    }
}
`
export const GET_USER_STORE = gql`
query user($userId:String!){
    getUserStoreBalanceInformationByUserId(
        payload: { userId: $userId}) 
    {
        totalEarned
        totalWithdrawn
        balance
    }
}
`
export const GET_USER_TRANSACTIONS = gql`
query user($userId:String!){
    fetchWalletTransactionsByUserId(
        payload: { userId: $userId, isStoreTransaction: false },
        pageOptions: { page: 1, limit: 25 }
    ) {
        items {
            _id
            reference
            externalReference
            amount
            action
            type
            isStoreTransaction
            createdAt
            currency {
                shortCode
                symbol
            }
        }
        pagination
    }
}
`

export const GET_USER_PRODUCT_ORDER = gql `
query user($userId:String!){
  fetchProductOrdersByUserId(
        payload: { userId: $userId },
        pageOptions: { page: 1, limit: 25 }, 
    ) {
    items {
      _id
      reference
      amountPaid
      quantity
      pricePerUnit
      status
      product {
        name
        images
      }
      buyer {
        _id
        firstName
        lastName
        email
        phone
      }
      seller {
        _id
        firstName
        lastName
        email
        phone
      }
      order {
        _id
        reference
        paymentMethod
      }
    }
    pagination
  }
}
`

export const GET_USER_SERVICE_ORDER = gql`
query user($userId:String!){
  fetchServiceOrdersByUserid(
        payload: { userId: $userId},
        pageOptions: { page: 1, limit: 25 }, 
    ) {
        items {
        _id
        reference
        totalPrice
        offerAmount
        status
        service {
            _id
            reference
            title
            rateType
            images
            price
            status
        }
        buyer {
            _id
            firstName
            lastName
            email
            phone
        }
        seller {
            _id
            firstName
            lastName
            email
            phone
        }
        }
        pagination
  }
}
`

export const GET_USER_PRODUCTS = gql`
query user($userId:String!){
	fetchProductsByUserId(
    payload: { userId:$userId },
    pageOptions: { page: 1, limit: 25 }
  ) {
    items {
      _id
      reference
      name
      alias
      price
      description
      isNegotiable
      isInStock
      rating
      status
      images
      categories {
        _id
        name
      }
      subCategories {
        _id
        name
      }
      merchant {
        _id
        firstName
        lastName
      }
      currency {
        name
        symbol
      }
      country {
        _id
        name
      },
    }
    pagination
  }
}
`



export const GET_USER_SERVICE = gql `
query user($userId:String!) {
  fetchUserServicesByUserId(
    payload: { userId: $userId },
    pageOptions: { page: 1, limit: 25 }, 
  ) 
  {
    items {
      _id
      title
      description
      status
      images
      price
      isNegotiable
      rateType
      user {
        firstName
        lastName
        phone
        email
      },
      currency {
        _id
        name
        symbol
        shortCode
      }
      city {
        _id
        name
      }
      state { 
        _id
        name  
      }
      country {
        name
      }
    }
    pagination
  }
}
`



export const GET_SINGLE_USER = gql`
  query listAllUsers($_id: String!) {
    listAllUsers(query: { _id: $_id }, pageOptions: { page: 1, limit: 25 }) {
      items {
        _id
        imageUrl
        uuid
        firstName
        lastName
        email
        phone
        profileStatus
        activityStatus
        hasCompletedSellerVerification
        hasPaidMembershipFee # this is used ti determine if a User is also a Buyer or not
        coordinates
        addresses {
          city {
            name
            state {
              name
              country {
                name
              }
            }
          }
        }
        createdAt
      }
      pagination
    }
  }
`;

export const GET_CATEGORIES = gql`
  query {
    getCategories(query: { }) {
      _id
      name
      image
      description
      isActive
      createdAt
      updatedAt
    }
  }
`;

export const GET_SUB_CATEGORIES = gql`
query getSub($category:String!){
    getSubCategories(query: { category: $category}) {
        _id
        name
        description
        isActive
        createdAt
        updatedAt
        category {
            _id
            name
            image
        }
    }
}
`;

export const GET_PROFILE = gql`
  query {
    getAdminProfile {
      userType
      profile {
        _id
        uuid
        firstName
        lastName
        email
        phone
        role {
          title
        }
        activityStatus
        createdAt
        updatedAt
        country {
          name
          alias
        }
      }
    }
  }
`;

export const GET_TEAMS = gql`
  query {
    fetchTeamsWithFilter(
      query: {
        textToSearch: "ACTIVE"

      }
      pageOptions: { page: 1, limit: 25 }
    ) {
      items {
        _id
        uuid
        firstName
        lastName
        email
        phone
        imageUrl
        activityStatus
        role {
          title
        }
        createdAt
        updatedAt
        country {
          name
        }
      }
      pagination
    }
  }
`;

export const GET_ROLES = gql`
  query {
    getAdminRoles {
      _id
      title
      description
    }
  }
`;

export const GET_BANNER = gql`
  query {
    fetchBanners(query: {}) {
      _id
      type
      imageUrl
    }
  }
`;

export const GET_PRODUCTS = gql`
query {
	fetchProductsWithFilter(
    query: {
    
    },
    pageOptions: { page: 1, limit: 25 }
  ) {
    items {
      _id
      name
      price
      description
      isInStock
      status
      reference
      createdAt
      categories {
        _id
        name
      }
      subCategories {
        _id
        name
      }
      merchant {
        _id
        firstName
        lastName
      }
      currency {
        name
        symbol
      }
      country {
        _id
        name
      },
    }
    pagination
  }
}
`
export const GET_PRODUCTS_BY_DATERANGE = gql`
query fetchProduct($dateRangeKey:String!){
	fetchProductsWithFilter(
    query: {
     dateRangeKey: $dateRangeKey
    },
    pageOptions: { page: 1, limit: 25 }
  ) {
    items {
      _id
      name
      price
      description
      isInStock
      status
      categories {
        _id
        name
      }
      subCategories {
        _id
        name
      }
      merchant {
        _id
        firstName
        lastName
      }
      currency {
        name
        symbol
      }
      country {
        _id
        name
      },
    }
    pagination
  }
}
`
export const GET_SINGLE_PRODUCTS = gql`
query fetchProduct($textToSearch:String!){
	fetchProductsWithFilter(
    query: {
     textToSearch:$textToSearch,
    },
    pageOptions: { page: 1, limit: 25 }
  ) {
    items {
      _id
      name
      price
      images
      description
      isInStock
      isNegotiable
      status
      quantityInStock
      stockHistory{
      quantity
      initialQuantity
      }
      categories {
        _id
        name
      }
      subCategories {
        _id
        name
      }
      merchant {
        _id
        firstName
        lastName
        createdAt
      }
      currency {
        name
        symbol
      }
      country {
        _id
        name
      },
    }
    pagination
  }
}
`

export const GET_SERVICES = gql`
query {
  fetchAllServicesWithFilter(
    query: {
      textToSearch:"APPROVED",
    
    }, 
    pageOptions: { page: 1, limit: 25 }
  ) 
  {
    items {
      _id
      title
      description
      status
      images
      price
      isNegotiable
      rateType
      createdAt
      user {
        firstName
        lastName
        phone
        email
      },
      currency {
        _id
        name
        symbol
        shortCode
      }
      city {
        _id
        name
      }
      state { 
        _id
        name  
      }
      country {
        name
      }
    }
    pagination
  }
}
`
export const GET_SINGLE_SERVICES = gql`
query fetchProduct($textToSearch:String!){
  fetchAllServicesWithFilter(
    query: {
      textToSearch:$textToSearch,
    
    }, 
    pageOptions: { page: 1, limit: 25 }
  ) 
  {
    items {
      _id
      title
      description
      status
      images
      price
      isNegotiable
      rateType
      createdAt
      user {
        firstName
        lastName
        phone
        email
        _id
        createdAt
      },
      currency {
        _id
        name
        symbol
        shortCode
      }
      city {
        _id
        name
      }
      state { 
        _id
        name  
      }
      country {
        name
      }
    }
    pagination
  }
}
`
export const GET_SERVICES_DATE = gql`
query fetch($dateRangeKey:String!){
  fetchAllServicesWithFilter(
    query: {
    dateRangeKey:$dateRangeKey,
      textToSearch:"APPROVED",
    
    }, 
    pageOptions: { page: 1, limit: 25 }
  ) 
  {
    items {
      _id
      title
      description
      status
      images
      price
      isNegotiable
      rateType
      createdAt
      user {
        firstName
        lastName
        phone
        email
      },
      currency {
        _id
        name
        symbol
        shortCode
      }
      city {
        _id
        name
      }
      state { 
        _id
        name  
      }
      country {
        name
      }
    }
    pagination
  }
}
`

export const GET_PRODUCT_ORDERS = gql`
query  {
  fetchOrdersByAdmin(
    pageOptions: { page: 1, limit: 25 }, 
    query: {
        
    #   customDateFilter: {
    #     fromDate: "2023-07-01",
    #     toDate: "2023-08-31"
    #   }
    }) {
    items {
      _id
      reference
      amountPaid
      quantity
      pricePerUnit
      status
      product {
        name
        images
      }
      buyer {
        _id
        firstName
        lastName
        email
        phone
      }
      createdAt
      seller {
        _id
        firstName
        lastName
        email
        phone
      }
    }
    pagination
  }
}
`

export const GET_SERVICE_ORDER  = gql`
query {
  fetchServiceOrdersByAdmin(
    pageOptions: { page: 1, limit: 25 }, 
    query: {
        # textToSearch: "65c87cc4054107e37a69f6e3"
        # dateRangeKey: "60",
        customDateFilter: {
            fromDate: "2023-07-01",
            toDate: "2024-08-31"
        }
    }) {
    items {
      _id
      reference
      totalPrice
      offerAmount
      status
      createdAt
      service {
        _id
        reference
        title
        rateType
        images
        price
        status
      }
      buyer {
        _id
        firstName
        lastName
        email
        phone
      }
      seller {
        _id
        firstName
        lastName
        email
        phone
      }
    }
    pagination
  }
}
`

export const GET_SINGLE_SERVICE_ORDER  = gql`
query  fetchOrder($textToSearch:String!){
  fetchServiceOrdersByAdmin(
    pageOptions: { page: 1, limit: 25 }, 
    query: {
         textToSearch: $textToSearch
        # dateRangeKey: "60",
        customDateFilter: {
            fromDate: "2023-07-01",
            toDate: "2024-08-31"
        }
    }) {
    items {
      _id
      reference
      totalPrice
      offerAmount
      status
        createdAt
      service {
        _id
        reference
        title
        rateType
        images
        price
        status
      }
      buyer {
        _id
        firstName
        lastName
        email
        phone
      
      }
      seller {
        _id
        firstName
        lastName
        email
        phone
      }
    }
    pagination
  }
}
`
export const GET_SINGLE_PRODUCT_ORDERS = gql`
query fetchOrdersByAdmin( $_id:String!) {
  fetchOrdersByAdmin(
    pageOptions: { page: 1, limit: 25 }, 
    query: {
        _id: $_id
    }) {
    items {
      _id
      reference
      amountPaid
      quantity
      pricePerUnit
      status
      product {
        name
        images
      }
      buyer {
        _id
        firstName
        lastName
        email
        phone
      }
      createdAt
      seller {
        _id
        firstName
        lastName
        email
        phone
      }
    }
    pagination
  }
}
`

export const GET_MAMAPURSES_TRANSACTIONS =  gql  `
query {
  fetchWalletTransactionsWithFilter(
    query: {
    #   textToSearch: "65a67e7b4125992fa0a5e158",
    #   dateRangeKey: "60"
    },
    pageOptions: { page: 1, limit: 25 }
  ) {
    items {
      _id
      reference
      externalReference
      amount
      gateway
      description
      action
      type
      status
      paidAt
      createdAt
      updatedAt
      user {
        _id
        firstName
        lastName
        phone
        email
      }
      currency {
        _id
        name
        symbol
        alias
      }
    }
    pagination
  }
}
`

export const MAMAPURSE_BALANCE = gql`
query {
    getAggregateWalletBalances {
        totalMamapurseBalance
        totalStoreBalance
        totalBalance
    }
}
`

export const  ALL_BANNER = gql `
query {
    fetchBanners(query: { }) {
        _id
        type
        imageUrl
        route
    }
}
`

export const GET_ACCOUNT_TYPE = gql `
query {
    getUserTypes {
        key
        value
    }
}
`

export const GET_NOTIFICATIONS = gql`
query {
    fetchAllAdminNotifications(pageOptions: { page: 1, limit: 25 }) {
       items {
         _id
         accountType
         title
         content
         createdAt
         updatedAt
         isSent
       } 
       pagination
    }
}
`

export const GET_DISPUTED = gql`
query {
    fetchDisputedOrders(
        filter: { filterBy: "All" }
        pageOptions: { page: 1, limit: 25 }
    ) {
        items {
            _id
            status
            dispute {
                reason
                reference
                isRead
                isResolved
                description
                files
                createdAt
                updatedAt
            }
            seller {
                firstName
                lastName
                    phone
        profileStatus
        email
        createdAt
             _id
                
            }
            buyer {
                firstName
                lastName
                  phone
        profileStatus
        email
        createdAt
        _id
            }
            product {
                _id
                name
                images
          price
            }
        }
        pagination
    }
}
`


