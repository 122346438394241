import React, { useState } from "react";
import { Drawer } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography, Avatar, IconButton, Button } from "@mui/material";
import { IoMdClose } from "react-icons/io";
import CustomInput from "../../Common/CustomInput";
import { useMutation } from "@apollo/client";
import { CREATE_PRODUCT_CATEGORIES } from "../../../Utils/Mutation";
import Loader from "../../Common/Loader";
import {useSnackbarHook} from '../../../hooks/useSnackbarHook'

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 540,
    flexShrink: 0,
    p: 4,
  },
  drawerPaper: {
    width: 540,
    p: 4,
  },
  toolbar: theme.mixins.toolbar,
}));
const CreateCategory = ({ open, onClose , action}) => {
  const classes = useStyles();
const [categoryName, setCategoryName] = useState("")
const {handleClick} = useSnackbarHook()

const [createCategory, {data, loading}] = useMutation(CREATE_PRODUCT_CATEGORIES)

const handleCreateCategory = async () =>{
await createCategory({
  variables:{
    name:categoryName
  }
}).then((res)=>{
  console.log(res)
   handleClick("Category created successfully", "success");
   action()
  onClose()
}).catch((err)=>{
  console.log(err)
  handleClick(`${err.message}`, "error")
})
}



  return (
    <Drawer
      className={classes.drawer}
      anchor="right"
      open={open}
      onClose={onClose}
      classes={{ paper: classes.drawerPaper }}
    >
      {
        loading && (<Loader/>)
      }
      <Box sx={{ px: 4, py: 5 }} className={classes.toolbar}>
        <Box align="right">
          <IconButton onClick={onClose}>
            <IoMdClose style={{ fontSize: "20px", color: "#000" }} />
          </IconButton>
        </Box>
        <Typography
          sx={{
            fontFamily: "outfit",
            color: "#0D0D0D",
            fontSize: "14px",
            fontWeight: 600,
            // ml: 5,
            mb: 3,
          }}
        >
          Create Category
        </Typography>

        <Box sx={{ mt: 6 }}>
          <CustomInput
            label="Enter Category Title *"
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
          />

          <Button
            variant="contained"
            fullWidth
            sx={{ mt: 4 }}
            onClick={handleCreateCategory}
            disabled={!categoryName}
          >
            Create Category
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};
export default CreateCategory;
