import { MenuItem, Select } from '@mui/base'
import { Grid, Typography, IconButton, Box } from '@mui/material'

import React, { useEffect, useState } from 'react'
import { FaAngleDown } from "react-icons/fa6";
import { ChartContainer } from "@mui/x-charts";
import { LinePlot, MarkPlot } from "@mui/x-charts/LineChart";
import { MdOutlineKeyboardDoubleArrowUp } from "react-icons/md";
import { useQuery } from '@apollo/client';
import { GET_USER_METRICS } from '../../Utils/Queries';



const Analysis = ({revenue_data, order_data}) => {

  const {data:user, loading} = useQuery(GET_USER_METRICS)



  const userMetrics = user?.getUserAnalysisByDateFilter || []

  const pData = [userMetrics?.previousValue, userMetrics?.currentValue,];
const xLabels = [
  "Page A",
  "Page B",

];

const [diff, setDiff] = useState(0)

useEffect(()=>{
if(userMetrics){
 let  value =  userMetrics?.currentValue - userMetrics?.previousValue

 setDiff(value)
}
},[userMetrics])

  return (
    <>
      <Box sx={{ p: 3, bgcolor: "#fff" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5" sx={{fontWeight:700,  fontSize:'14px'}}>Analysis</Typography>
          <Box
            sx={{
              width: "10%",
              bgcolor: "#F8F7F7",
              p: 1,
              display: "flex",
              columnGap: 1,
              alignItems: "center",
              borderRadius: 2,
              px: 2,
            }}
          >
            <Typography>Today </Typography>
            <FaAngleDown />
          </Box>
        </Box>
        <Grid container sx={{ mt: 3 }} spacing={2}>
          <Grid item md={6}>
            <Box sx={{ border: "1px solid #F2F2F2", borderRadius: 2, p: 2 }}>
              <Typography sx={{ color: "#101010", fontWeight: 400 }}>
                Revenue
              </Typography>
              <Typography variant="h2" sx={{ color: "#101010", my: 2, overflow:'hidden', textOverflow:'ellipsis' }}>
                ${revenue_data?.currentValue?.toLocaleString()}
              </Typography>
              <Typography sx={{ color: "#666", fontWeight: 400 }}>
                <span style={{ color:revenue_data?.percentage >= 1 ? "#3FB763" : "#FF3347", fontWeight: 700 }}>
            {revenue_data?.percentage}% {" "}

                </span>
                from last week
              </Typography>
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box sx={{ border: "1px solid #F2F2F2", borderRadius: 2, p: 2 }}>
              <Typography sx={{ color: "#101010", fontWeight: 400 }}>
                Orders
              </Typography>
              <Typography variant="h2" sx={{ color: "#101010", my: 2 }}>
             {order_data?.currentValue
}
              </Typography>
              <Typography sx={{ color: "#666", fontWeight: 400 }}>
                <span style={{ color: "#FF3347", fontWeight: 700 }}>
                  {" "}
                  - 0,5%{" "}
                </span>
                from last week
              </Typography>
            </Box>
          </Grid>
          <Grid item md={12}>
            <Box
              sx={{
                border: "1px solid #F2F2F2",
                borderRadius: 2,
                p: 2,
                height: "300px",
              }}
            >
              <Typography sx={{ color: "#101010", fontWeight: 600 }}>
                New Customers
              </Typography>
              <Box sx={{overflowX:'scroll'}}>
                <ChartContainer
                  width={470}
                  height={220}
                  series={[{ type: "line", data: pData }]}
                  xAxis={[{ scaleType: "point", data: xLabels }]}
                  sx={{
                    ".MuiLineElement-root": {
                      stroke: "#3FB763",
                      strokeWidth: 2,
                    },
                    ".MuiMarkElement-root": {
                      stroke: "#8884d8",
                      scale: "0",
                      fill: "#fff",
                      strokeWidth: 0,
                    },
                  }}
                  disableAxisListener
                >
                  <LinePlot />
                  <MarkPlot />
                </ChartContainer>

                <Box sx={{display:'flex', columnGap:3, justifyContent:'right'}}>
                  <Box
                    sx={{
                      height: "16px",
                      width: "16px",
                      borderRadius: 1,
                      p: 1,
                      bgcolor: "#3FB7631a",
                    }}
                  >
                    <MdOutlineKeyboardDoubleArrowUp
                      style={{ color: "#3FB763" }}
                    />
                  </Box>
                  <Box>
                    <Typography variant="h3" sx={{color:diff >= 0 ? "#333" : "#FF3347"}}>
                      {diff}{" "}
                      <span
                        style={{
                          fontWeight: 300,
                          fontSize: "12px",
                          color: "#3FB763",
                        }}
                      >
                        +10%
                      </span>
                    </Typography>
                    <Typography sx={{color:'#666', fontWeight:400, fontSize:'10px'}}>Compared from Last Week</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Analysis