import React from "react";

const DateFormatter = ({ dateString }) => {
  // Create a new Date object from the original date string
  const parsedDate = new Date(dateString);

  // Format the date to "MM-DD-YYYY"
  const formattedDate = parsedDate.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });

  return <span>{formattedDate}</span>;
};

export default DateFormatter;
