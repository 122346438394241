import React, { useEffect, useState } from "react";
import AppWidget from "../../Components/Mamapurse/AppWidget";
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { FaAngleDown } from "react-icons/fa6";
import { RiFileDownloadLine } from "react-icons/ri";
import { FiSearch } from "react-icons/fi";
import CustomTabs from "../../Components/Common/CustomTabs";
import All from "../../Components/Mamapurse/All";
import Credit from "../../Components/Mamapurse/Credit";
import Debit from "../../Components/Mamapurse/Debit";
import Failed from "../../Components/Mamapurse/Failed";
import TransactionDetails from "../../Components/Mamapurse/Drawer/TransactionDetails";
import { useQuery } from "@apollo/client";
import {
  GET_MAMAPURSES_TRANSACTIONS,
  MAMAPURSE_BALANCE,
} from "../../Utils/Queries";
import Loader from "../../Components/Common/Loader";

const Mamapurse = () => {
  const [value, setValue] = React.useState(0);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [searchParams, setSearchParams] = useState("");

  const { data, loading, error } = useQuery(MAMAPURSE_BALANCE);

  const [filteredData, setFilteredData] = useState([]);

  console.log(data?.getAggregateWalletBalances, "Mama");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const { data: transactions, loading: loadingTransactions } = useQuery(
    GET_MAMAPURSES_TRANSACTIONS
  );
  console.log(transactions);

  useEffect(() => {
    setFilteredData(transactions?.fetchWalletTransactionsWithFilter?.items);
  }, [transactions]);

  const transaction_details = filteredData;
  const credit_data = filteredData?.filter((item) => item?.action === "CREDIT");
  const debit_data = filteredData?.filter((item) => item?.action === "DEBIT");
  const failed_data = filteredData?.filter(
    (item) => item?.status !== "SUCCESS"
  );
  const TabData = [
    {
      label: "All",
      component: <All data={filteredData} />,
    },
    {
      label: "Credit",
      component: <Credit data={credit_data} />,
    },
    {
      label: "Debit",
      component: <Debit data={debit_data} />,
    },
    {
      label: "Failed",
      component: <Failed data={failed_data} />,
    },
  ];

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();

    // Filter items based on the search term
    const filteredItems = transactions?.fetchWalletTransactionsWithFilter?.items?.filter(
      (item) => item?.user?.firstName.toLowerCase().includes(searchTerm)
    );

    setFilteredData(filteredItems);
    setSearchParams(searchTerm);
  };

  return (
    <>
    {
      loading && <Loader/>
    }
      <AppWidget loading={loading} data={data} />
      <Box sx={{ bgcolor: "#fff", p: 2, mt: 3 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <TextField
            placeholder="Search by ID, product, or others..."
            sx={{ width: "70%" }}
            value={searchParams}
            onChange={handleSearch}
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FiSearch style={{ fontSize: "18px" }} />
                </InputAdornment>
              ),
              style: {
                background: " #F8F7F7",
                fontWeight: 400,
                fontSize: "13px",
                borderRadius: "5px",
                border: 0,
                offset: " 0px solid #f2f2f2",
                // Replace with your desired font family
              },
            }}
          />
          <Box
            sx={{
              width: "15%",
              bgcolor: "#F8F7F7",
              fontSize: "12px",
              py: 1,
              display: "flex",
              columnGap: 1,
              alignItems: "center",
              borderRadius: 1,
              justifyContent: "center",
              px: 2,
            }}
          >
            <Typography sx={{ color: "#666", fontWeight: 400 }}>
              Today{" "}
            </Typography>
            <FaAngleDown style={{ color: "#666" }} />
          </Box>
          <Button
            endIcon={<RiFileDownloadLine style={{ color: "#666" }} />}
            sx={{
              color: "#666",
              bgcolor: "#F8F7F7",
              width: "10%",
              py: 1,
              fontSize: "12px",
            }}
          >
            Download
          </Button>
        </Box>

        <Box sx={{ mt: 4 }}>
          <CustomTabs
            value={value}
            handleChange={handleChange}
            data={TabData}
            variant="standard"
          />
        </Box>
      </Box>

      <TransactionDetails open={drawerOpen} onClose={handleDrawerClose} />
    </>
  );
};

export default Mamapurse;
