import logo from './logo.svg';
import './App.css';
import { Routes } from './Routes';
import { ThemeProvider } from '@emotion/react';
import { theme } from './Theme';
import { SnackbarProvider } from "notistack";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { ApolloProvider } from "@apollo/client";
import { store, persistor } from "./store/store";


function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
              <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
          <Routes />
          </PersistGate>
          </Provider>
        </SnackbarProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
