import { Table, Box, Avatar, Typography } from '@mui/material'
import React from 'react'
import { StyledTableCell, StyledTableRow } from '../../Common/StyledCell'
import EmptyData from '../../Common/EmptyData';

const Categories = ({data}) => {
  return (
<>
      <Table>
      {
        (!data || data?.length === 0) ? (
          <>
    
          <EmptyData label="No Data available currently" height="400px"/>

          </>
        ):(
          <>
          {
            data?.map((item, index)=>(
                <StyledTableRow key={index}>
          <StyledTableCell>
            <Box sx={{ display: "flex", alignItems: "center", columnGap: 2, width:'70%' }}>
           
              <Box>
                <Typography sx={{fontFamily:'outfit', fontWeight:500, width:'80%', overflow:'hidden', textOverflow:'ellipsis', whiteSpace:'nowrap' }}>Curology {item?.category}</Typography>
           
              </Box>
            </Box>
          </StyledTableCell>
          <StyledTableCell align="right">{item?.totalSold} sold</StyledTableCell>
        </StyledTableRow>
            ))
          }
          </>
        )
      }
   
      </Table>
</>
  )
}

export default Categories