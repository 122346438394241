import { Box, Button, Grid, Skeleton, Typography } from "@mui/material";
import React from "react";
import { CiMoneyBill } from "react-icons/ci";
import { LiaWalletSolid } from "react-icons/lia";
import { FaBoxOpen } from "react-icons/fa";
import { FaMoneyBillTransfer } from "react-icons/fa6";

const AppWidget = ({ data, loading }) => {
  //   const {totalBalance,
  // totalMamapurseBalance,
  // totalStoreBalance

  // } = data?.getAggregateWalletBalances
  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={4}>
          <Box sx={{ bgcolor: "#fff", p: 2 }}>
            <Box sx={{ display: "flex", columnGap: 3, alignItems: "center" }}>
              <CiMoneyBill style={{ fontWeight: 500, fontSize: "25px" }} />
              <Typography sx={{ fontWeight: 700, color: "#4d4d4d" }}>
                Total Balance
              </Typography>
            </Box>
            <Typography variant="h2" sx={{ mt: 3, fontSize: "26px" }}>
              $
              {loading ? (
                <Skeleton />
              ) : (
                data?.getAggregateWalletBalances?.totalBalance.toLocaleString()
              )}
            </Typography>
          </Box>
        </Grid>
        <Grid item lg={4}>
          <Box sx={{ bgcolor: "#fff", p: 2 }}>
            <Box sx={{ display: "flex", columnGap: 3, alignItems: "center" }}>
              <LiaWalletSolid style={{ fontWeight: 500, fontSize: "25px" }} />
              <Typography sx={{ fontWeight: 700, color: "#4d4d4d" }}>
                Mamapurse Balance
              </Typography>
            </Box>
            <Typography variant="h2" sx={{ mt: 3, fontSize: "26px" }}>
              $
              {loading ? (
                <Skeleton />
              ) : (
                data?.getAggregateWalletBalances?.totalMamapurseBalance.toLocaleString()
              )}
            </Typography>
          </Box>
        </Grid>
        <Grid item lg={4}>
          <Box sx={{ bgcolor: "#fff", p: 2 }}>
            <Box sx={{ display: "flex", columnGap: 3, alignItems: "center" }}>
              <FaBoxOpen style={{ fontWeight: 500, fontSize: "25px" }} />
              <Typography sx={{ fontWeight: 700, color: "#4d4d4d" }}>
                Store Balance
              </Typography>
            </Box>
            <Typography variant="h2" sx={{ mt: 3, fontSize: "26px" }}>
              $
              {loading ? (
                <Skeleton />
              ) : (
                data?.getAggregateWalletBalances?.totalStoreBalance.toLocaleString()
              )}
            </Typography>
          </Box>
        </Grid>
        {/* <Grid item lg={3}>
          <Box sx={{ bgcolor: "#fff", p: 2 }}>
            <Box sx={{ display: "flex", columnGap: 3, alignItems: "center" }}>
              <FaMoneyBillTransfer
                style={{ fontWeight: 500, fontSize: "25px" }}
              />
              <Typography sx={{ fontWeight: 700, color: "#4d4d4d" }}>
                Payment Requests
              </Typography>
            </Box>

            <Box
              sx={{
                mt: 2.3,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h2" sx={{fontSize:'26px'}}>20</Typography>
              <Button sx={{ color: "#FF3347" }}>View All</Button>
            </Box>
          </Box>
        </Grid> */}
      </Grid>
    </>
  );
};

export default AppWidget;
