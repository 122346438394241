import {
  Box,
  Button,
  Typography,
  TextField,
  InputAdornment,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Select,
  IconButton,
  MenuItem
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FaAngleDown } from "react-icons/fa6";
import { RiFileDownloadLine } from "react-icons/ri";
import { FiSearch } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import CustomSearch from "../Common/CustomSearch";
import CustomSelect from "../Common/CustomSelect";
import { useLazyQuery } from "@apollo/client";
import { GET_PRODUCT_ORDERS } from "../../Utils/Queries";
import DateFormatter from "../Common/DateFormetter";
import Loader from "../Common/Loader";
import CopyButton from "../Common/CopyButton";
import { BiExpandAlt } from "react-icons/bi";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const status = [
  {
    value: "",
    label: "All",
  },

  {
    value: "Shipped",
    label: "Shipped",
  },
  {
    value: "Review & Release Fund",
    label: "Review & Release Fund",
  },
    {
    value: "Canceled",
    label: "Canceled",
  },
  {
    value: "Refunded",
    label: "Refunded",
  },
];

const Products = () => {
  const navigate = useNavigate();
  const handleClickRow = (id, data) => {
    navigate(`/dashboard/order-details/${id}`);
    setProductDetails(data)
  };

  const [productDetails, setProductDetails] = useState(null)

  const [getOrders, { data, loading }] = useLazyQuery(GET_PRODUCT_ORDERS);

  const handleGetOrders = async () => {
    await getOrders();
    console.log(data?.fetchOrdersByAdmin);
  };

  useEffect(() => {
    handleGetOrders();
  }, []);

    const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePageLeft = (event, newPage) => {
    setPage(page - 1);
  };
  const handleChangePageRight = (event, newPage) => {
    setPage(page + 1);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const startRow = page * rowsPerPage + 1;



    const generatePDF = () => {
  const doc = new jsPDF();

  doc.setFont('helvetica', 'bold');
  doc.setFontSize(16);

  // Add heading
  doc.text(`Mamaket Product Order List - ${formatDate(new Date())}`, 10, 10);
  // Set custom font and size for the table
  doc.setFont('helvetica');
  doc.setFontSize(12);

  const tableColumn = [
    "Order Id", "Product", "Date", "Customer", "Price", "Seller", "Status" , 
  ];
  const tableRows = [];

  data?.fetchOrdersByAdmin?.items.forEach(item => {
    const itemData = [
      item._id,
      item?.product?.name,
        formatDate(item?.createdAt),
        `${item?.buyer?.firstName} ${item?.buyer?.lastName}`,
      `$${item.amountPaid}`,
  `${item?.seller?.firstName} ${item?.seller?.lastName}`,
      item.status ,
 
    
   
    ];
    tableRows.push(itemData);
  });

  doc.autoTable({
    head: [tableColumn],
    body: tableRows,
    startY: 15,
    styles: {
      fontSize: 8,
      font: 'helvetica'
    },
    headStyles: {
 fillColor: [92, 48, 105]
    }
  });


  doc.save(`mamaket_pol(${new Date()}).pdf`);
};

const [statusValue, setStatusValue] = useState("")

const handleStatus = (value) => {
setStatusValue(value)
}

    const [searchParams, setSearchParams] = useState("");

    const [searchedItems, setSearchedItems]  = useState([])

      useEffect(()=>{
      setSearchedItems(data?.fetchOrdersByAdmin?.items)
    }, [data])

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();

    // Filter items based on the search term
    const filteredItems = data?.fetchOrdersByAdmin?.items?.filter(
      (item) => item?.product?.name.toLowerCase().includes(searchTerm) || item?._id.toLowerCase().includes(searchTerm)
    );

    setSearchedItems(filteredItems);
    setSearchParams(searchTerm);
  };
useEffect(()=>{
  const handleFilter= (e) => {
    const searchTerm = statusValue?.toLowerCase();

    // Filter items based on the search term
    const filteredItems = data?.fetchOrdersByAdmin?.items?.filter(
      (item) => item?.status.toLowerCase().includes(searchTerm) 
    );

    setSearchedItems(filteredItems);
    setSearchParams(searchTerm);
  };
  handleFilter()
}, [statusValue])

    const endRow = Math.min(startRow + rowsPerPage - 1, searchedItems?.length);
  const rowsText = `${startRow}-${endRow} of ${searchedItems?.length}`;
  return (
    <>
    {
      loading  && <Loader/>
    }
      <Box sx={{ mt: 2 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <TextField
                  value={searchParams}  onChange={handleSearch}
            size="small"
            placeholder="Search by ID, product, or others..."
            sx={{ width: "55%" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FiSearch style={{ fontSize: "18px" }} />
                </InputAdornment>
              ),
              style: {
                background: " #F8F7F7",

                fontSize: "13px",
                borderRadius: "5px",
                border: 0,
                offset: " 0px solid #f2f2f2",
                // Replace with your desired font family
              },
            }}
          />
          <Box
            sx={{
              width: "15%",
              bgcolor: "#F8F7F7",

              // display: "flex",
              columnGap: 1,
              alignItems: "center",
              borderRadius: 1,
              justifyContent: "center",
            }}
          >
            <CustomSelect label={"All Status"} list={status} handleChangeDateRange={handleStatus} />
          </Box>
          <Box
            sx={{
              width: "15%",
              bgcolor: "#F8F7F7",

              display: "flex",
              columnGap: 1,
              alignItems: "center",
              borderRadius: 1,
              justifyContent: "center",
            }}
          >
            {/* <CustomSelect label={"Today"} list={status} /> */}
          </Box>
          <Button
          onClick={generatePDF}
            endIcon={<RiFileDownloadLine style={{ color: "#666" }} />}
            sx={{
              color: "#666",
              bgcolor: "#F8F7F7",
              width: "10%",
              py: 1,
              fontSize: "12px",
            }}
          >
            Download
          </Button>
        </Box>

        <Box sx={{ mt: 2 }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow sx={{ bgcolor: "#f8f8f7" }}>
                  <TableCell>Order ID</TableCell>
                  <TableCell>Product</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Customer</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell >Status</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!searchedItems ||
             searchedItems?.length === 0 ? (
                  <></>
                ) : (
                  <>
                    {searchedItems?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((item, index) => (
                      <TableRow
                        key={index}
                        hover
                        sx={{ cursor: "pointer" }}
                   
                      >
                        <TableCell sx={{display:'flex', alignItems:'center', columnGap:2, height:'50px'}}>
                               <CopyButton id={item?._id}/>
                        <Typography sx={{ color: "#4d4d4d", fontWeight: 500 , width:'100px',  whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis'}}>
                    {item?._id}
            
                        </Typography>
                   
                 
                      </TableCell>
                        <TableCell sx={{fontSize:'12px'}}>{item?.product?.name}</TableCell>
                        <TableCell sx={{fontSize:'12px'}}><DateFormatter dateString={item?.createdAt}/></TableCell>
                        <TableCell sx={{fontSize:'12px'}}>{`${item?.buyer?.firstName} ${item?.buyer?.lastName}`}</TableCell>
                        <TableCell sx={{fontSize:'12px'}}>${item?.amountPaid.toLocaleString()}</TableCell>
                        <TableCell sx={{fontSize:'12px'}}>{item?.status}</TableCell>
                        <TableCell align="right"      onClick={() => handleClickRow(item._id, item)}> <BiExpandAlt /> </TableCell>
                      </TableRow>
                    ))}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>

               <Grid container sx={{ bgcolor: "#fff", mt: 3, px: 4, py: 1 }}>
        <Grid
          item
          lg={4}
          md={4}
          xs={4}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography
            sx={{
              color: "#424242",
              fontSize: "13px",
              fontFamily: "outfit",
            }}
          >
            {rowsText}
          </Typography>
        </Grid>
        <Grid item lg={8} md={8} xs={8}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: 2,
            }}
          >
            <Typography
              sx={{
                color: "#424242",
                fontSize: "13px",
                fontFamily: "outfit",
                display: { lg: "block", xs: "none" },
              }}
            >
              You are currently on Page
            </Typography>
            <Select
              size="small"
              sx={{
                fontFamily: "outfit",
                color: "#424242",
                fontSize: "13px",
              }}
              value={rowsPerPage}
              onChange={handleChangeRowsPerPage}
              InputProps={{
                style: {
                  fontFamily: "outfit",
                  fontSize: "13px",
                  // borderRadius: "8px",

                  // Replace with your desired font family
                },
              }}
            >
              <MenuItem
                sx={{ fontFamily: "outfit", color: "#424242" }}
                value={5}
              >
                5
              </MenuItem>
              <MenuItem
                sx={{ fontFamily: "outfit", color: "#424242" }}
                value={10}
              >
                10
              </MenuItem>
              <MenuItem
                sx={{ fontFamily: "outfit", color: "#424242" }}
                value={25}
              >
                25
              </MenuItem>
              <MenuItem
                sx={{ fontFamily: "outfit", color: "#424242" }}
                value={50}
              >
                50
              </MenuItem>
            </Select>
            <Box
              sx={{
                borderLeft: "1px solid #424242",
                pl: { md: 4, xs: 2 },
                ml: { md: 3, xs: 2 },
              }}
            >
              <IconButton
                disabled={startRow === 1}
                onClick={handleChangePageLeft}
                sx={{
                  width: "30px",
                  height: "30px",
                  border: "1px solid #424242",
                  "&.Mui-disabled": {
                    border: "1px solid #4242421a",
                  },
                }}
              >
                <ChevronLeftRoundedIcon />
              </IconButton>
              <IconButton
                disabled={endRow === searchedItems?.length}
                onClick={handleChangePageRight}
                sx={{
                  ml: 4,
                  width: "30px",
                  height: "30px",
                  border: "1px solid #424242",
                  "&.Mui-disabled": {
                    border: "1px solid #4242421a",
                  },
                }}
              >
                <ChevronRightRoundedIcon />
              </IconButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Products;

const formatDate = (dateString) => {
  const date = new Date(dateString);

  const day = date.getUTCDate();
  const month = date.toLocaleString('en-GB', { month: 'short' });
  const year = date.getUTCFullYear();

  return `${day} ${month} ${year}`;
};
