import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ThemeProvider, createTheme } from "@mui/material";

// const theme = createTheme({
// palette:{
//   primary:{
//     main:'#101010'
//   }
// },
// typography:{
//   fontFamily:'inter',
//   button:{
//     textTransform:'initial'
//   }
// }
// })

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CustomTabs = ({value, handleChange, data, variant }) => {
  // const [value, setValue] = React.useState(0);

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  return (
    // <ThemeProvider theme={theme}>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant= {variant ? variant :  "fullWidth"}
          >
            {data.map((item, index) => (
              <Tab label={item.label} {...a11yProps(index)} sx={{fontWeight:900}} />
            ))}
          </Tabs>
        </Box>
        {data.map((item, index) => (
          <CustomTabPanel value={value} index={index}>
            {item?.component}
          </CustomTabPanel>
        ))}
      </Box>
    // </ThemeProvider>
  );
}

export default  CustomTabs;