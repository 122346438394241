import {
  Box,
  Grid,
  Typography,
  Divider,
  Button,
  TextField,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Select,
  IconButton,
  Drawer,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomSelect from "../../Components/Common/CustomSelect";
import CustomInput from "../../Components/Common/CustomInput";
import ImageUploader from "../../Components/Common/ImageUploader";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { GET_ACCOUNT_TYPE, GET_NOTIFICATIONS } from "../../Utils/Queries";
import { CREATE_NOTIFICATION } from "../../Utils/Mutation";
import { useSnackbarHook } from "../../hooks/useSnackbarHook";
import Loader from "../../Components/Common/Loader";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import { CloseOutlined } from "@mui/icons-material";


const Notifications = () => {
  const [accountType, setAccountType] = useState("");
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [viewData, setViewData] = useState(null)

  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen, item) => {
    setViewData(item)
    setOpen(newOpen);
  };

  const { handleClick } = useSnackbarHook();

  const [
    getNotifications,
    { data: notifications, loading: notificationLoading, refetch },
  ] = useLazyQuery(GET_NOTIFICATIONS);

  useEffect(() => {
    getNotifications();
  }, []);

  const { data, loading } = useQuery(GET_ACCOUNT_TYPE);

  console.log(notifications?.fetchAllAdminNotifications);
  const [createNotification, { loading: createLoading }] = useMutation(
    CREATE_NOTIFICATION
  );

  const handleCreateNotification = () => {
    console.log(accountType, title, message);
    createNotification({
      variables: {
        accountType,
        title,
        content: message,
      },
    })
      .then((res) => {
        handleClick("Notification Created", "success");
        refetch();
        setTitle("");
        setMessage("");
      })
      .catch((err) => {
        handleClick(`${err?.message}`, "error");
      });
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePageLeft = (event, newPage) => {
    setPage(page - 1);
  };
  const handleChangePageRight = (event, newPage) => {
    setPage(page + 1);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const startRow = page * rowsPerPage + 1;
  const endRow = Math.min(
    startRow + rowsPerPage - 1,
    notifications?.fetchAllAdminNotifications?.items?.length
  );
  const rowsText = `${startRow}-${endRow} of ${notifications?.fetchAllAdminNotifications?.items?.length}`;
  return (
    <>
      {(createLoading || notificationLoading) && <Loader />}
      <Grid container spacing={2}>
        <Grid item lg={6}>
          <Box
            sx={{ bgcolor: "#fff", p: 2, borderBottom: "1px solid #f2f2f2" }}
          >
            <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
              Create Push Notification
            </Typography>
          </Box>
          <Box sx={{ bgcolor: "#fff", p: 2, height: "80vh" }}>
            <TextField
              value={accountType}
              onChange={(e) => setAccountType(e.target.value)}
              sx={{ fontSize: "12px" }}
              inputProps={{
                style: {
                  fontSize: "12px",
                  fontWeight: 500,
                },
              }}
              select
              fullWidth
              size="small"
              label={"Account Types"}
            >
              {loading && (
                <Typography sx={{ fontSize: "10px" }}>
                  Getting Account Types...
                </Typography>
              )}
              {data?.getUserTypes.map((option, index) => (
                <MenuItem
                  sx={{ color: "#4A414F", fontWeight: 400, fontSize: "13px" }}
                  key={index}
                  value={option.value}
                >
                  {option.value}
                </MenuItem>
              ))}
            </TextField>

            <Box sx={{ mt: 2 }}>
              <CustomInput
                label="Title *"
                onChange={(e) => setTitle(e.target.value)}
                value={title}
              />
              <CustomInput
                label="Message*"
                multiline
                rows={6}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <Typography
                sx={{
                  textAlign: "right",
                  mt: -2,
                  color: "#666",
                  fontSize: "12px",
                }}
              >
                {message?.length}//1000 word characters
              </Typography>
            </Box>
            <Divider sx={{ my: 2 }} />
            <Box align="right">
              <Button
                onClick={handleCreateNotification}
                disabled={
                  message?.length > 1000 || !message || !accountType || !title
                }
                variant="contained"
              >
                Send Notification
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={6}>
          <Box sx={{ bgcolor: "#fff", p: 2 }}>
            <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
              History
            </Typography>
            <Box
              className="hide_scrollbar"
              sx={{ mt: 2, height: "80vh", overflow: "scroll" }}
            >
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow sx={{ bgcolor: "#F7F6F6" }}>
                      <TableCell sx={{ border: "none", fontSize: "14px" }}>
                        Title
                      </TableCell>
                      <TableCell sx={{ border: "none", fontSize: "14px" }}>
                        Date
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{ border: "none", fontSize: "14px" }}
                      >
                        Status
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {notifications?.fetchAllAdminNotifications?.items
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      ?.map((item, index) => (
                        <TableRow
                        key={index}
                          hover
                          sx={{ cursor: "pointer" }}
                          onClick={()=>toggleDrawer(true, item)}
                        >
                          <TableCell sx={{ fontSize: "13px" }}>
                            {item?.title}
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{ fontSize: "13px", fontWeight: 400 }}
                            >
                              {formatDate(item?.createdAt)}
                              <br />
                              <span
                                style={{ fontSize: "11px", fontWeight: 400 }}
                              >
                                {formatTime(item?.createdAt)}
                              </span>
                            </Typography>
                          </TableCell>
                          <TableCell>
                            {item?.isSent ? (
                              <>
                                <Box
                                  sx={{
                                    p: 1,
                                    px: 2,
                                    borderRadius: 2,
                                    color: "#3FB763",
                                    bgcolor: "#ECF8F0",
                                    fontSize: "11px",
                                    textAlign: "center",
                                  }}
                                >
                                  Sent
                                </Box>
                              </>
                            ) : (
                              <>
                                <Box
                                  sx={{
                                    p: 1,
                                    px: 2,
                                    borderRadius: 2,
                                    color: "#FF3347",
                                    bgcolor: "#FFE5E8",
                                    fontSize: "11px",
                                    textAlign: "center",
                                  }}
                                >
                                  Not Sent
                                </Box>
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid container sx={{ bgcolor: "#fff", mt: 3, px: 4, py: 1 }}>
                <Grid
                  item
                  lg={4}
                  md={4}
                  xs={4}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography
                    sx={{
                      color: "#424242",
                      fontSize: "12px",
                      fontFamily: "outfit",
                    }}
                  >
                    {rowsText}
                  </Typography>
                </Grid>
                <Grid item lg={8} md={8} xs={8}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: 2,
                    }}
                  >
                    <Select
                      size="small"
                      sx={{
                        fontFamily: "outfit",
                        color: "#424242",
                        fontSize: "13px",
                      }}
                      value={rowsPerPage}
                      onChange={handleChangeRowsPerPage}
                      InputProps={{
                        style: {
                          fontFamily: "outfit",
                          fontSize: "13px",
                          // borderRadius: "8px",

                          // Replace with your desired font family
                        },
                      }}
                    >
                      <MenuItem
                        sx={{ fontFamily: "outfit", color: "#424242" }}
                        value={5}
                      >
                        5
                      </MenuItem>
                      <MenuItem
                        sx={{ fontFamily: "outfit", color: "#424242" }}
                        value={10}
                      >
                        10
                      </MenuItem>
                      <MenuItem
                        sx={{ fontFamily: "outfit", color: "#424242" }}
                        value={25}
                      >
                        25
                      </MenuItem>
                      <MenuItem
                        sx={{ fontFamily: "outfit", color: "#424242" }}
                        value={50}
                      >
                        50
                      </MenuItem>
                    </Select>
                    <Box
                      sx={{
                        borderLeft: "1px solid #424242",
                        pl: { md: 4, xs: 2 },
                        ml: { md: 3, xs: 2 },
                      }}
                    >
                      <IconButton
                        disabled={startRow === 1}
                        onClick={handleChangePageLeft}
                        sx={{
                          width: "30px",
                          height: "30px",
                          border: "1px solid #424242",
                          "&.Mui-disabled": {
                            border: "1px solid #4242421a",
                          },
                        }}
                      >
                        <ChevronLeftRoundedIcon />
                      </IconButton>
                      <IconButton
                        disabled={
                          endRow ===
                          notifications?.fetchAllAdminNotifications?.items
                            ?.length
                        }
                        onClick={handleChangePageRight}
                        sx={{
                          ml: 4,
                          width: "30px",
                          height: "30px",
                          border: "1px solid #424242",
                          "&.Mui-disabled": {
                            border: "1px solid #4242421a",
                          },
                        }}
                      >
                        <ChevronRightRoundedIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Drawer anchor="right" open={open} onClose={()=>toggleDrawer(false)}>
        <Box sx={{ bgcolor: "#fff", height: "100vh", width: "450px", px: 4 }}>
          <Box
            sx={{
              mt: 4,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontWeight: 500 }}>
              Notification Details
            </Typography>
            <IconButton onClose={()=>toggleDrawer(false)}>
              <CloseOutlined sx={{ fontSize: "20px" }} />
            </IconButton>
          </Box>
          <Box sx={{mt:4, borderBottom:'1px solid #dedede', pb:2}}>
            <Typography sx={{fontSize:'12px', fontWeight:400}}>Title</Typography>
            <Typography sx={{fontSize:'14px', fontWeight:500, mt:1}}>{viewData?.title}</Typography>
             </Box>
          <Box sx={{mt:2}}>
            <Typography sx={{fontSize:'12px', fontWeight:400}}>Message</Typography>
            <Typography sx={{fontSize:'14px', fontWeight:500, mt:1}}>{viewData?.content}</Typography>
             </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default Notifications;

function formatDate(inputDate) {
  const date = new Date(inputDate);

  // Extract year, month, and day components
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so add 1
  const day = String(date.getDate()).padStart(2, "0");

  // Format into "MM-DD-YYYY" format
  return `${month}-${day}-${year}`;
}

function formatTime(timestamp) {
  const date = new Date(timestamp);

  // Get hours and minutes
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Convert to 12-hour format
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  // Construct the time string
  const formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`;

  return formattedTime;
}
