import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import bannerImg from "../../assets/img/banner.png";
import { RiEditBoxLine } from "react-icons/ri";
import { AiOutlineDelete } from "react-icons/ai";
import AddMobile from "./Drawer/AddMobile";
import { useMutation } from "@apollo/client";
import MiddleBanner from "./Drawer/MiddleBanner";
import { CloseOutlined } from "@mui/icons-material";
import CustomInput from "../Common/CustomInput";
import { DELETE_BANNER } from "../../Utils/Mutation";
import deleteImg from "../../assets/icon/delete.svg";
import Loader from "../Common/Loader";
import { useSnackbarHook } from "../../hooks/useSnackbarHook";



const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",

  boxShadow: 24,
  p: 4,
};

const Website = ({ data, getAllBanner }) => {
  const [url, setUrl] = useState("");

  const {handleClick} = useSnackbarHook()

  const [editData, setEditData] = useState(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = (item) => {
    setOpen(true);
    setEditData(item);
  };
  const handleClose = () => setOpen(false);
  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = (item) => {
    setOpen2(true);
    setEditData(item);
  };
  const handleClose2 = () => setOpen2(false);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };
  const [drawerOpen2, setDrawerOpen2] = React.useState(false);
  const handleDrawerOpen2 = () => {
    setDrawerOpen2(true);
  };

  const handleDrawerClose2 = () => {
    setDrawerOpen2(false);
  };




  useEffect(()=>{

setUrl(editData?.route)
  }, [editData])

  const [deleteBanner, { loading}] = useMutation(DELETE_BANNER)


  const handleDeleteBannner=  () =>{
 deleteBanner({variables:{bannerId:editData?._id}})
    .then((res)=>{
setOpen2(false)
getAllBanner();
handleClick("Banner deleted", "success")
    }).catch((err)=>{
handleClick(`${err?.message}`, "error")
    })
  }

  return (
    <>
    {
      loading && <Loader/>
    }
      <Box
        sx={{
          bgcolor: "#F7F6F6",
          p: 2,
          mt: 3,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ color: "#101010", fontWeight: 500 }}>
          Top Banner (1440 X 240)
        </Typography>
        <Button
          onClick={handleDrawerOpen}
          variant="contained"
          sx={{ bgcolor: "#0AB110", "&:hover": { background: "#0AB110b7" } }}
        >
          Add Banner
        </Button>
      </Box>

      <Box sx={{ mt: 3 }}>
        {data?.map((item, index) => (
          <Box
            key={index}
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", columnGap: 3 }}>
              <Box
                sx={{
                  background: `url('${item?.imageUrl}')`,
                  width: "400px",
                  height: "100px",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  border: "0.2px solid #ddd",
                }}
              />
              <Typography
                sx={{
                  fontWeight: 500,
                  width: "140px",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                <a href={item?.route}>{item?.route}</a>
              </Typography>
            </Box>
            <Box>
              <IconButton onClick={() => handleOpen2(item)}>
                <AiOutlineDelete
                  style={{ color: "#FF4C5E", fontSize: "20px" }}
                />
              </IconButton>
              {/* <IconButton onClick={() => handleOpen(item)}>
                <RiEditBoxLine style={{ fontSize: "20px" }} />
              </IconButton> */}
            </Box>
          </Box>
        ))}
      </Box>
     

  

      <AddMobile
        open={drawerOpen}
        onClose={handleDrawerClose}
        getAllBanner={getAllBanner}
      />
 

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
              Edit
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseOutlined sx={{ fontSize: "20px" }} />
            </IconButton>
          </Box>

          <Box sx={{ mt: 2 }}>
            <Typography sx={{fontSize:'13px'}}>Edit Url</Typography>
            <CustomInput  value={url} onChange={(e)=>setUrl(e.target.value)}/>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", columnGap: 3, alignItems: "center" }}>
            <Box>
              <img src={deleteImg} width={50} />
            </Box>
            <Box>
              <Typography
                sx={{ fontSize: "16px", fontWeight: 500, color: "#333" }}
              >
          Are you sure you want to delete this banner?
              </Typography>
              <Typography sx={{ fontSize: "12px", fontWeight: 400 }}>
          This banner will be deleted immediately, you can’t undo this action.
              </Typography>
              <Box sx={{ mt: 3 }}>
                  <Button variant="outlined"   onClick={handleClose2} color="error" sx={{ mr: 4, px: 4 }} >
             Cancel
                </Button>
                <Button
                onClick={handleDeleteBannner}
                  variant="contained"
                  color="error"
                  sx={{ bgcolor: "#FF3347", px: 4 }}
                
                >
               Delete
                </Button>
              
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Website;
