import { Avatar, Box, Button, Divider, Grid, TextField, Typography, InputLabel } from '@mui/material'
import React, { useEffect, useState } from 'react';
import imgIcon from '../../assets/img/image-add.svg'
import CustomInput from '../../Components/Common/CustomInput';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_PROFILE } from '../../Utils/Queries';
import Loader from '../../Components/Common/Loader';
import { EDIT_PROFILE } from '../../Utils/Mutation';
import { useSnackbarHook } from '../../hooks/useSnackbarHook';


const Settings = () => {
const {handleClick} = useSnackbarHook()
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")

  const [getProfile, {data, loading}] = useLazyQuery(GET_PROFILE)

  const [editProfile, {loading:editLoading}] = useMutation(EDIT_PROFILE);

  const handleEditProfile = async () =>{
    await editProfile({
      variables:{
        firstName,
        lastName,
 
      }
    }).then((res)=>{
      console.log(res)
      handleClick("Profile Successfully Updated", "success")
      handleGetProfile()
    }).catch((err)=>{
      handleClick(`${err.message}`, "error")
    })
  }

  const handleGetProfile = async () =>{
    await getProfile().then((res)=>{
console.log(res)
// setFirstName(res?.data?.getAdminProfile?.firstName);
// setLastName(data?.getAdminProfile?.lastName);
    }).catch((err)=>{
console.log(err)
    })
  }

  useEffect(()=>{
    handleGetProfile()
  }, [])

  const profile = data?.getAdminProfile;

  useEffect(()=>{
setFirstName(data?.getAdminProfile?.profile?.firstName)
setLastName(data?.getAdminProfile?.profile?.lastName)
  }, [data])
  return (
    <>
      {(loading || editLoading) && <Loader />}
      <Box sx={{ p: 3, bgcolor: "#FFF", px: 5, pb: 5 }}>
        <Grid container spacing={2}>
          <Grid item lg={4}>
            <Box>
              <Typography sx={{ fontWeight: 700 }}>Profile photo</Typography>
              <Typography sx={{ mt: 1, color: "#666", fontSize: "12px" }}>
                This image will be displayed on your profile
              </Typography>
              <Button
                startIcon={<img src={imgIcon} />}
                variant="outlined"
                sx={{ mt: 3, fontWeight: 600 }}
                color="primary"
              >
                Change Photo
              </Button>
            </Box>
          </Grid>
          <Grid item lg={8}>
            <Avatar sx={{ width: "100px", height: "100px" }} />
          </Grid>
        </Grid>
        <Divider sx={{ my: 5 }} />
        <Grid container spacing={2}>
          <Grid item lg={4}>
            <Box>
              <Typography sx={{ fontWeight: 700 }}>
                Personal Information
              </Typography>
              <Typography sx={{ mt: 1, color: "#666", fontSize: "12px" }}>
                Update your personal details here.
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={7}>
            <Grid container spacing={2}>
              <Grid item lg={6}>
                <CustomInput
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  label="First Name:"
                />
              </Grid>
              <Grid item lg={6}>
                <CustomInput
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  label="Last Name:"
                />
              </Grid>
            </Grid>
            <Box align="right">
              <Button variant="contained" onClick={handleEditProfile} disabled={!firstName}>
                Save Changes
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Divider sx={{ my: 5 }} />
        <Grid container spacing={2}>
          <Grid item lg={4}>
            <Box>
              <Typography sx={{ fontWeight: 700 }}>Password</Typography>
              <Typography sx={{ mt: 1, color: "#666", fontSize: "12px" }}>
                Change Password
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={7}>
            <Box>
              <InputLabel
                sx={{
                  color: "#2B2B2B",
                  fontSize: "13px",
                  fontWeight: 600,
                  // fontFamily: "Gordita",
                }}
              >
                Current Password
              </InputLabel>
              <TextField
                placeholder="****"
                fullWidth
                margin="dense"
                InputProps={{
                  style: {
                    marginBottom: "20px",
                    fontSize: "13px",
                    borderRadius: "8px",
                    offset: " 1px solid #f2f2f2",
                    // Replace with your desired font family
                  },
                }}
              />
              <InputLabel
                sx={{
                  color: "#2B2B2B",
                  fontSize: "13px",
                  fontWeight: 600,
                }}
              >
                New Password
              </InputLabel>
              <TextField
                placeholder="****"
                fullWidth
                margin="dense"
                InputProps={{
                  style: {
                    marginBottom: "20px",
                    fontSize: "13px",
                    borderRadius: "8px",
                    offset: " 1px solid #f2f2f2",
                    // Replace with your desired font family
                  },
                }}
              />
              <InputLabel
                sx={{
                  color: "#2B2B2B",
                  fontSize: "13px",
                  fontWeight: 600,
                }}
              >
                Confirm Password
              </InputLabel>
              <TextField
                placeholder="****"
                fullWidth
                margin="dense"
                InputProps={{
                  style: {
                    marginBottom: "20px",
                    fontSize: "13px",
                    borderRadius: "8px",
                    offset: " 1px solid #f2f2f2",
                    // Replace with your desired font family
                  },
                }}
              />
              <Box align="right">
                <Button variant="contained">Save Changes</Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Settings