import { Box } from '@mui/material'
import React from 'react'
import CustomTabs from '../../Components/Common/CustomTabs'
import Products from '../../Components/Orders/Products'
import Services from '../../Components/Orders/Services'



const TabData = [
  {
    label: "Products",
    component: <Products/>,
  },
  {
    label: "Services",
    component: <Services />,
  },
];
const Orders = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  return (
    <>
      <Box sx={{ bgcolor: "#fff", p: 2 }}>
        <CustomTabs variant="standard" value={value} handleChange={handleChange} data={TabData} />
      </Box>
    </>
  );
}

export default Orders