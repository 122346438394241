import { Box, Grid, Typography } from '@mui/material'
import React, {useState} from 'react'
import CustomTabs from '../../Components/Common/CustomTabs';
import Products from '../../Components/Categories/Products';
import Services from '../../Components/Categories/Services';

const TabData = [
  {
    label: "Products",
    component: <Products />,
  },

];

const Categories = () => {
      const [value, setValue] = React.useState(0);

      const handleChange = (event, newValue) => {
        setValue(newValue);
      };
  return (
    <>
    <Box sx={{bgcolor:'#fff', p:2, width:'100%'}}>
      <CustomTabs variant="standard" value={value}  handleChange={handleChange} data={TabData}  />
    </Box>
    </>
  );
}

export default Categories